<template>
  <div style="border: 1px solid #dadada; width: 100%">
    <Toolbar
      v-if="showToolBar"
      style="border-bottom: 1px solid #dadada"
      :editor="editorRef"
      :default-config="toolbarConfig"
      :mode="mode"
    />
    <Editor
      v-model="content"
      style="height: 540px; overflow-y: auto"
      :default-config="editorConfig"
      :mode="mode"
      @on-change="onEditorChange"
      @on-created="handleCreated"
    />
  </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import "@wangeditor/editor/dist/css/style.css";
import { Editor, Toolbar } from "@wangeditor/editor-for-vue";
import { IDomEditor } from "@wangeditor/editor";
import type { IToolbarConfig, IEditorConfig } from "@wangeditor/editor";
import { BASE_URL } from "../../api/ENV";
import { uploadFilePublic } from "../../api/common";
import { getTextContent } from "../../utils/util";
type InsertFnType = (url: string, alt?: string, href?: string) => void;
const toolbarConfig: Partial<IToolbarConfig> = {
  excludeKeys: ["codeBlock"],
};
export default defineComponent({
  name: "ArticleEditorComp",
  components: {
    Editor,
    Toolbar,
  },
  props: {
    modelValue: {
      type: String,
      default: "",
    },
    showToolBar: {
      type: Boolean,
      default: true,
    },
    placeholder: {
      type: String,
      default: "请输入内容...",
    },
  },
  emits: ["update:modelValue", "hasInputToggle"],
  data() {
    return {
      content: "",
      toolbarConfig,
      editorRef: null! as IDomEditor,
      mode: "default",
      action: `${BASE_URL}/statics/upload`,
    };
  },
  computed: {
    editorConfig() {
      return {
        placeholder: this.placeholder,
        uploadVideo: {
          maxFileSize: 1024 * 1024 * 20,
          maxNumberOfFiles: 1,
          allowedFileTypes: ["video/*"],
          customUpload(file: File, insertFn: InsertFnType) {
            const params = new FormData();
            params.append("file", file);
            params.append("origin", "KNOWLEDGE_PRODUCT_AND_SERVICE_SOLUTIONS");
            params.append("from", this.$store.state.userInfo.organization.name);
            uploadFilePublic(params).then(({ data }) => {
              insertFn(data.url, data.fileName, data.url);
            });
          },
        },
        MENU_CONF: {
          fontFamily: { fontFamilyList: [] },
          uploadImage: {
            // https://www.wangeditor.com/v5/menu-config.html#%E5%9F%BA%E6%9C%AC%E9%85%8D%E7%BD%AE
            maxFileSize: 1024 * 1024 * 2, // 1M
            // 最多可上传几个文件，默认为 100
            maxNumberOfFiles: 1,
            // 选择文件时的类型限制，默认为 ['image/*'] 。如不想限制，则设置为 []
            allowedFileTypes: ["image/*"],
            // 自定义上传
            customUpload(file: File, insertFn: InsertFnType) {
              const params = new FormData();
              params.append("file", file);
              uploadFilePublic(params).then(({ data }) => {
                insertFn(data.url, data.fileName, data.url);
              });
            },
          },
          uploadVideo: {
            maxFileSize: 1024 * 1024 * 200,
            maxNumberOfFiles: 1,
            allowedFileTypes: ["video/*"],
            customUpload: (file: File, insertFn: InsertFnType) => {
              const params = new FormData();
              params.append("file", file);
              uploadFilePublic(params).then(({ data }) => {
                insertFn(data.url);
              });
            },
          },
        },
      } as Partial<IEditorConfig>;
    },
  },
  watch: {
    modelValue(newVal: string) {
      this.content = newVal;
    },
  },
  mounted() {
    this.showBol = true;
  },
  created() {},
  methods: {
    onEditorChange(e: IDomEditor) {
      this.$emit("update:modelValue", e.getHtml());
      // 利用正式匹配已经输入内容来控制确认按钮是否可点击
      this.isEditConfirmDisable(e);
    },
    isEditConfirmDisable(e: IDomEditor) {
      if (getTextContent(e.getHtml()).length) {
        this.$emit("hasInputToggle", false);
      } else {
        this.$emit("hasInputToggle", true);
      }
    },
    handleCreated(editor: IDomEditor) {
      this.editorRef = Object.seal(editor);
    },
  },
});
</script>
<style scoped lang="scss">
::v-deep(.w-e-text-placeholder) {
  top: 12px;
}

.w-e-full-screen-container {
  z-index: 999;
}
</style>
