<template>
  <el-config-provider :locale="zhCn">
    <router-view></router-view>
  </el-config-provider>
  <MessageCom :config="message_config"></MessageCom>
</template>

<script lang="ts">
import { ElConfigProvider } from "element-plus";
import zhCn from "element-plus/es/locale/lang/zh-cn";
export default {
  components: { ElConfigProvider },
  data() {
    return {
      zhCn,
    };
  },
  computed: {
    message_config() {
      return this.$store.state.errConfig;
    },
  },
  methods: {},
};
</script>

<style>
body,
html,
#app {
  height: 100%;
  font-family: Microsoft Yahei, PingFangSC, PingFang SC, SimHei, sans-serif;
}
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  --el-color-primary: #276efa;
  --el-border-radius-base: 0;
  --el-disabled-bg-color: #f8f9fc;
  --el-font-size-large: 16px;
  --el-color-primary-light-3: #327fff;
  --el-button-hover-text-color: #2468f1;
  --el-color-primary-light-9: rgba(50, 127, 255, 0.05);
  --el-text-color-placeholder: #888fa5;
  --el-component-size: 30px;
  --el-menu-item-height: 40px;
  --el-pagination-font-size: 12px;
}

.el-popper {
  --el-color-primary: #276efa;
}

.card {
  border-radius: 12px 12px 0 0;
  background-color: white;
  padding: 20px;
}

.demonstration {
  display: block;
  color: #595959;
  font-size: 12px;
  margin-bottom: 6px;
  text-align: left;
}

.ed-required {
  color: #f56c6c;
}

.el-cascader-node {
  position: relative;
}

.el-cascader-node .el-radio {
  position: unset;
}

.el-cascader-node .el-radio::after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
</style>
