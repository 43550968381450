import adminMenus from "./adminMenus";
import i18n from "./../i18n/index";

const routes: any[] = [
  ...adminMenus,
  {
    path: `/admin/event/add`,
    name: "event-create",
    component: () => import("@/views/user/EventCreate.vue"),
    meta: {
      title: "发布活动",
      code: "*",
      breadcrumb: [{ label: "需求管理" }, { label: "发布需求", path: "" }],
      permissions: [],
      defaultMenu: "enterprise_demand_management",
    },
  },
  {
    path: `/admin/event/edit/:id`,
    name: "event-edit",
    component: () => import("@/views/user/EventCreate.vue"),
    meta: {
      title: "编辑活动",
      code: "*",
      breadcrumb: [{ label: "需求管理" }, { label: "发布需求", path: "" }],
      permissions: [],
      defaultMenu: "enterprise_demand_management",
    },
  },
  {
    path: `/admin/event/:id`,
    name: "event-admin-detail",
    component: () => import("@/views/user/EventDetail.vue"),
    meta: {
      title: "活动详情",
      code: "*",
      breadcrumb: [{ label: "需求管理" }, { label: "发布需求", path: "" }],
      permissions: [],
      defaultMenu: "event_management",
    },
  },
  {
    path: `/admin/course/add`,
    name: "course-create",
    component: () => import("@/views/admin/CourseCreate.vue"),
    meta: {
      title: "发布课程",
      code: "*",
      breadcrumb: [{ label: "需求管理" }, { label: "发布需求", path: "" }],
      permissions: [],
      defaultMenu: "course-manage",
    },
  },
  {
    path: `/admin/course/edit/:id`,
    name: "course-edit",
    component: () => import("@/views/admin/CourseCreate.vue"),
    meta: {
      title: "编辑课程",
      code: "*",
      breadcrumb: [{ label: "需求管理" }, { label: "发布需求", path: "" }],
      permissions: [],
      defaultMenu: "course-manage",
    },
  },
  {
    path: `/admin/news/add`,
    name: "news-create",
    component: () => import("@/views/admin/NewsCreate.vue"),
    meta: {
      title: "发布新闻",
      code: "*",
      breadcrumb: [{ label: "需求管理" }, { label: "发布需求", path: "" }],
      permissions: [],
      defaultMenu: "news-manage",
    },
  },
  {
    path: `/admin/news/edit/:id`,
    name: "news-edit",
    component: () => import("@/views/admin/NewsCreate.vue"),
    meta: {
      title: "编辑新闻",
      code: "*",
      breadcrumb: [{ label: "需求管理" }, { label: "发布需求", path: "" }],
      permissions: [],
      defaultMenu: "news-manage",
    },
  },
  {
    path: `/admin/news/:id`,
    name: "news-admin-detail",
    component: () => import("@/views/user/NewsDetail.vue"),
    meta: {
      title: "新闻详情",
      code: "*",
      breadcrumb: [{ label: "需求管理" }, { label: "发布需求", path: "" }],
      permissions: [],
      defaultMenu: "news_management",
    },
  },
];
export default routes;
