// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
// 引入vue中定义的指令对应的类型定义
import { Directive } from "vue";

let bindingVal: {
  value: () => void;
};
let lastCall = Date.now();
function _scrollFun() {
  const now = Date.now();
  if (now - lastCall < 50) {
    return;
  }
  lastCall = now;
  const CONDITION =
    this.scrollHeight - this.scrollTop - this.clientHeight <= 10;
  if (CONDITION) {
    bindingVal.value();
  }
}
export const loadmore: Directive = {
  updated(el, binding) {
    bindingVal = binding;
    const SELECTWRAP_DOM = document
      .getElementById(
        el.querySelector(".select-trigger")?.getAttribute("aria-describedby")
      )
      ?.querySelector(".el-select-dropdown__wrap");
    SELECTWRAP_DOM?.removeEventListener("scroll", _scrollFun);
    SELECTWRAP_DOM?.addEventListener("scroll", _scrollFun);
  },
};
