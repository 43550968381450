// meta.permissions [[机构类型__只有orgType在包含在内才有访问权限], [权限code值(undefined则默认取--[menu.meta.code] 有子级菜单项则取子级code的聚合数组)__PS:后续子页面以<<父级code.子级code>>为组织形式去拆分判断]]
// meta.defaultMenu 跟左侧菜单项的meta.defaultMenu保持一致
import { ADMIN, ORGANIZATION } from "./permissionConstants";
// TODO：菜单栏相关的路由
const menusRoutes = [
  {
    path: "/home",
    name: "home",
    component: () => import("../views/user/Home.vue"),
    meta: {
      // title: "首页",
      code: "*",
      // icon: "home",
      permissions: [],
      defaultMenu: "home",
      // keepAlive: true,
    },
  },
  {
    path: "/project-collect",
    name: "ProjectCollectPage",
    component: () => import("../views/user/ProjectCollectPage.vue"),
    meta: {
      permissions: [[]],
      shallowHeader: false,
      showHeader: true,
      showFooter: true,
      mainClass: "home",
    },
  },
  {
    path: "/course",
    name: "course-list",
    component: () => import("../views/user/CourseList.vue"),
    meta: {
      // title: "首页",
      code: "street_home",
      // icon: "home",
      permissions: [],
      defaultMenu: "management_home",
      // keepAlive: true,
    },
  },
  {
    path: "/demand",
    name: "demand-market",
    component: () => import("../views/user/DemandMarket.vue"),
    meta: {
      // title: "首页",
      code: "street_home",
      // icon: "home",
      permissions: [],
      defaultMenu: "management_home",
      // keepAlive: true,
    },
  },
  {
    path: "/service",
    name: "service-market",
    component: () => import("../views/user/ServiceMarket.vue"),
    meta: {
      // title: "首页",
      code: "street_home",
      // icon: "home",
      permissions: [],
      defaultMenu: "management_home",
      // keepAlive: true,
    },
  },
  {
    path: "/event",
    name: "event-list",
    component: () => import("../views/user/EventList.vue"),
    meta: {
      // title: "首页",
      code: "street_home",
      // icon: "home",
      permissions: [],
      defaultMenu: "management_home",
      // keepAlive: true,
    },
  },
  {
    path: "/news",
    name: "news-list",
    component: () => import("../views/user/NewsList.vue"),
    meta: {
      // title: "首页",
      code: "street_home",
      // icon: "home",
      permissions: [],
      defaultMenu: "management_home",
      // keepAlive: true,
    },
  },
  // {
  //   path: "/manage/enterprise-lib",
  //   name: "manage-enterprise-lib",
  //   meta: {
  //     title: "试点企业管理",
  //     code: "management_enterprise_library",
  //     breadcrumb: [{ label: "试点企业管理" }],
  //     icon: "enterpriseDiagnostic",
  //     permissions: [],
  //     defaultMenu: "management_enterprise_library",
  //   },
  //   children: [],
  // },
];

export default menusRoutes;
