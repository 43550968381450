<!-- 分页：
   <BasePage
    :total="total"
    :form-data="pageObj"
    @change="pageChange($event)"
    ></BasePage>

    参数配置：
    total: 0,  ------（总条数）
    pageObj: {
        default: {
          page: 1,
          size: 10,
        },
      },
    pageChange(data: any) {  ------（分页事件）
      console.log("也吗", data);
    },
 -->
<template>
  <div
    v-if="baseType === 'admin'"
    :class="['footer-box', 'admin-pager', externalClass]"
  >
    <div class="infoText">
      <slot name="infoText"></slot>
    </div>
    <el-pagination
      prev-icon="ArrowLeft"
      next-icon="ArrowRight"
      :total="total"
      :current-page="formData.page"
      :page-sizes="[10, 20, 50, 100]"
      background
      :page-size="formData.size"
      layout="prev, pager, next, jumper"
      v-bind="{ ...$attrs }"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    ></el-pagination>
    <div>
      <slot name="buttons"></slot>
    </div>
  </div>
  <Teleport v-else-if="showTime && toDiv" :to="toDiv">
    <div :class="['footer-box', externalClass]">
      <div class="infoText">
        <slot name="infoText"></slot>
      </div>
      <el-pagination
        prev-icon="back"
        next-icon="right"
        :current-page="formData.page"
        :page-sizes="[10, 20, 50, 100]"
        :total="total"
        background
        :page-size="formData.size"
        layout=" prev, pager, next"
        v-bind="{ ...$attrs }"
        @current-change="handleCurrentChange"
        @size-change="handleSizeChange"
      ></el-pagination>
      <div>
        <slot name="buttons"></slot>
      </div>
    </div>
  </Teleport>
  <div v-else :class="['footer-box', externalClass]">
    <div class="infoText">
      <slot name="infoText"></slot>
    </div>
    <el-pagination
      prev-icon="back"
      next-icon="right"
      :total="total"
      :current-page="formData.page"
      :page-sizes="[10, 20, 50, 100]"
      background
      :page-size="formData.size"
      layout="prev, pager, next"
      v-bind="{ ...$attrs }"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    ></el-pagination>
    <div>
      <slot name="buttons"></slot>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent, PropType } from "vue";
export default defineComponent({
  name: "BasePage",
  props: {
    formData: {
      type: Object as PropType<{
        page: number;
        size: number;
      }>,
      default: () => {
        return {
          page: 1,
          size: 10,
        };
      },
    },
    total: {
      type: Number,
      default: 0,
    },
    baseType: {
      type: String,
      default: "",
    },
    externalClass: {
      type: String,
      default: "",
    },
    toDiv: {
      type: String,
      default: "",
    },
  },
  emits: ["change"],
  data() {
    return {
      paginationObj: {
        page: 1,
        size: 10,
      },
      showTime: false,
    };
  },
  watch: {
    formData: {
      handler(newVal: any, oldVal: any) {
        this.paginationObj = newVal;
      },
      deep: true,
    },
  },
  created() {
    this.paginationObj = this.formData;
  },
  mounted() {
    // 确保挂载元素#main-content已经存在
    this.showTime = true;
  },
  methods: {
    handleCurrentChange($event: number) {
      this.paginationObj.page = $event;
      this.$emit("change", this.paginationObj);
    },
    handleSizeChange($event: number) {
      this.paginationObj.size = $event;
      this.paginationObj.page = 1;
      this.$emit("change", this.paginationObj);
    },
  },
});
</script>
<style scope>
/* .footer-box {
  position: sticky;
  bottom: 0;
  right: 0px;
  left: 0px;
  min-height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 24px;
  box-sizing: border-box;
  background: #ffffff;
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.05);
  z-index: 11;
  /* margin-left: -20px; */
/* margin-right: -24px;
  margin-left: -24px;
}  */

.footer-box {
  min-height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 24px;
  box-sizing: border-box;
  background: #ffffff;
  /* box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.05); */
  z-index: 11;
  /* margin-left: -16px;
  margin-right: -16px; */
}

.admin-pager .el-pagination__edtor.el-input {
  width: 30px;
}

.admin-pager .el-pagination__edtor.el-input .el-input__wrapper {
  border-radius: 6px;
}

.admin-pager .el-pager li {
  width: 30px !important;
  height: 30px;
  background: #ffffff;
  border-radius: 6px;
  border: 1px solid #dcdee6;
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 12px !important;
  color: #595959 !important;
  line-height: 17px !important;
  text-align: center;
  font-style: normal;
}

.el-pager li {
  background-color: #fff !important;
  width: 16px !important;
  height: 30px !important;
  font-family: Arial, Arial;
  font-weight: bold;
  font-size: 16px !important;
  color: #74767e !important;
  line-height: 18px !important;
  text-align: center;
  font-style: normal;
}
.el-pager li:hover {
  background: #fff !important;
  color: #222222 !important;
}

.admin-pager .el-pager li:not(.is-disabled).is-active {
  background: #080b0e !important;
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 12px !important;
  color: #ffffff !important;
  border-radius: 6px;
}

.el-pager li:not(.is-disabled).is-active {
  color: #222222 !important;
  background: #fff !important;
  font-family: Arial;
  font-weight: bold;
  position: relative;
}
.el-pager li:not(.is-disabled).is-active::after {
  content: "";
  position: absolute;
  width: 16px;
  height: 2px;
  background: #222222;
  bottom: 0;
}

.btn-prev,
.btn-next {
  border-radius: 50% !important;
  border: 1px solid rgba(228, 229, 231, 0.5) !important;
  background-color: #fff !important;
  width: 44px;
  height: 44px !important;
}

.admin-pager .btn-prev,
.admin-pager .btn-next {
  width: 30px;
  height: 30px !important;
  border-radius: 6px !important;
}

.el-pagination .btn-next .el-icon,
.el-pagination .btn-prev .el-icon {
  font-size: 14px !important;
}

.admin-pager .el-pagination .btn-next .el-icon,
.admin-pager .el-pagination .btn-prev .el-icon {
  font-size: 6px !important;
}

/* .el-input__inner {
  font-size: 12px !important;
} */

.infoText {
  font-size: 12px;
  color: rgb(78, 78, 78);
  min-width: fit-content;
}
</style>
