<!-- 
  <load-more-select
      v-model="setting.detectionPlan"
      :select="testPlanOptions"
  ></load-more-select>
  testPlanOptions: { loadMore: 加载更多方法回调， otherInfo: {同el-select组件的配置项}}
  具体使用可参考@/views/common/model/workBenchDetail.vue
  -->
<template>
  <el-select
    v-model="localVal"
    v-loadmore="select.loadMore"
    v-bind="select.otherInfo"
    @change="$emit('update:modelValue', localVal)"
  >
    <el-option
      v-for="option in select.options"
      :key="option.code"
      :label="option.name"
      :value="option.code"
    />
  </el-select>
</template>
<script lang="ts">
import { defineComponent, PropType } from "vue";
export default defineComponent({
  name: "LoadMoreSelectComp",
  props: {
    select: {
      type: Object as PropType<
        Record<string, string> & {
          options: Record<string, string>[];
        }
      >,
      default: () => ({}),
    },
    modelValue: {
      type: [String, Array],
      default: "",
    },
  },
  emits: ["update:modelValue"],
  data() {
    return {
      localVal: "",
    };
  },
  watch: {
    modelValue: {
      handler(newVal: string) {
        this.localVal = newVal;
      },
      immediate: true,
    },
  },
});
</script>

<style></style>
