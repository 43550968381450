import store from "../store";
import { http } from "./http";
// 分段上传文件
export function uploadSliceFile(params: object, config: object) {
  return http.post("/ocs/attachment/piece/upload", params, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
    ...config,
  });
}

export function uploadFile(params: FormData) {
  return http.post("/ocs/attachment/upload", params, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
}

export function getDownload(params: FormData) {
  return http.post(`/sso/service-selection/download-attachment`, params, {
    headers: {
      "Content-Type": "application/json",
    },
    responseType: "blob",
    onDownloadProgress: (evt) => {
      store.commit("loadedTempo", 0);
      let currentLoaded = 0;
      currentLoaded = parseInt((evt.loaded / evt.total) * 100);
      store.commit("loadedTempo", currentLoaded);
    },
  });
}

export function uploadFilePublic(params: FormData) {
  return http.post("/ocs/attachment/upload/public", params, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
}

export function uploadVideo(params: FormData) {
  return http.post(`/ocs/attachment/upload/video`, params, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
}

export function getDic(params) {
  return http.get("/org/dictionary", { params });
}
export function getWeeklyDic() {
  return http.get("/diagnose/weekly/dic");
}
export function getDownloadUrlByPath(filePath: string) {
  return http.get("/ocs/attachment/accessUrl", {
    params: {
      filePath,
    },
  });
}

export function getDownloadFilePath(id: string) {
  return http.get(`/ocs/attachment/${id}`);
}

// 获取首页信息
export function getIndex(id) {
  return http.get(`/index/`);
}

// 获取需求详情
export function getDemandDetail(id) {
  return http.get(`/demand/${id}`);
}

// 获取需求列表
export function getDemandList(params: object) {
  return http.get("/demand/", { params });
}

// 获取需求类型筛选项
export function getDemandCategory() {
  return http.get("/demand/category");
}

// 公告列表查询
export function getNoticeList(params: object) {
  return http.get("/demand/knowledge/notice/list");
}

// 获取课程列表
export function getCourseList(params: object) {
  return http.get("/course/", { params });
}

// 获取活动列表

export function getEventList(params: object) {
  return http.get("/event/", { params });
}

// 获取活动详情

export function getEventDetail(id) {
  return http.get(`/event/${id}`);
}

// 创建活动
export function postEvent(params: object) {
  return http.post("/event/", params);
}

// 获取推荐活动
export function getEventRecommend(params: object) {
  return http.get(`/event/recommend`, { params });
}

// 获取新闻列表

export function getNewsList(params: object) {
  return http.get("/news/", { params });
}

// 获取新闻详情

export function getNewsDetail(id) {
  return http.get(`/news/${id}`);
}

// 获取关于我们详情
export function getAboutUs() {
  return http.get(`/about-us/`);
}

// 提交反馈
export function postFeedback(params: object) {
  return http.post("/feedback/", params);
}

// 实名认证
export function postEnterpriseVerify(params: object) {
  return http.post("/account/enterprises-verify", params);
}

// 获取实名认证
export function getEnterpriseVerify() {
  return http.get("/account/enterprises-verify");
}

// 获取消息列表
export function getMessageList(params: object) {
  return http.get(`/message/`, { params });
}

// 获取消息详情
export function getMessage(id: string | number) {
  return http.get(`/message/${id}`);
}

// 获取用户需求列表

export function getMyDemandList(params: object) {
  return http.get(`/demand/self/`, { params });
}

// 新增需求
export function postDemand(params: object) {
  return http.post(`/demand/`, params);
}

// 编辑需求
export function putEditDemand(id: number | string, params) {
  return http.put(`/demand/${id}`, params);
}

// 发布需求
export function putDemandPublic(id: number | string) {
  return http.put(`/demand/${id}/public`);
}

// 删除需求
export function deleteDemand(id: number | string) {
  return http.delete(`/demand/${id}`);
}

// 获取服务列表

export function getServiceList(params: object) {
  return http.get(`/service`, { params });
}

// 获取服务详情
export function getServiceDetail(id) {
  return http.get(`/service/${id}`);
}

// 获取服务类型筛选项
export function getServiceCategory() {
  return http.get("/service/category");
}

// 获取用户服务列表

export function getMyServiceList(params: object) {
  return http.get(`/service/self`, { params });
}

// 新增服务
export function postService(params: object) {
  return http.post(`/service/`, params);
}

// 编辑服务
export function putEditService(id: number | string, params) {
  return http.put(`/service/${id}`, params);
}

// 发布服务
export function putServicePublic(id: number | string) {
  return http.put(`/service/${id}/public`);
}

// 删除服务
export function deleteService(id: number | string) {
  return http.delete(`/service/${id}`);
}

// 获取当前用户信息
export function getUserProfile() {
  return http.get(`/account/profile`);
}

// 用户详情-服务信息创建更新
export function postServiceInfo(params: object) {
  return http.post(`/account/detail/services`, params);
}

// 用户详情-出海信息创建更新
export function postOverseaInfo(params: object) {
  return http.post(`/account/detail/overseas`, params);
}

// 用户详情-企业信息更新
export function putServiceInfo(params: object) {
  return http.put(`/account/detail/enterprise`, params);
}

// 用户详情-联系信息更新
export function putContactInfo(params: object) {
  return http.put(`/account/detail/contact`, params);
}

// 获取站点访问量
export function getVisits() {
  return http.get(`/visits/`);
}
// 获取征集信息
export function getCollection() {
  return http.get(`/project-solicitation/`);
}

// 提交征集信息
export function postCollection(params) {
  return http.post(`/project-solicitation/`, params);
}
// 获取服务信息
export function getProfileOverseaInfo() {
  return http.get(`/account/detail/overseas`);
}
// 获取服务信息
export function getServiceInfo() {
  return http.get(`/account/detail/services`);
}

// 获取企业出海信息
export function getEnterpriseInfo() {
  return http.get(`/account/detail/enterprise`);
}
