<!-- TopSearchForm  -->
<!-- 
  1、传入搜索框配置inputConfigArr:
  [
   {
          type: "text", ------（搜索框类型type: 'select/text/datePicker/daterange/cascader'）
          key: "keyword", ------（获取搜索入参参数）
          hasBtn: true, ------（搜索icon的显示）
          otherInfo: { ------ （placeholder提示语）
            placeholder: "搜索ploceHolder",
          },
          options: [], ------（select、cascader类型需要，是下拉的字典数据）
          otherInfo: { ------ （placeholder提示语：时间区间类型需要）
            'range-separator': '至',
            'start-placeholder': '请选择年月日',
            'end-placeholder': '请选择年月日',
          },
        },
      ]
    2、按钮配置btnArr:[
    {
          text: "开通账户", ------(按钮文案)
          configObj: {
            type: "primary",
          },
          localIcon: "add", ------(按钮的icon)
          clickFun: () => { ------(按钮事件)
            this.toAddAcct();
          },
        },
    ]
 -->
<template>
  <div class="formCon">
    <el-form
      v-if="inputConfigArr.length"
      ref="form"
      :inline="true"
      :model="formDataCopy"
      label-position="top"
      :class="[foldType ? '' : 'expanded']"
      @submit.native.prevent
    >
      <el-form-item
        v-for="(item, index) in inputConfigArr"
        :key="index"
        :class="[item.hasBtn ? 'search' : '']"
      >
        <div v-if="item.type == 'text'" class="itemList">
          <el-input
            v-model.trim="formDataCopy[item.key]"
            class="inputTxt"
            type="text"
            v-bind="item.otherInfo"
            clearable
            @keyup.enter="$emit('search', formDataCopy)"
          />
          <i
            class="searchIco icon-searchIco iconfont"
            @click="$emit('search', formDataCopy)"
          ></i>
        </div>

        <div v-if="item.type == 'select' && !item.multiple" class="itemLists">
          <span class="selectName">{{ item.label }}</span>
          <el-select
            v-if="item.closeType == 'none'"
            v-model="formDataCopy[item.key]"
            :disabled="item.disabled"
            v-bind="item.otherInfo"
            @change="$emit('change', formDataCopy[item.key])"
          >
            <el-option
              v-for="option in item.options"
              :key="option.value || option.code"
              :label="option.label || option.name"
              :value="option.value || option.code"
            />
          </el-select>
          <el-select
            v-else
            v-model="formDataCopy[item.key]"
            :disabled="item.disabled"
            v-bind="item.otherInfo"
            clearable
            @change="$emit('change', formDataCopy[item.key])"
          >
            <el-option
              v-for="option in item.options"
              :key="option.value || option.code"
              :label="option.label || option.name"
              :value="option.value || option.code"
            />
          </el-select>
        </div>
        <div
          v-if="item.type == 'select' && item.multiple"
          class="itemLists width240"
        >
          <span class="selectName">{{ item.label }}</span>
          <el-select
            v-model="formDataCopy[item.key]"
            :disabled="item.disabled"
            v-bind="item.otherInfo"
            clearable
            multiple
            collapse-tags
            @change="$emit('change', formDataCopy[item.key])"
          >
            <el-option
              v-for="option in item.options"
              :key="option.value || option.code"
              :label="option.label || option.name"
              :value="option.value || option.code"
            />
          </el-select>
        </div>

        <div v-if="item.type == 'datePicker'" class="itemLists width616">
          <span class="selectName">{{ item.label }}</span>
          <el-date-picker
            v-model="formDataCopy[item.key]"
            type="datetimerange"
            v-bind="item.otherInfo"
            value-format="YYYY-MM-DD hh:mm:ss"
            clearable
          />
        </div>
        <div v-if="item.type == 'dateMonth'" class="itemLists paddingLeft0">
          <span class="selectName">{{ item.label }}</span>
          <el-date-picker
            v-model="formDataCopy[item.key]"
            type="month"
            style="width: 100%"
            placeholder="请选择"
          >
          </el-date-picker>
        </div>
        <div v-if="item.type == 'dateWeek'" class="itemLists paddingLeft0">
          <span class="selectName">{{ item.label }}</span>
          <el-date-picker
            v-bind="item.otherInfo"
            v-model="formDataCopy[item.key]"
            :clearable="false"
            value-format="YYYY-MM-DD"
            type="week"
            format="YYYY [年第] ww [周]"
            placeholder="请选择周期"
            style="width: 100%"
            :picker-options="{ firstDayOfWeek: 1 }"
          />
        </div>
        <div v-if="item.type == 'daterange'" class="itemLists width356">
          <span class="selectName">{{ item.label }}</span>
          <el-date-picker
            v-model="formDataCopy[item.key]"
            type="daterange"
            value-format="YYYY-MM-DD"
            clearable
            v-bind="item.otherInfo"
          >
          </el-date-picker>
        </div>

        <div v-if="item.type == 'cascader'" class="itemLists">
          <span class="selectName">{{ item.label }}</span>
          <el-cascader
            ref="cascader"
            v-model="formDataCopy[item.key]"
            :show-all-levels="false"
            clearable
            :options="item.options"
            v-bind="item.otherInfo"
            :props="{
              checkStrictly: true,
              value: 'name',
              label: 'name',
            }"
          ></el-cascader>
        </div>
      </el-form-item>
      <div v-if="inputConfigArr.length > 3" class="foldCover"></div>
    </el-form>

    <div class="foldRight">
      <!-- 新增按钮 -->
      <div v-if="btnArr.length" class="btnArray">
        <el-button
          v-for="(item, index) in btnArr"
          :key="index"
          :class="[item.btnClass, item.btnType]"
          v-bind="item.configObj"
          :disabled="item.disable"
          @click="item.clickFun"
        >
          <i
            v-if="item.localIcon"
            :class="item.localIcon"
            class="iconfont maright4"
          ></i>
          <SvgIcon
            v-if="item.icon"
            :name="item.icon"
            class-name="menuSvg"
          ></SvgIcon>
          {{ item.text }}
        </el-button>
      </div>
      <slot name="rightBtns"></slot>
      <div v-if="searchSlot" class="btnArray">
        <slot :name="searchSlot"></slot>
      </div>

      <!-- 下载图片等按钮 -->
      <div v-if="spanArr.length" class="btnArray">
        <span
          v-for="(item, index) in spanArr"
          :key="index"
          class="btnSpan"
          v-bind="item.configObj"
          @click="item.clickFun"
        >
          <i v-if="item.localIcon" :class="item.localIcon" class="iconfont"></i>
        </span>
      </div>

      <!-- 收起展开 -->
      <div
        v-if="inputConfigArr.length >= 3"
        class="foldModule"
        @click="foldClick"
      >
        {{ currentTxt }}
        <span class="currentIco" :class="foldType ? 'downIco' : 'upIco'">
          <i v-if="foldType" class="icon-upIco iconfont"></i>
          <i v-else class="icon-downIco iconfont"></i>
        </span>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent, PropType } from "vue";

type InputConfigArr = {
  type: string;
  key: string;
  label: string;
  options?: {
    value?: string | number;
    label?: string;
    code?: string | number;
    name?: string;
  }[];
  hasBtn?: boolean;
  otherInfo: object;
};

type SpanArray = {
  text: string;
  configObj: object;
  uploadConfigObj?: object;
  localIcon?: object;
  icon?: string;
  clickFun: () => void;
};
type BtnArray = {
  text: string;
  configObj: object;
  uploadConfigObj?: object;
  localIcon?: object;
  icon?: string;
  clickFun: () => void;
};
export default defineComponent({
  name: "TopSearchForm",
  props: {
    inputConfigArr: {
      type: Array as PropType<InputConfigArr[]>,
      default: () => [],
    },
    spanArr: {
      type: Array as PropType<SpanArray[]>,
      default: () => [],
    },
    btnArr: {
      type: Array as PropType<BtnArray[]>,
      default: () => [],
    },
    searchSlot: {
      type: String,
      default: "",
    },
    formData: {
      type: Object,
      default: () => ({}),
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["search", "fileUpload", "change"],
  expose: ["refreshForm"],
  data() {
    return {
      currentTxt: "展开",
      foldType: false,
      formDataCopy: {} as Record<string, string>,
      prevFormDataCopy: {} as Record<string, object>,
      firstLoad: true,
    };
  },
  watch: {
    formDataCopy: {
      handler(newName) {
        const stickyItem = this.inputConfigArr.filter((item) => item.hasBtn)[0];
        // eslint-disable-next-line no-empty
        if (
          stickyItem &&
          newName[stickyItem.key] !== this.prevFormDataCopy[stickyItem.key]
        ) {
          /* empty */
        } else {
          if (this.firstLoad && this.$route.meta.keepAlive) {
            this.firstLoad = false;
            return;
          }

          this.$emit("search", this.formDataCopy);
        }
        this.prevFormDataCopy = { ...newName };
      },
      deep: true,
    },
  },
  created() {
    this.formDataCopy = { ...this.formData };
  },
  methods: {
    foldClick() {
      // 收起/展开事件
      this.foldType = !this.foldType;
      this.currentTxt = this.foldType ? "收起" : "展开";
    },
    refreshForm(data: object) {
      this.formDataCopy = { ...data };
    },
  },
});
</script>
<style scoped lang="scss">
.btnSpan {
  display: block;
  width: 30px;
  height: 30px;
  border-radius: 4px;
  border: 1px solid #dcdee6;
  float: left;
  margin-left: 6px;
  cursor: pointer;
  line-height: 28px;
  text-align: center;
}

.btnSpan:hover {
  color: #2468f1;
  border: 1px solid rgba(1, 111, 255, 0.3);
}

.upIco {
  line-height: 16px;
  background: url("@/assets/images/upIco.png") center center / 8px 8px no-repeat,
    #fff !important;
}

.downIco {
  line-height: 14px;
  background: url("@/assets/images/downIco.png") center center / 8px 8px
      no-repeat,
    #fff !important;
}

.iconfont {
  font-size: 8px;
}

.maright4 {
  margin-right: 4px;
  font-weight: bold;
}

.currentIco {
  display: block;
  width: 16px;
  height: 16px;
  background: #ffffff;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.06);
  border: 1px solid #d1d5d9;
  float: right;
  border-radius: 10px;
  margin: 2px 0 0 6px;
  text-align: center;
}

.foldRight {
  /* position: absolute; */
  right: 24px;
  height: auto;
  overflow: hidden;
  top: 24px;
}

.foldModule {
  cursor: pointer;
  float: right;
  font-size: 14px;
  color: #666666;
  width: 54px;
  margin-top: 5px;
}

.formCon {
  /* padding-right: 200px; */
  height: auto;
  overflow: hidden;
  padding-top: 0px;
  margin-bottom: 16px;
  display: flex;
  min-height: 32px;
}

.search ::v-deep(.el-form-item__content) {
  display: flex;
  border-radius: var(--el-input-border-radius, var(--el-border-radius-base));
  overflow: hidden;
}

::v-deep(.el-form) {
  margin-bottom: -12px;
  position: relative;
  display: inline-flex;
  width: 100%;
  flex: 1;
}

.expanded {
  height: 42px;
}

.foldCover {
  flex: 1;
  background: #fff;
  z-index: 2;
}

.foldCover::after {
  position: absolute;
  content: " ";
  width: 71px;
  height: 20px;
  margin-top: 6px;
  background: #fff;
  z-index: 1;
  left: 100%;
}

.search ::v-deep(.el-input__wrapper) {
  border-radius: 0;
}

.search ::v-deep(.el-input) {
  flex-grow: 1;
  width: auto;
}

.search ::v-deep(.el-button) {
  height: 32px;
  border-radius: 0;
  min-width: inherit;
}

.btnArray {
  float: right;
  margin-left: 12px;
}

.itemList {
  width: 240px;
  height: 32px;
  border-radius: 4px;
  float: left;
  position: relative;
  box-sizing: border-box;
}

.itemLists {
  width: 240px;
  height: 32px;
  border-radius: 4px;
  float: left;
  position: relative;
  display: flex;
  align-items: center;
}

.inputTxt {
  width: 240px !important;
  height: 32px;
  font-size: 14px;
  color: #333333;
  line-height: 20px;
  border: none;
  align-items: center;
  display: flex;
}

.searchIco {
  display: block;
  width: 14px;
  height: 14px;
  font-size: 14px;
  color: #ddd;
  position: absolute;
  right: 12px;
  top: 8px;
  cursor: pointer;
  background-image: url(@/assets/images/searchIco.png);
  background-size: 100% 100%;
}

.searchIco:hover {
  color: #2468f1;
}

.selectName {
  float: left;
  width: 56px;
  font-size: 13px;
  color: #666666;
  min-width: fit-content;
  margin: 0px 0px 0px 8px;
  position: absolute;
  z-index: 1;
  margin-left: 8px;
  line-height: 1em;
}

.width616 {
  width: 616px !important;
}

.width300 {
  width: 300px !important;
}

.width356 {
  width: 356px !important;
}

.width240 {
  width: 240px !important;
}

::v-deep(.el-button) {
  border-radius: 4px;
  padding: 5px 14px;
}

::v-deep(.el-button--primary.is-plain) {
  background: #fff;
  border: 1px solid rgba(1, 111, 255, 0.3);
  color: #2468f1;
}

::v-deep(.el-button--primary.is-plain:not(.is-disabled):hover),
::v-deep(.el-button--primary.is-plain:not(.is-disabled):focus) {
  border: 1px solid rgba(1, 111, 255, 1) !important;
  background: #fff !important;
}

.menuSvg {
  width: 14px;
  height: 14px;
  margin-right: 6px;
}

::v-deep(.el-button) {
  height: 32px;
}

.bigBtn {
  padding: 5px 14px;
  height: auto;
  overflow: hidden;
  line-height: 20px;
  font-size: 14px;
  text-align: center;
  display: inline;
  border-radius: 4px;
  margin-left: 8px;
  cursor: pointer;
}

.formBtn {
  padding: 5px 14px;
  height: auto;
  overflow: hidden;
  line-height: 24px;
  font-size: 14px;
  text-align: center;
  display: inline;
  border-radius: 4px;
  margin-left: 8px;
  cursor: pointer;
}

.smallBtn {
  box-sizing: border-box;
  padding: 7px 26px;
  height: auto;
  overflow: hidden;
  font-size: 14px;
  text-align: center;
  display: inline;
  border-radius: 4px;
  margin-left: 8px;
  cursor: pointer;
}

.smallBtn label {
  display: block;
  min-width: 56px;
  cursor: inherit;
}

.bg016FFF {
  border: 1px solid #2468f1;
  color: #fff;
  background-color: #2468f1;
  cursor: pointer;
}

.bg016FFF:hover,
.bgLight:hover {
  border: 1px solid #327fff;
  background-color: #327fff;
  cursor: pointer;
  color: #fff;
}

.bg080B0E {
  border: 1px solid #080b0e;
  color: #fff;
  background-color: #080b0e;
  cursor: pointer;
}

.bg080B0E:hover {
  background: #242d38;
}

.bgFFFFFF {
  border: 1px solid #e3e3e3 !important;
  color: #4e4e4e !important;
  background-color: #fff !important;
  cursor: pointer;
}

.bgFFFFFF:hover {
  border: 1px solid #2468f1 !important;
  color: #2468f1 !important;
  background-color: #fff !important;
  cursor: pointer;
}

.bgF2F3F9 {
  background: #f2f3f9;
  color: #4e4e4e;
}

.bgF2F3F9:hover {
  background-color: #fff;
  border-radius: 4px;
  border: 1px solid rgba(36, 104, 241, 0.5);
  font-size: 14px;
  font-weight: 400;
  color: #2468f1;
}

.bgLight {
  background: rgba(50, 127, 255, 0.05);
  border-radius: 4px;
  border: 1px solid #2468f1;
  cursor: pointer;
  color: #2468f1;
}
.el-button + .el-button {
  margin-left: 8px;
}
::v-deep(.el-select__tags) {
  padding-left: 70px;
  max-width: 150px !important;
}
</style>
