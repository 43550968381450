<!-- MessageCom  -->
<!-- 
   <MessageCom :config="message_config"></MessageCom>
  参数配置： message_config: {
        type: "error",  ------（成功：success，失败：error）
        message: "最长浮窗长度，返回成功提回成功…",  ------（成功、失败的原因描述）
        visible: true, ------（弹框的显示隐藏控制参数）
        clickFun: () => {  ------（弹框点击关闭按钮事件）
            this.closeClick();
          },
      },
     -->

<template>
  <div v-if="config.visible && config.type === 'success'">
    <div class="successMes">
      <i class="successIco icon-successIco iconfont"></i>
      <span>{{ config?.message }}</span>
      <i class="closeIco icon-closeIco iconfont" @click="config.clickFun"></i>
    </div>
  </div>

  <div v-if="config.visible && config.type === 'error'">
    <DialogBox :config="config_message" @close="cancalClick">
      <template #dialogBoxFooter>
        <div class="elFooter">
          <ButtonCom :btn-data="btnBoxData"></ButtonCom>
        </div>
      </template>
    </DialogBox>
  </div>
  <div v-if="config.visible && config.type === 'warning'">
    <div class="warningMes">
      <i class="errorIco icon-errorIco iconfont"></i>
      <span>{{ config?.message }}</span>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent, PropType } from "vue";
export default defineComponent({
  name: "MessageCom",
  props: {
    config: {
      type: Object as PropType<{
        visible: string;
        type: string;
        message: string;
        width: number;
        height: number;
        clickFun: () => void;
      }>,
      default: () => ({}),
    },
  },
  data() {
    return {
      config_message: {
        name: "提示",
        width: "400",
        height: "180",
        type: "message",
        message:
          "很抱歉，我们的服务器似乎出现了一些故障，已向技术人员发送通知，请您稍后再试",
        hintMes: "",
        visible: true,
        isShowFooter: true,
        key: "delete",
        id: "",
      },
      btnBoxData: [
        {
          text: "确定",
          configObj: {
            type: "primary",
          },
          localIcon: "",
          btnType: "smallBtn",
          btnClass: "bg016FFF",
          clickBtn: () => {
            this.cancalClick();
          },
        },
        {
          text: "取消",
          configObj: {
            type: "primary",
          },
          btnType: "smallBtn",
          btnClass: "bgFFFFFF",
          localIcon: "",
          clickBtn: () => {
            this.cancalClick();
          },
        },
      ],
      visible: false,
    };
  },
  watch: {
    "config.visible"(newVal: boolean) {
      this.visible = newVal;
    },
  },
  methods: {
    cancalClick() {
      this.config.visible = false;
    },
  },
});
</script>
<style scoped>
.closeIco {
  font-size: 10px;
  float: right;
  margin: 0px 12px 0 30px;
  cursor: pointer;
}

.errorIco {
  position: absolute;
  width: 16px;
  height: 16px;
  top: 16px;
  left: 10px;
  background: url("@/assets/images/waring.png") 0 0 / 100% 100%;
}

.warningMes {
  width: auto;
  height: auto;
  overflow: hidden;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  background-color: #fef4dc;
  font-size: 14px;
  color: #e47b00;
  border-radius: 4px;
  border: 1px solid #fef4dc;
  top: 80px;
  z-index: 9999;
}

.warningMes span {
  display: block;
  padding: 12px 10px 12px 34px;
  height: auto;
  overflow: hidden;
  line-height: 24px;
}

.successIco {
  font-size: 14px;
  color: #57be25;
  float: left;
  margin: 12px 8px 0 12px;
  width: 14px;
  height: 14px;
  background: url("@/assets/images/success_mes.png") 0 0 / 100% 100%;
}

.successMes {
  width: auto;
  max-width: 800px;
  height: 38px;
  line-height: 38px;
  background-color: #eef8e9;
  font-size: 14px;
  color: #57be25;
  border-radius: 4px;
  border: 1px solid rgba(87, 190, 37, 0.6);
  position: fixed;
  top: 80px;
  right: 0;
  z-index: 999999;
}

.successMes span {
  display: block;
  max-width: 714px;
  height: 38px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  float: left;
}
</style>
