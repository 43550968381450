// index.ts
import { App, Directive } from "vue";
import { permission } from "./permission";
import { loadmore } from "./loadmore";

const directives = {
  permission,
  loadmore,
};
export default function useDirectives(app: App) {
  Object.keys(directives).forEach((key) => {
    app.directive(key, (directives as { [key: string]: Directive })[key]);
  });
}
