<!-- v-model 双向绑定选择的值， type: 默认为radio单选，multiple则为多选，插槽 search 可以后面定义 -->
<template>
  <DialogBox>
    <template #dialogBoxContent>
      <slot name="search"></slot>
      <NormalTable
        ref="tableRef"
        :column-data="columnData"
        :table-data="tableData"
        :highlight-current-row="type === 'radio'"
        :table-height="$attrs.config?.height || 300"
        @current-change="handleCurrentChange($event)"
        @selection-change="handleSelectionChange($event)"
        @click.stop
      >
        <template #select>
          <el-table-column
            v-if="type !== 'radio'"
            type="selection"
            width="55"
          />
          <el-table-column v-else label="" width="43px" align="left">
            <template #default="scoped">
              <span
                :class="[
                  'radioCustom',
                  selectArr.includes(scoped.row[propKey]) ? 'checked' : '',
                ]"
              ></span>
            </template>
          </el-table-column>
        </template>
        <template #statusSlot>
          <el-table-column label="当前状态">
            <template #default="scoped">
              <span
                :class="statusMap[scoped.row.status || scoped.row.currentState]"
                ><i></i>{{ scoped.row.status || scoped.row.currentState }}</span
              >
            </template>
          </el-table-column>
        </template>
      </NormalTable>
    </template>
    <template #dialogBoxFooter>
      <div class="elFooter">
        <ButtonCom :btn-data="btns"></ButtonCom>
      </div>
    </template>
  </DialogBox>
</template>
<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "SelectTable",
  props: {
    columnData: {
      type: Array,
      default: () => [],
    },
    tableData: {
      type: Array,
      default: () => [],
    },
    btns: {
      type: Array,
      default: () => [],
    },
    propKey: {
      type: String,
      default: "id",
    },
    maxSelect: {
      type: Number,
      default: Infinity,
    },
    modelValue: {
      type: [Array, String],
      default: () => [],
    },
    type: {
      type: String,
      default: "radio",
    },
    statusMap: {
      type: Object,
      default: () => ({}),
    },
  },
  emits: ["update:modelValue"],
  data() {
    return {
      tableRef: null,
    };
  },
  computed: {
    selectArr() {
      return Array.isArray(this.modelValue)
        ? this.modelValue.map((v) => v[this.propKey])
        : [this.modelValue[this.propKey]];
    },
  },
  mounted() {
    setTimeout(() => {
      if (!this.$refs.tableRef) {
        const tRef = this.$refs.tableRef?.$refs.myTableRef;
        if (this.type !== "radio") {
          tRef?.clearSelection();
          this.modelValue.forEach((row) => {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            tRef?.toggleRowSelection(row, undefined);
          });
        } else {
          tRef?.setCurrentRow(this.modelValue);
        }
      }
    }, 300);
  },
  methods: {
    handleSelectionChange(
      row: Record<string, string> | Record<string, string>[]
    ) {
      if (this.type === "radio") return;
      this.handleChange(row);
    },
    handleCurrentChange(
      row: Record<string, string> | Record<string, string>[]
    ) {
      if (this.type !== "radio") return;
      this.handleChange(row);
    },
    handleChange(row: Record<string, string> | Record<string, string>[]) {
      let res = row;
      if (Array.isArray(row)) {
        if (row.length > this.maxSelect) {
          this.$store.commit("setErrConfig", {
            visible: true,
            type: "error",
            message: `最多选择${this.maxSelect}条记录`,
          });
          res = (res as any).slice(0, this.maxSelect);
        }
      }
      this.$emit("update:modelValue", res);
    },
  },
});
</script>
<style scope lang="scss">
.radioCustom {
  width: 16px;
  height: 16px;
  background: #ffffff;
  border-radius: 9px;
  border: 1px solid #dcdee6;
  display: inline-block;
  vertical-align: middle;
  &.checked {
    background: #016fff;
    border-color: #016fff;
    position: relative;
    &::after {
      position: absolute;
      content: " ";
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      background: url("@/assets/images/tick.png") center / 10px 10px no-repeat;
    }
  }
}
</style>
