export default {
  placeholder: {
    pleaseInput: "请输入",
    searchKeywords: "搜索关键词",
    startDate: "开始日期",
    endDate: "结束日期",
    pleaseSelect: "请选择",
    successTip: "提交成功",
    pleaseUpload: "请上传文件",
    pleaseInputEmail: "请输入用户名",
    fillUsername: "请填写用户名",
    userNameError: "用户名或密码错误",
    noInfo: "暂无相关信息",
  },
  message: {
    Hello: "你好",
    login: "登录",
    signUpFree: "免费注册",
    welcomeBack: "欢迎回来",
    detailTip: "请输入登录信息",
    userName: "用户名",
    password: "密码",
    forgetPassword: "忘记密码",
    haveAccount: "还没有账号？",
    signup: "注册",
    selectType: "选择创建账号的类型",
    demandSide: "需求方",
    demandIntro: "有出海需要的生物医药企业",
    supplier: "供应方",
    supplierIntro: "能提供出海服务的第三方机构",
    internationalOrg: "国际/外国当地组织",
    internationalOrgIntro:
      "主要涵盖WHO等国际性组织及其分支机构、外国当地商会/协会等半官方机构",
    next: "下一步",
    alreadyAccount: "已经有账号?",
    fillBasic: "基本信息",
    orgFullName: "机构全称",
    email: "邮箱地址",
    confirmPassword: "密码确认",
    emailCode: "邮箱验证码",
    sendCode: "获取验证码",
    agreeTip1: "我已阅读和同意",
    termsService: `服务协议`,
    agreeTip2: ", 以及",
    userAgreement: "许可协议",
    agreeTip3: "和",
    privacyPolicy: "隐私政策",
    createAccount: "创建账号",
    checkTypeTip: "请选择创建账号的类型",
    usernameExist: "该用户名已被占用",
    emailCorrect: "请输入正确的邮箱",
    emailExist: "该邮箱已被注册",
    pwdRule: "8-20位数字、字母或常用符号,字母区分大小写",
    samePwd: "请输入相同密码",
    emailInput: "请输入邮箱",
    beenSent: "已发送",
    home: "首页",
    demandMarket: "需求市场",
    serviceMarket: "服务市场",
    eventRelease: "活动发布",
    courseLearning: "学习课程",
    newsBroadCast: "新闻放送",
    aboutUs: "联系我们",
    improveSelf: "持续提升自我",
    all: "全部",
    popularCourses: "热门课程",
    latestCourses: "最新课程",
    releaseTime: "发布时间",
    browse: "浏览",
    to: "至",
    generalServices: "服务类型/通用型",
    consult: "Consulting service",
    law: "Law",
    business: "Business",
    finance: "Finance",
    financeTax: "Finance and tax",
    other: "其他",
    professionalServices: "服务类型/专业型",
    preCro: "Preclinical CRO",
    clinicalCro: "Clinical CRO",
    CDMO: "CDMO",
    registrationDeclare: "Registration declaration",
    coldChain: "Cold chain",
    storage: "Storage",
    maintenance: "Maintenance",
    targetArea: "出海目标区域",
    america: "美国",
    europe: "欧洲",
    austrailia: "澳洲",
    japan: "日本",
    southeastAsia: "东南亚",
    middleEast: "中东",
    southAmerica: "南美",
    africa: "非洲",
    overseaStrategy: "预计出海策略",
    indepandentOversea: "Independent overseas expansion",
    nonAutonomousOversea: "Non-autonomous overseas",
    jointOversea: "Joint Overseas Expedition",
    destination: "地址",
    strategy: "出海策略",
    excitingMoments: "激动人心的时刻",
    internationalAct: "国际活动",
    domesticAct: "国内活动",
    activityTime: "活动时间",
    remainingStart: "距离活动开始的天数",
    eventEnd: "活动已结束",
    releaseAct: "发布活动",
    themeName: "活动主题",
    uploadEvent: "上传活动封面",
    uploadTip: "格式支持JPG、JPEG、PNG，单张图片大小不超过10M",
    uploadPic: "上传图片",
    actType: "活动类型",
    organizer: "主办单位",
    actTime: "活动时间",
    defaultsOn: "默认发布",
    eventAddress: "活动地址",
    activites: "活动介绍",
    release: "发布",
    notRelease: "未发布",
    language: "语言",
    newsInfo: "新闻放送",
    stayUpDate: "了解行业最新动态",
    viewDetail: "查看详情",
    visits: "访客量",
    contactUs: "联系我们",
    contactNumber: "联系电话",
    mailbox: "邮箱",
    serviceAddress: "服务地址",
    serviceAddressContent: "上海市浦东新区金港路766号",
    technicalSupport: "技术支持",
    supportingUnits: "支持单位",
    supportingUnitsConent: "中国信通院上海工创中心",
    companyAddress: "单位地址",
    companyAddressContent: "上海市浦东新区金港路766号",
    quickNavigation: "快速链接",
    feedbackOpinion: "意见反馈",
    advertiseContact: "广告联系",
    bioCollege: "Bio+ college",
    municipalAdministration: "上海市市场监督管理局",
    joinNow: "现在加入",
    platformIntroduction: "平台介绍",
    officialAccount: "官方账号",
    advertisePlacement: "商务合作",
    opinionsFeedback: "意见反馈",
    opinionIntro:
      " 欢迎您留下您宝贵意见，我们将携手共同建设美好的出海服务环境，针对您反馈的意见，我们将进行不定期的统一回复，感谢您对我们出海服务的大力支持，我们致力于打造美好和谐的出海服务环境。",
    contactInfo: "联系信息",
    name: "姓名",
    telephone: "电话",
    theme: "主题",
    feedbackContent: "内容",
    feedback: "反馈",
    submit: "提交",
    CN: "中文",
    EN: "英语",
    seeDetails: "查看详情",
    personalCenter: "个人中心",
    essentialInfo: "基本信息",
    realNameAuth: "企业认证",
    messageCenter: "消息中心",
    myData: "我的信息",
    enterpriseRealName: "机构认证",
    institutionName: "机构名称",
    socialCreditCode: "社会信用代码",
    legalName: "企业法人姓名",
    idNumber: "法人身份证号",
    uploadLicense: "营业执照上传",
    uploadID: "法人身份证正面照片上传",
    confirm: "提交",
    uploadFile: "上传文件",
    registeredOn: "注册时间",
    productCurrentStatus: "产品目前状态",
    demandInfo: "需求方信息",
    serviceInfo: "服务方信息",
    getContactInfo: "查看联系方式",
    relatedInfo: "关联信息",
    expectedStrategy: "预计出海策略",
    difficulties: "目前的困难或瓶颈",
    hopeSupport: "希望得到的服务和支持",
    serviceDesc: "服务内容描述",
    hi: "你好",
    demandDetail: "需求详情",
    eventDetail: "活动详情",
    ended: "已结束",
    detailIntro: "详情介绍",
    popularRec: "热门推荐",
    newsDetail: "新闻详情",
    logout: "退出登录",
    topic: "留言主题或留言人",
    resetPwd: "重置密码",
    resetPwdTip: "输入邮箱登录重置密码",
    newPwd: "新密码",
    bannerSubTitle:
      "这是一个占位符，稍后会添加具体文本 这是一个占位符，这是一个占位符，稍后会添加具体文本 这是一个占位符，稍后会添加具体文本这是一个占位符",
    viewNow: "现在查看",
    totalDemand: "需求累计总量",
    totalService: "服务累计总量",
    totalActivities: "活动累计总量",
    totalSettleEnterprise: "入驻企业总计",
    lastRequirementBrief: "最新需求简报",
    viewMoreRequirement: "查看更多需求",
    popularService: "热门服务",
    viewMoreServices: "查看更多服务",
    latestActivities: "最新活动",
    viewMoreActivities: "查看更多活动",
    viewMore: "查看更多",
    popularCourse: "热门课程",
    newsAndInfo: "新闻放送",
    projectCollect: "2024年出海项目征集",
    projectBackground: "项目背景",
    overseaProject: "出海征集方案模板",
    uploadFillingDoc: "上传填报完成的文档",
    myDemand: "我的需求",
    myService: "我的服务",
    createDemand: "创建需求",
    createService: "创建服务",
    addDemand: "发布需求",
    addService: "发布服务",
    productName: "拟出海产品/服务名称",
    productCategory: "产品类型及种类",
    productStatus: "产品目前状态",
    uploadProductImg: "上传产品图片",
    contactPerson: "联系人",
    contactPhone: "联系方式",
    orgType: "注册类型",
    usci: "统一社会信用代码",
    contactAddress: "联系地址",
    overseaProdInfo: "出海产品信息",
    prodOverseaStatus: "产品出海状态",
    alreadyOverseaProd: "是否已有出海产品",
    overseaProdCount: "出海产品数量",
    productType: "出海产品类型及种类",
    currentOverseaStrategy: "目前采用的出海策略",
    currentOverseaCountry: "目前已出海国家/区域",
    currentStep: "在出海国所处阶段",
    ableTargetRegion: "可出海服务区域",
    planOverseaRegion: "规划可出海服务区域",
    serviceSupplyInfo: "服务提供信息",
    editContact: "修改联系方式",
    goAuthentication: "去认证",
    editEnterprise: "修改企业名称代码",
    alreadyOverseas: "已经出海",
    notOverseas: "尚未出海",
    layoutOverseas: "尚未出海,但正在积极布局",
    sales: "已上市销售",
    approving: "审评审批中",
    yes: "是",
    no: "否",
    userDoc: "用户上传的文档",
    edit: "编辑",
    serviceDetail: "服务详情",
    delete: "删除",
    deleteTip: "删除提示",
    deleteMsg: "请确认是否删除？删除后信息将在平台中不再显示",
    cancel: "取消",
    aboutus1: `随着 Payload 的发展，他们的客户希望找到一种更简单的方法来部署基于 Node.js 框架的 CMS。就在那时，Payload 的创始人决定探索不同的平台，让他们和他们的用户只需单击一下即可轻松部署，最终找到了 DigitalOcean 的应用平台。`,
    aboutus2: `正如 Elliot 所说：“当我们尝试 DigitalOcean 的应用平台时，我们可以使用 Dockerfile，只需通过 API 推送即可部署。我们意识到我们可以省去一半的代码。我们也对应用平台提供的其他一些功能感到满意，比如 Web 钩子。”`,
    aboutus3: `Payload 喜欢使用通过 App Platform 提供的强大 API，

他们发现他们可以通过编程完成大多数任务。他们还使用 Web 挂钩进行警报和更新。对于成长中的企业来说，App Platform 的简单性和功能性相结合对 Payload 非常有效，Elliot 说：“App Platform 在能够自定义事物方面达到了最佳平衡点，而且

无需构建我们自己的运行器或部署器”，这为他们的团队节省了宝贵的时间。正如 Elliot 所说：“当我们尝试 DigitalOcean 的 App Platform 时，我们可以使用 Dockerfile 并通过 API 推送它进行部署。我们意识到我们可以减少一半我们编写的代码。我们还对 App Platform 提供的一些其他功能感到满意，例如 Web 挂钩。`,
    isread: "已读",
    unread: "未读",
    opnionSuggestion: "意见与建议",
    complain: "投诉",
    messageUser: "留言主题/留言人",
    message: "消息",
  },
};
