<!--引入组件
     <OperationCom
                :items="scoped.row.operateBtnStatus"
                :operate-item="scoped.row"
                :max-length="1"
                @route-event="operateEvent"
              ></OperationCom>

          //追加操作按钮list
          this.tableData = data.list.map((item) => {
          return {
            ...item,
            operateBtnStatus: item.status ? ["编辑", "禁用"] : ["编辑", "启用"],
          };
        });

        // 操作事件
      operateEvent(data, name) {
      switch (name) {
        case "禁用":
        case "启用":
          this.clickEnableUser(data);
          break;
        case "编辑":
          this.clickEditDetail(data);
          break;
      }
    },
     -->
<template>
  <div v-if="items" class="operationCom">
    <div v-if="items.length > maxLength">
      <div
        v-for="(el, i) in showBtn"
        :key="i"
        class="operationBtn"
        @click.stop="routeEvent(operateItem, el)"
      >
        <span class="nameIco"> {{ el }}</span>
      </div>
      <div class="moreBtn">
        <el-dropdown @command="handleCommand">
          <span class="moreIco" @click.stop></span>
          <template #dropdown>
            <el-dropdown-menu>
              <el-dropdown-item
                v-for="(item, index) in dropData"
                :key="index"
                :command="beforeHandleCommand(operateItem, item)"
              >
                {{ item }}</el-dropdown-item
              >
            </el-dropdown-menu>
          </template>
        </el-dropdown>
      </div>
    </div>
    <div v-else style="display: flex">
      <div v-for="(item, index) in items" :key="index" class="btn-box">
        <el-button type="text" @click.stop="routeEvent(operateItem, item)">
          <span> {{ item }}</span>
        </el-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  comments: {},
  props: {
    // 按钮集合
    items: {
      type: Array,
      default: () => {
        return [];
      },
    },
    // 按钮行数据
    operateItem: {
      type: Object,
      default: () => {},
    },
    // 默认超过2个按钮显示隐藏下拉，否则平铺
    maxLength: {
      type: Number,
      default: () => 2,
    },
  },
  data() {
    return {
      dropData: [], // 下拉的按钮组
      showBtn: [], // 不隐藏的按钮
    };
  },
  watch: {
    items: {
      deep: true,
      handler(newVal, oldVal) {
        if (this.items.length > this.maxLength) {
          this.showBtn = this.items.slice(0, this.maxLength);
          this.dropData = this.items.slice(this.maxLength);
        }
      },
    },
  },
  mounted() {
    if (this.items.length > this.maxLength) {
      this.showBtn = this.items.slice(0, this.maxLength);
      this.dropData = this.items.slice(this.maxLength);
    }
  },
  methods: {
    // 正常按钮点击事件
    routeEvent(data, name) {
      this.$emit("routeEvent", data, name);
    },
    beforeHandleCommand(data, name) {
      return {
        data,
        name,
      };
    },
    // 下拉菜单点击事件
    handleCommand(command) {
      this.routeEvent(command.data, command.name);
    },
  },
};
</script>
<style lang="scss" scoped>
.link-text .el-button {
  width: 100%;
  text-align: left;
}

.el-dropdown-link {
  vertical-align: text-top;
}
.el-dropdown {
  vertical-align: middle;
}
.operationBtn {
  font-weight: 400;
  font-size: 13px;
  color: #2468f1;
  line-height: 18px;
  text-align: left;
  font-style: normal;
  float: left;
  margin-right: 16px;
  margin-top: 4px;
}
.moreBtn {
  float: left;
  height: auto;
}
.moreBtn:hover .moreBtnList {
  display: block;
}
.moreIco {
  display: block;
  width: 24px;
  height: 24px;
  font-size: 13px;
  line-height: 26px;
  text-indent: 6px;
  color: #2468f1;
  border-radius: 4px;
  position: relative;
  float: left;
}
.nameIco {
  height: 24px;
  font-size: 13px;
  text-indent: 6px;
  color: #2468f1;
  border-radius: 4px;
  position: relative;
}
.moreIco::after {
  content: "";
  display: block;
  width: 14px;
  height: 4px;
  background-image: url(../../assets/icons/more.png);
  background-size: 100% 100%;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -7px;
  margin-top: -2px;
}
.moreIco:hover {
  background-color: rgba(4, 23, 118, 0.08);
}
.moreBtnList {
  display: none;
  position: absolute;
  width: 104px;
  height: auto;
  overflow: hidden;
  background: #ffffff;
  box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  border: 1px solid #eeeeee;
  z-index: 9999;
  left: 0;
  top: 40px;
}
.moreBtnList span {
  display: block;
  padding: 5px 10px;
  height: 30px;
  font-weight: 400;
  font-size: 14px;
  color: #333333;
  line-height: 30px;
  text-align: left;
  font-style: normal;
}
.moreBtnList span:hover {
  background: #f7f9fa;
  font-weight: 400;
  font-size: 14px;
  color: #2468f1;
  line-height: 30px;
  text-align: left;
  font-style: normal;
}
</style>
<style>
.el-dropdown__popper {
  width: 104px !important;
  background: #ffffff;
  box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  border: 1px solid #eeeeee;
  text-align: left;
}
.el-dropdown-menu__item {
  padding: 5px 10px !important;
  font-weight: 400;
  font-size: 14px;
  color: #333333;
  line-height: 30px;
  text-align: left;
  font-style: normal;
}
:focus-visible {
  outline: none !important;
}
.btn-box .el-button {
  font-weight: 400;
  font-size: 13px;
  color: #2468f1;
  line-height: 18px;
  text-align: left;
  font-style: normal;
}
</style>
