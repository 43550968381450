<!-- 使用示例
<CircularCheckBox v-model="formData.deliveryMethods" :optionList="deliveryOptions"></CircularCheckBox> -->
<template>
  <div class="answer_box">
    <div
      v-for="item in optionList"
      class="answer_item"
      @click="setCheck(item.code)"
    >
      <i
        class="select_region"
        :class="modelValue?.includes(item.code) ? 'on' : ''"
      ></i>
      <span class="value">{{ item.name }}</span>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent, PropType } from "vue";
export default defineComponent({
  name: "CircularCheckBox",
  props: {
    config: {
      type: Object as PropType<{
        visible: string;
        name: string;
        type: string;
        isShowFooter: boolean;
        message: string;
        img: string;
        hintMes: string;
        time: string;
        width: number;
        height: number;
        customClass: string;
        data: Array<ConfigData>;
        page: number;
        size: number;
        messageList: Array<ConfigData>;
        key?: string;
      }>,
      default: () => ({
        page: 1,
        size: 10,
      }),
    },
    optionList: {
      type: Array,
      default: () => [],
    },
    modelValue: {
      type: Array,
      default: () => [],
    },
  },
  emits: ["update:modelValue"],
  data() {
    return {
      visible: false,
    };
  },
  watch: {
    "config.visible": {
      handler(newVal: boolean) {
        this.visible = newVal;
      },
      immediate: true,
    },
  },
  methods: {
    setCheck(checkValue) {
      if (this.modelValue?.includes(checkValue)) {
        this.modelValue.splice(this.modelValue.indexOf(checkValue), 1);
        this.$emit("update:modelValue", [...this.modelValue]);
        return;
      }
      if (Array.isArray(this.modelValue)) {
        this.$emit("update:modelValue", [...this.modelValue, checkValue]);
      } else {
        this.$emit("update:modelValue", [checkValue]);
      }
    },
  },
});
</script>
<style lang="scss" scoped>
.answer_box {
  width: 100% !important;
  height: auto;
  display: flex;
  flex-wrap: wrap;
  gap: 14px 24px !important;

  .answer_item {
    min-height: 20px;
    display: flex;
    cursor: pointer;

    .select_region {
      width: 18px;
      height: 18px;
      box-sizing: border-box;
      border: 1px solid #dcdee6;
      background-color: #ffffff;
      display: inline-block;
      vertical-align: top;
      margin: 1px 6px;
      border-radius: 4px;
      cursor: pointer;
    }

    .select_region.on {
      border: 1px solid #2468f1;
      background-color: #2468f1;
      background-image: url(@/assets/icons/active_icon.png);
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center center;
    }

    .value {
      width: 100%;
      flex: 1;
      font-weight: 400;
      font-size: 14px;
      color: #333333;
      line-height: 20px;
      text-align: left;
      font-style: normal;
      vertical-align: middle;
    }
  }

  .answer_item:last-child {
    margin-bottom: 0;
  }

  .answer_item:hover {
    background-color: #f5f7fa;
  }

  .answer_input_value {
    width: 280px;
    height: 36px;
    display: flex;
    cursor: pointer;
    margin-top: 20px;

    > .label {
      display: inline-block;
      height: 36px;
      line-height: 36px;
      background-color: transparent;
      padding: 0 20px 0 8px;
      font-size: 16px;
      color: #333333;
    }

    ::v-deep {
      .el-input {
        flex: 1;

        .el-input__wrapper {
          border-bottom: 1px solid #dcdfe6;
          box-shadow: none;
          padding: 0 0;

          .el-input__inner {
            font-size: 16px !important;
            color: #333333;
          }
        }
      }
    }
  }
}
</style>
