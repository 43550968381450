import axios from "axios";
import {
  getDownloadUrlByPath,
  getDownloadFilePath,
  uploadSliceFile,
} from "../api/common";
const CancelToken = axios.CancelToken;

// 过滤权限
export function filterPermissions(
  allowOrgTypes: string[],
  orgType: string,
  roles: [Record<string, string[]>, string[]]
) {
  let menus = roles[0]?.reduce((item, currentItem) => {
    if (currentItem.children.length) {
      item.concat([currentItem.code]);
      return item.concat(currentItem.children.map((value) => value.code));
    } else {
      return item.concat([currentItem.code]);
    }
  }, []);
  if (!menus || menus.length === 0) menus = [];

  const hasRight = roles[1].some((v: string) => menus.includes(v));
  return hasRight || roles[1][0] === "*";
}

// 简单的JS下载方案
export async function downloadFileSimple(
  url: string,
  fileName: string,
  local = false
) {
  if (typeof url === "string" && !url.startsWith("http") && !local) {
    const { data: downloadUrl } = await getDownloadUrlByPath(url);
    url = downloadUrl;
  }
  const anchor = document.createElement("a");
  fileName = fileName || "download";
  if ("download" in anchor) {
    if (typeof url === "string") {
      anchor.href = url;
    } else {
      anchor.href = URL.createObjectURL(url);
    }

    anchor.setAttribute("download", fileName);
    anchor.className = "download-js-link";
    anchor.innerHTML = "downloading...";
    anchor.style.display = "none";
    document.body.appendChild(anchor);
    setTimeout(function () {
      anchor.click();
      document.body.removeChild(anchor);
    }, 66);
    return true;
  }
}

export function downloadFileByID(id: string) {
  getDownloadFilePath(id).then(({ data }) => {
    downloadFileSimple(data.filePath, data.name);
  });
}

// 限制只能正整数
export function checkIsPositiveNum(value: string) {
  return value && Number.isInteger(parseInt(value)) && parseInt(value) > 0;
}

// 校验密码格式
export function checkPwdFormat(value: string) {
  // 应该包含大写字母、小写字母、数字，1个特殊字符，长度8-20位
  return /^(?=.*[A-Za-z])(?=.*[!@#$%^&*()-_=+\\\|\[\]{};:\'",.<>?\/0-9])[A-Za-z0-9!@#$%^&*()-_=+\\\|\[\]{};:\'",.<>?\/]{8,20}$/gi.test(
    value
  );
}

// 校验邮箱格式
export function checkEmailFormat(value: string) {
  return value && /^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/gi.test(value);
}

// 校验手机号格式
export function checkMobileFormat(value: string) {
  const mobileReg = /^1[3-9]\d{9}$/;
  return value && mobileReg.test(value);
}

// 校验手机号或固定座机号码格式
export function testPhone(phone: string) {
  const reg = /^(\d+-)+\d+$/;
  return checkMobileFormat(phone) || reg.test(phone);
}

// 复制操作
export function copyText(
  copyContent: string,
  callback?: (res: "success" | "fail") => void
) {
  if (navigator.clipboard && window.isSecureContext) {
    navigator.clipboard.writeText(copyContent).then(
      function () {
        const res = "success";
        callback && callback(res);
      },
      function (err) {
        const input = document.createElement("input");
        document.body.appendChild(input);
        input.setAttribute("value", copyContent);
        input.select();
        if (document.execCommand("copy")) {
          document.execCommand("copy");
        }
        document.body.removeChild(input);
        const res = "success";
        callback && callback(res);
      }
    );
  }
}

// 提取html中的内容
export function getTextContent(str = "") {
  if (str.includes("video")) return [true];
  str = str.replaceAll(/\sstyle="(.*?)"/gi, "").replaceAll(/<br\/?>/gi, "");
  const reg = /<[a-zA-Z]+.*?>([\s\S]*?)<\/[a-zA-Z]*?>/gi;
  const res = [];
  if (reg.exec(str)) {
    res.push(
      RegExp.$1
        .trim()
        .replaceAll(/<[a-zA-Z]*?>([\s\S]*?)/gi, "")
        .replaceAll(/[(\s)|(&nbsp;)]/gi, "")
    );
  }
  return res.filter((v) => !!v);
}

function createFormDate(
  fileID: string,
  file: File,
  vernier: number,
  chunk: number,
  chunks: number,
  CHUNK_SIZE: number
) {
  const data = new FormData();
  data.append("origin", "SELECTION_SELECTION");
  data.append("chunks", chunks.toString());
  data.append("chunk", chunk.toString());
  data.append("id", fileID);
  data.append("name", file.name);
  const start = vernier;
  const end = vernier + CHUNK_SIZE;
  data.append("file", file.slice(start, end));
  return data;
}

const onUploadProgress = (arr: number[], i: number) => (pe: ProgressEvent) => {
  arr[i] = pe.loaded;
};

// 上传大文件
export function uploadBigFile(
  params: FormData,
  callbackFun?: (res: string, data: string) => void
) {
  const file = params;
  const SIZE = file.size;
  const totalSize = SIZE;
  const sequence: Array<number> = [];
  const percentage = Math.floor(
    (sequence.reduce((acc, cur) => acc + cur, 0) / totalSize) * 100
  );
  const status = "exception";
  const obj = {
    percentage,
    status,
    totalSize,
    showCloseIcon: false,
    fileName: file.name,
    sequence,
  };
  const CHUNK_SIZE = 5 * 1024 * 1024;
  const chunks = Math.ceil(SIZE / CHUNK_SIZE);
  let vernier = 0;
  const fileID = `file_${Date.now()}`;
  const source = CancelToken.source();
  let hasUploadChunk = 0;
  const upSlice = () => {
    if (vernier < SIZE) {
      const idx = sequence.length;
      const chunk = idx + 1;
      sequence.push(0);
      const data = createFormDate(
        fileID,
        file,
        vernier,
        chunk,
        chunks,
        CHUNK_SIZE
      );
      vernier += CHUNK_SIZE;
      const size = (data.get("file") as Blob).size;
      const config = {
        onUploadProgress: onUploadProgress(sequence, idx),
        cancelToken: source.token,
      };
      uploadSliceFile(data, config)
        .then(({ data }: { data: string }) => {
          sequence[idx] = size;
          obj.percentage = Math.floor(
            (sequence.reduce((acc, cur) => acc + cur, 0) / totalSize) * 100
          );
          obj.status = obj.percentage === 100 ? "success" : "exception";
          hasUploadChunk++;
          if (!data.filePath) {
            callbackFun &&
              callbackFun(
                "uploading",
                parseInt((hasUploadChunk / chunks) * 100) + ""
              );
            upSlice();
          } else {
            callbackFun && callbackFun("success", data);
          }
        })
        .catch((err: string) => {
          source.cancel();
          callbackFun && callbackFun("error", err);
        });
    }
  };
  const firstUploadNum = Math.ceil(totalSize / CHUNK_SIZE);
  for (
    let index = 0;
    index < (firstUploadNum < 3 ? firstUploadNum : 3);
    index++
  ) {
    upSlice();
  }
}

export function setName(name: string) {
  localStorage.setItem(`name`, name);
}
export function getName() {
  return localStorage.getItem(`name`);
}

export function setRoleShowName(roles: string) {
  localStorage.setItem(`roleShowName`, roles);
}
export function getRoleShowName() {
  return localStorage.getItem(`roleShowName`);
}

export function setRole(roles: string) {
  localStorage.setItem(`role`, JSON.stringify(roles));
}
export function getRole() {
  const roleStr = localStorage.getItem(`role`);
  return roleStr ? JSON.parse(roleStr) : roleStr;
}

export function setToken(token: string) {
  localStorage.setItem(`token`, token);
}
export function getToken() {
  return localStorage.getItem(`token`);
}

export function setMenus(menu: object[]) {
  localStorage.setItem(`menu`, JSON.stringify(menu));
}
export function getMenus() {
  const menuStr = localStorage.getItem(`menu`);
  return menuStr ? JSON.parse(menuStr) : menuStr;
}

export function setSubmitToken(token: string) {
  localStorage.setItem(`submitToken`, token);
}
export function getSubmitToken() {
  return localStorage.getItem(`submitToken`);
}

// elment-form校验手机号
export function validateMobile(
  rule: unknown,
  value: string,
  callback: (param?: Error) => void
) {
  if (!checkMobileFormat(value)) {
    callback(new Error("手机号格式错误"));
    return false;
  } else {
    callback();
    return true;
  }
}

// elment-form校验文件上传
export function validateFileUpload(
  rule: unknown,
  value: Record<string, string>,
  callback: (param?: Error) => void
) {
  if (!value?.fileName || !value?.filePath) {
    callback(new Error("请上传文件"));
    return false;
  } else {
    callback();
    return true;
  }
}

// 身份证号脱敏
export function transIdCard(id: string) {
  return id?.replace(/^(.{4}).*(.{4})$/, "$1**********$2");
}

// 手机号脱敏
export function transMobile(mobile: string) {
  return mobile?.replace(/^(\d{3})\d*(\d{4})$/, "$1****$2");
}

export function debounce(fn: any, immediate = true, wait = 1000) {
  let timeout;
  return function () {
    const ctx = this;
    const args = arguments;
    if (timeout) clearTimeout(timeout);
    if (immediate) {
      const callNow = !timeout;
      // wait秒后清除定时器
      timeout = setTimeout(() => {
        timeout = null;
      }, wait);
      // 先执行函数
      if (callNow) fn.apply(ctx, args);
    } else {
      // wait秒后执行fn
      timeout = setTimeout(() => {
        fn.apply(ctx, args);
      }, wait);
    }
  };
}

export function isImgClassify(url = "") {
  return ["jpg", "jpeg", "png"].includes(
    url.split("?")[0].split(".").slice(-1)[0]
  );
}

export function assignNotNullObj(origin: any, newVal: any) {
  for (const key of Object.keys(newVal)) {
    if (!newVal[key]) {
      newVal[key] = origin[key];
    }
  }
  return newVal;
}

export function getMonthsList(startYear, startMonth, endYear, endMonth) {
  const months = [];
  for (let year = startYear; year <= endYear; year++) {
    for (let month = 1; month <= 12; month++) {
      if (year === startYear && month < startMonth) continue;
      if (year === endYear && month > endMonth) break;
      months.push(`${year}-${month.toString().padStart(2, "0")}`);
    }
  }
  return months;
}

export function getCurrentMonth() {
  const now = new Date();
  const year = now.getFullYear();
  const month =
    now.getMonth() + 1 > 10 ? now.getMonth() + 1 : `0` + (now.getMonth() + 1); // 月份从0开始，所以需要加1}

  const currentTime = `${year}-${month}`;
  return currentTime;
}
export function getPreMonth(date) {
  const arr = date.split("-");
  const year = arr[0]; // 获取当前日期的年份
  const month = arr[1]; // 获取当前日期的月份
  let year2 = year;
  let month2 = parseInt(month) - 1;
  if (month2 == 0) {
    year2 = parseInt(year2) - 1;
    month2 = 12;
  }
  if (month2 < 10) {
    month2 = "0" + month2;
  }
  const t2 = year2 + "-" + month2;
  return t2;
}
export function getNextMonth(date) {
  const arr = date.split("-");
  const year = arr[0]; // 获取当前日期的年份
  const month = arr[1]; // 获取当前日期的月份
  let year2 = year;
  let month2 = parseInt(month) + 1;
  if (month2 == 13) {
    year2 = parseInt(year2) + 1;
    month2 = 1;
  }
  if (month2 < 10) {
    month2 = "0" + month2;
  }

  const t2 = year2 + "-" + month2;
  return t2;
}

export function getWeeksSince(startYear, startMonth, startDay) {
  const weeksList = [];
  const startDate = new Date(startYear, startMonth - 1, startDay); // JS中月份是从0开始的
  const currentDate = new Date(startDate);
  const today = new Date();

  while (currentDate <= today) {
    if (currentDate.getDay() === 1) {
      // 当前日期是一周中的周一
      const weekStart = new Date(currentDate);
      const weekEnd = new Date(weekStart);
      weekEnd.setDate(weekStart.getDate() + 6); // 设置周日

      const weekNumber = getISOWeek(weekStart);

      weeksList.push({
        weekNumber,
        weekStart: format(weekStart),
        weekEnd: format(weekEnd),
      });
    }
    currentDate.setDate(currentDate.getDate() + 1);
  }

  return weeksList;
}

function getISOWeek(date) {
  // 计算ISO周数
  const onejan = new Date(date.getFullYear(), 0, 1);
  const dayOfWeekOneJan = onejan.getDay() || 7;
  const dayOfYear = Math.ceil((date - onejan) / 86400000 + 1);
  const week = Math.ceil(
    (dayOfYear - dayOfWeekOneJan + dayOfWeekOneJan + 6) / 7
  );
  return week;
}
function format(date) {
  return `${date.getFullYear()}-${padZero(date.getMonth() + 1)}-${padZero(
    date.getDate()
  )}`;
}

function padZero(num) {
  return num < 10 ? "0" + num : num;
}
export function getLastMonday(dateString) {
  const date = new Date(dateString);
  // 找到本周的周一，先减去当前日期是周几的天数
  const weekday = date.getDay() || 7; // 周日当作第7天来处理
  const thisMonday = new Date(date.setDate(date.getDate() - (weekday - 1)));
  // 再减去7天，得到上一周的周一
  const lastMonday = new Date(thisMonday.setDate(thisMonday.getDate() - 7));
  // 格式化日期
  const year = lastMonday.getFullYear();
  const month = padZero(lastMonday.getMonth() + 1);
  const day = padZero(lastMonday.getDate());
  return `${year}-${month}-${day}`;
}
export function getWeekStart(dateStr) {
  const date = new Date(dateStr);
  const dayOfWeek = date.getDay(); // 0 is Sunday, 1 is Monday, etc.
  const daysToSubtract = dayOfWeek === 1 ? 0 : dayOfWeek - 1;
  date.setDate(date.getDate() - daysToSubtract);
  return format(date);
}
export function getNextMonday(dateStr) {
  const date = new Date(dateStr);
  // 找到本周的周一，先减去当前日期是周几的天数
  const weekday = date.getDay() || 7; // 周日当作第7天来处理
  const thisMonday = new Date(date.setDate(date.getDate() - (weekday - 1)));
  // 再减去7天，得到上一周的周一
  const lastMonday = new Date(thisMonday.setDate(thisMonday.getDate() + 7));
  // 格式化日期
  const year = lastMonday.getFullYear();
  const month = padZero(lastMonday.getMonth() + 1);
  const day = padZero(lastMonday.getDate());
  return `${year}-${month}-${day}`;
}

export function sortChange({ column, prop, order }) {
  const sortType =
    order === null ? null : order === "descending" ? "DESC" : "ASC";
  const sortBy = order === null ? null : prop;
  this.sortParams.sortBy = sortBy;
  this.sortParams.sortType = sortType;
  this.getDataList();
}

export function getInitList(params: any) {
  this.pageObj.page = 1;
  this.getDataList(params);
}

export function getText(str: any) {
  return str.replace(/<[^<>]+>/g, "").replace(/&nbsp;/gi, "");
}

/**
 * 将日期格式化为指定格式的字符串
 * @param date - 要格式化的日期
 * @param format - 目标格式，例如 'yyyy-MM-dd HH:mm:ss'
 * @returns 格式化后的日期字符串
 */
export function formatDate(date, format) {
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const day = date.getDate().toString().padStart(2, "0");
  const hours = date.getHours().toString().padStart(2, "0");
  const minutes = date.getMinutes().toString().padStart(2, "0");
  const seconds = date.getSeconds().toString().padStart(2, "0");

  format = format.replace("yyyy", year);
  format = format.replace("MM", month);
  format = format.replace("dd", day);
  format = format.replace("HH", hours);
  format = format.replace("mm", minutes);
  format = format.replace("ss", seconds);

  return format;
}
