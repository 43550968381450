// store/index.ts
import { createStore } from "vuex";
interface PermissionInfo {
  id: number;
  name: string;
  description: string;
  code: string;
  childPermission: PermissionInfo[];
}
interface UserInfo {
  userId: number;
  userName: string;
  userPhone: string;
  organizationId: number;
  organizationName: string;
  organizationType: string;
  roleId: number;
  roleName: string;
  email: string;
  permissionInfo?: PermissionInfo[];
  permissions: Record<string, string[]>;
  account: Record<string, string>;
  organization: Record<string, string>;
  menus: Record<string, string>[];
}
export type StateType = {
  loadedTempo: string;
  userInfo: UserInfo | null;
  loadingStatus: boolean;
  expandMenu: boolean;
  errConfig: object & {
    visible: boolean;
  };
  previewImageUrl: string;
  previewImageVisible: boolean;
  previewImageW: string | number;
  isVideo: boolean;
  loginVisible: boolean;
  langCode: string;
};
let timer: number | undefined;
const store = createStore({
  state() {
    return {
      userInfo: null,
      expandMenu: false,
      loginVisible: false,
      loadingStatus: false,
      langCode: "zh-CN",
      errConfig: {
        type: "error",
        message: "",
        visible: false,
        clickFun: () => {
          timer && clearTimeout(timer);
          store.state.errConfig.visible = false;
        },
      },
      previewImageUrl: "",
      previewImageVisible: false,
      previewImageW: "auto",
    };
  },
  getters: {
    getRoleId(state: StateType) {
      return state.userInfo?.roleId;
    },
    getOrgType(state: StateType) {
      // 获取机构类型
      return state.userInfo?.organizationType;
    },
    getOrgDirection(state: StateType) {
      // 获取机构类型
      return state.userInfo?.organization.direction;
    },
    getIsAdmin(state: StateType) {
      // 区分企业和平台, true 平台， false 企业
      return state.userInfo?.organizationType === "ADMIN";
    },
    getOrgId(state: StateType) {
      // 获取组织机构ID
      return state.userInfo?.organization?.id;
    },
    getAcctName(state: StateType) {
      // 获取账户名称
      return state.userInfo?.account?.name;
    },
    getBindPhone(state: StateType) {
      // 获取手机号
      return state.userInfo?.account?.phone;
    },
    getAuthenticationStatus(state: StateType) {
      // 获取是否认证通过
      return state.userInfo.organization.authenticationStatus;
    },
    getAuthenticationFailReason(state: StateType) {
      // 获取认证失败原因
      return state.userInfo.organization.reason;
    },
    getLoadingStatus(state: StateType) {
      return state.loadingStatus;
    },
    getLoginVisible(state: StateType) {
      return state.loginVisible;
    },
    getLangCode(state: StateType) {
      return state.langCode;
    },
  },
  mutations: {
    loadedTempo(state: StateType, data: any) {
      state.loadedTempo = data;
    },
    setUserInfo(state: StateType, payload: UserInfo) {
      state.userInfo = payload;
    },
    expandMenuFun(state: StateType) {
      state.expandMenu = !state.expandMenu;
    },
    setLoadingStatus(state: StateType) {
      state.loadingStatus = state.loadingStatus;
    },
    setLoginVisible(state: StateType, payload: boolean) {
      state.loginVisible = payload;
    },
    setLangCode(state: StateType, payload: string) {
      state.langCode = payload;
    },
    setWaringConfig(state: StateType, payload: object) {
      state.errConfig = Object.assign(
        state.errConfig,
        {
          type: "error",
        },
        payload
      );
      timer && clearTimeout(timer);
    },
    setErrConfig(state: StateType, payload: object) {
      state.errConfig = Object.assign(
        state.errConfig,
        {
          type: "warning",
        },
        payload
      );
      timer && clearTimeout(timer);
      timer = setTimeout(() => {
        state.errConfig.visible = false;
      }, 3000);
    },
    setPreviewImageConfig(
      state: StateType,
      payload: {
        url: string;
        visible: boolean;
        width: string | number;
        isVideo: boolean | undefined;
      }
    ) {
      state.previewImageUrl = payload.url;
      state.previewImageVisible = payload.visible;
      state.previewImageW = payload.width;
      state.isVideo = !!payload.isVideo;
    },
  },
  actions: {},
});

export default store;
