<template>
  <div id="uploadFileUpgraded">
    <el-upload
      v-loading="loading"
      class="uploader_box"
      :show-file-list="false"
      v-bind="$attrs"
      :headers="headers"
      drag
      :on-success="(res) => handleUploadSuccess(res)"
      :on-error="(res) => handleUploadError(res)"
      :before-upload="beforeUpload"
      :action="action"
    >
      <div v-if="modelValue?.filename" class="to_upload">
        <i class="is_upload_icon"></i>
        <img
          v-if="isImg"
          alt="img"
          :src="modelValue.url"
          crossorigin="anonymous"
        />
        <template v-else>
          <i
            class="icon"
            :class="`${modelValue?.filename.split('.').slice(-1)[0]}`"
          ></i>
          <span>{{ modelValue?.filename }}</span>
        </template>
        <div class="cover_layer" @click.stop="">
          <i class="remove_icon" @click.stop="removeItem"></i>
          <div v-if="showPdf" class="eyeIcos" @click.stop="lookItem">
            <i></i>
          </div>
        </div>
      </div>
      <div v-else class="upload_file_new">
        <template v-if="isImg">
          <el-icon class="avatar-uploader-icon">
            <Plus />
            <div class="text">{{ infoTxt || $t("message.uploadFile") }}</div>
          </el-icon>
        </template>
        <template v-else>
          <el-icon class="avatar-uploader-icon">
            <Plus />
            <span class="text">{{ infoTxt || $t("message.uploadFile") }}</span>
          </el-icon>
        </template>
      </div>
      <div class="error_txt">{{ errMsg }}</div>
      <UploadLoading
        v-if="loading"
        class="uploading-file"
        :upload-percentage="0"
      ></UploadLoading>
    </el-upload>
  </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import { downloadFileSimple, getToken, uploadBigFile } from "../../utils/util";

export default defineComponent({
  name: "UploadFileUpgraded",
  props: {
    type: {
      type: String,
      default: "",
    },
    modelValue: {
      type: Object,
      default: () => ({}),
    },
    infoTxt: {
      type: String,
      default: "",
    },
    templateDownloadType: {
      type: [String, Object],
      default: "",
    },
    templateDownloadUrl: {
      type: String,
      default: "",
    },
    templateDownloadName: {
      type: String,
      default: "",
    },
    isImg: {
      type: Boolean,
      default: false,
    },
    action: {
      type: String,
      default: "",
    },
    showPdf: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["update:modelValue", "downloadFile", "uploading", "lookItem"],
  data() {
    return {
      loading: false,
      errMsg: "",
      headers: {
        Authorization: "Bearer " + getToken(),
      },
    };
  },
  methods: {
    downloadFileSimple,
    handleUploadSuccess(res: object) {
      this.loading = false;
      this.$emit("update:modelValue", res?.results);
      this.errMsg = "";
    },
    handleUploadError(res: object) {
      this.errMsg = "上传失败!";
      this.loading = false;
    },
    beforeUpload(rawFile: any) {
      if (Array.isArray(rawFile)) {
        this.$store.commit("setErrConfig", {
          visible: true,
          message: "只支持单个文件",
        });
        return;
      }
      const { accept } = this.$attrs;
      let maxSize = 0;
      if (accept == ".jpg,.jpeg,.png,.gif") {
        maxSize = this.$attrs["max-size"] || 20 * 1024 * 1024;
      } else {
        maxSize = this.$attrs["max-size"] || 200 * 1024 * 1024;
      }

      if (
        !accept
          .split(",")
          .map((v) => v.trim())
          .includes(`.${rawFile.name.split(".").slice(-1)[0]}`)
      ) {
        this.errMsg = "格式不支持";
        return false;
      } else if (rawFile.size > maxSize) {
        this.errMsg = "大小超出限制";
        return false;
      }
      this.errMsg = "";
      this.loading = true;

      if (this.type === "bigFile") {
        uploadBigFile(rawFile, (message: string, data: string) => {
          this.uploadCall(message, data);
        });
      } else {
        this.$emit("uploading");
      }
      return true;
    },
    removeItem() {
      // 移除文件
      this.$emit("update:modelValue", "");
    },
    lookItem() {
      // 查看pdf
      this.$emit("lookItem", "update:modelValue");
    },
    uploadCall(message: string, data: string) {
      if (message === "uploading") {
        this.progressType = true;
        this.uploadPercentage = data;
      } else if (data?.fileName != null || data?.fileName != "") {
        this.loading = false;
        this.progressType = false;
        this.uploadPercentage = 100;
        this.errMsg = "";
        this.uploadName = data;
        this.$emit("update:modelValue", data);
      }
      if (data && data.status === 500) {
        this.uploadPercentage = 0;
        this.errMsg = "上传失败";
        this.loading = false;
        this.progressType = false;
      }
    },
  },
});
</script>
<style lang="scss" scoped>
.uploading-file ::v-deep(.el-loading-mask .uploadPercentage) {
  top: 25px !important;
}
</style>
<style lang="scss">
#uploadFileUpgraded {
  display: inline-block;
  line-height: 0;

  .uploader_box {
    display: inline-block;
    box-sizing: border-box;

    .upload_file_new {
      width: 144px;
      height: 144px;
      display: inline-block;
      border-radius: 4px;
      background-color: #f6f8fa;
      border: 1px dashed #dcdee6;
      text-align: center;
      cursor: pointer;
      box-sizing: border-box;

      > i.icon {
        width: 50px;
        height: 50px;
        display: inline-block;
        margin-top: 30px !important;
        // background-image: url(@/assets/icons/upload_file_icon.png);
        background-repeat: no-repeat;
        background-size: cover;
      }

      > span {
        width: calc(100% - 24px);
        line-height: 20px;
        font-size: 14px;
        text-align: center;
        color: #666666;
        display: inline-block;
        margin: 14px 12px 0 12px;
      }

      .el-icon.avatar-uploader-icon {
        width: 100%;
        height: 100%;
        background: #f6f8fa;
        text-align: center;
        font-size: 34px;
        color: #666666;
        line-height: 17px;
        flex-direction: column;
        border-radius: 8px;
      }

      .el-icon.avatar-uploader-icon .text {
        font-size: 14px;
        margin-top: 6px;
        font-style: normal;
      }
    }

    .upload_file_new:hover {
      border: 1px dashed #2468f1;
    }

    .to_upload {
      width: 144px;
      height: 144px;
      display: inline-block;
      border-radius: 4px;
      background-color: #f6f8fa;
      border: 1px dashed #dcdee6;
      text-align: center;
      cursor: pointer;
      box-sizing: border-box;
      position: relative;

      > i.icon {
        width: 50px;
        height: 50px;
        display: inline-block;
        margin-top: 30px !important;
        // background-image: url(@/assets/icons/upload_file_icon.png);
        background-repeat: no-repeat;
        background-size: cover;
      }

      > img {
        width: 100%;
        height: 100%;
        display: inline-block;
        margin-top: 0 !important;
      }

      > i.icon.doc,
      // > i.icon.docx {
      //   background-image: url(@/assets/icons/word_icon.png);
      // }

      // > i.icon.pdf {
      //   background-image: url(@/assets/icons/pdf_icon.png);
      // }
      // > i.icon.PDF {
      //   background-image: url(@/assets/icons/pdf_icon.png);
      // }

      // > i.icon.ppt,
      // > i.icon.pptx {
      //   background-image: url(@/assets/icons/ppt_icon.png);
      // }

      // > i.icon.text {
      //   background-image: url(@/assets/icons/text_icon.png);
      // }

      // .is_upload_icon {
      //   width: 16px;
      //   height: 16px;
      //   display: inline-block;
      //   position: absolute;
      //   z-index: 1;
      //   right: 8px;
      //   top: 8px;
      //   background-image: url(@/assets/icons/is_upload_icon.png);
      //   background-repeat: no-repeat;
      //   background-size: cover;
      // }

      > span {
        width: calc(100% - 24px);
        line-height: 20px;
        font-size: 14px;
        text-align: center;
        color: #666666;
        display: inline-block;
        margin: 14px 12px 0 12px;
        max-height: 40px;
        overflow: hidden;
        -webkit-line-clamp: 2;
        word-break: break-all;
        text-overflow: ellipsis;
      }

      .cover_layer {
        position: absolute;
        right: 0;
        bottom: 0;
        left: 0;
        top: auto;
      }
    }

    .to_upload:hover {
      .cover_layer {
        position: absolute;
        z-index: 2;
        top: 0;
        background-color: rgba(0, 0, 0, 0.5);

        .remove_icon {
          width: 16px;
          height: 16px;
          display: inline-block;
          position: absolute;
          right: 8px;
          top: 8px;
          background-image: url(@/assets/icons/remove_icon.png);
          background-repeat: no-repeat;
          background-size: cover;
        }
      }
    }

    .error_txt {
      display: inline-block;
      position: absolute;
      left: 12px;
      right: 12px;
      bottom: 12px;
      color: #db3940;
      line-height: 18px;
      text-align: center;
      font-size: 12px;
    }
  }

  .el-upload {
    display: inline-block;
    border: 0 !important;

    .el-upload-dragger {
      display: inline-block;
      border: 0 !important;
    }
  }
}

.eyeModule {
  position: relative;
}

.eyeIcos {
  position: absolute;
  width: 140px;
  height: 30px;
  left: 0px;
  top: 50%;
  margin-top: -15px;
  display: none;
  z-index: 9999;
}

.eyeIcos i {
  display: block;
  width: 16px;
  height: 16px;
  background-image: url(@/assets/images/eyeIco.png);
  background-size: 100% 100%;
  margin: 0 auto;
  cursor: pointer;
}
</style>
