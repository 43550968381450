import { eu } from "element-plus/es/locale";

export default {
  placeholder: {
    pleaseInput: "Please input",
    searchKeywords: "Search keywords",
    startDate: "Start Date",
    endDate: "End Date",
    pleaseSelect: "Please select",
    successTip: "Submit Successfully!",
    pleaseUpload: "Please upload",
    pleaseInputEmail: "Please enter the username",
    fillUsername: "Please fill in your username",
    userNameError: "User name or password error",
    noInfo: "No relevant information available at the moment",
  },
  message: {
    Hello: "Hello",
    login: "Login",
    signUpFree: "Sign up free",
    welcomeBack: "Welcome back",
    detailTip: "Please enter your details to sign in",
    userName: "User name",
    password: "Password",
    forgetPassword: "Forgot password?",
    haveAccount: "Don’t have an account yet?",
    signup: "Sign Up",
    selectType: "Select the type of account you want to create",
    demandSide: "Demand side",
    demandIntro: "Biopharmaceutical companies with overseas needs",
    supplier: "Supplier",
    supplierIntro:
      "Third party organizations that can provide overseas services",
    internationalOrg: "International/Foreign Local Organizations",
    internationalOrgIntro:
      "WHO and other international organizations and their branches, chambers of commerce/associations",
    next: "Next",
    alreadyAccount: "Already have an account?",
    fillBasic: "Fill in basic information",
    orgFullName: "Full name of organization",
    email: "Email",
    confirmPassword: "Confirm password again",
    emailCode: "Email verification code",
    sendCode: "Send verification code",
    agreeTip1: "Yes, I understand and agree to",
    termsService: `the platform's terms of service`,
    agreeTip2: ", including the",
    userAgreement: "user agreement",
    agreeTip3: "and",
    privacyPolicy: "privacy policy",
    createAccount: "Create my account",
    checkTypeTip: "Please select the type of account to create",
    usernameExist: "This username has been taken",
    emailCorrect: "Please enter a valid email address",
    emailExist: "This email address has been registered",
    pwdRule:
      "8-20 digits, letters or common symbols, letters are case sensitive",
    samePwd: "Please enter the same password",
    emailInput: "Please enter your email address",
    beenSent: "Has been sent",
    home: "Home",
    demandMarket: "Demand Market",
    serviceMarket: "Services Market",
    eventRelease: "Event Release",
    courseLearning: "Learning Courses",
    newsBroadCast: "News Broadcast",
    aboutUs: "About Us",
    improveSelf: "Continuously improve yourself",
    all: "All",
    popularCourses: "Popular courses",
    latestCourses: "Latest courses",
    releaseTime: "Release time",
    browse: "browse",
    to: "TO",
    generalServices: "General Services",
    consult: "Consulting service",
    law: "Law",
    business: "Business",
    finance: "Finance",
    financeTax: "Finance and tax",
    other: "Other",
    professionalServices: "Professional services",
    preCro: "Preclinical CRO",
    clinicalCro: "Clinical CRO",
    CDMO: "CDMO",
    registrationDeclare: "Registration declaration",
    coldChain: "Cold chain",
    storage: "Storage",
    maintenance: "Maintenance",
    targetArea: "Target Area",
    america: "America",
    europe: "Europe",
    austrailia: "Austrailia",
    japan: "Japan",
    southeastAsia: "Southeast Asia",
    middleEast: "Middle East",
    southAmerica: "South America",
    africa: "Africa",
    overseaStrategy: "Plan overseas strategy",
    indepandentOversea: "Independent overseas expansion",
    nonAutonomousOversea: "Non-autonomous overseas",
    jointOversea: "Joint Overseas Expedition",
    destination: "Destination",
    strategy: "Strategy",
    excitingMoments: "Exciting Moments",
    internationalAct: "International activities",
    domesticAct: "Domestic activities",
    activityTime: "Activity time",
    remainingStart: "Remaining until the start of the event days",
    eventEnd: "The event has ended",
    releaseAct: "Release Activity",
    themeName: "Event Theme Name",
    uploadEvent: "Upload Event cover image",
    uploadTip:
      "Format support JPG, JPEG, PNG, with a single image size not exceeding 10M",
    uploadPic: "Upload Pictures",
    actType: "Activity type",
    organizer: "Organizer",
    actTime: "Activity time",
    defaultsOn: "Defaults on",
    eventAddress: "Event address",
    activites: "Activities",
    release: "Release",
    notRelease: "Not Release",
    language: "Language",
    newsInfo: "News information",
    stayUpDate: "Stay up-to-date with the latest developments in the industry",
    viewDetail: "View details",
    visits: "Visits",
    contactUs: "Contact us",
    contactNumber: "Contact number",
    mailbox: "Mailbox",
    serviceAddress: "Service Address",
    serviceAddressContent: "No. 766 Jingang Road, Pudong New Area, Shanghai",
    technicalSupport: "Technical support",
    supportingUnits: "Supporting units",
    supportingUnitsConent: "Shanghai Industrial and Creative Center",
    companyAddress: "Company Address",
    companyAddressContent: "No. 766 Jingang Road, Pudong New Area, Shanghai",
    quickNavigation: "Quick Navigation",
    feedbackOpinion: "Feedback on opinions",
    advertiseContact: "Advertising Contact",
    bioCollege: "Bio+ college",
    municipalAdministration:
      "Shanghai Municipal Administration for Market Regulation",
    joinNow: "Join now",
    platformIntroduction: "Platform Introduction",
    officialAccount: "Official Account",
    advertisePlacement: "Advertising Placement",
    opinionsFeedback: "Opinions and Feedback",
    opinionIntro:
      "Welcome to leave your valuable feedback. We will work together to build a beautiful overseas service environment. In response to your feedback, we will provide periodic unified responses. Thank you for your strong support of our overseas service. We are committed to creating a beautiful and harmonious overseas service environment.",
    contactInfo: "Contact Information",
    name: "Name",
    telephone: "Telephone",
    theme: "Theme",
    feedbackContent: "Feedback Content",
    feedback: "Feedback",
    submit: "Submit",
    CN: "CN",
    EN: "EN",
    seeDetails: "See details",
    personalCenter: "Personal Center",
    essentialInfo: "Essential Information",
    realNameAuth: "Real Name Authentication",
    messageCenter: "Message Center",
    myData: "My Data",
    enterpriseRealName: "Enterprise real name authentication",
    institutionName: "Institution Name",
    socialCreditCode: "Unified Social Credit Code",
    legalName: "Name of legal representative",
    idNumber: "Corporate ID number",
    uploadLicense: "Upload business license",
    uploadID:
      "Upload the front and back photos of the legal representative's ID card",
    confirm: "Confirm",
    uploadFile: "Upload Files",
    registeredOn: "Registered on",
    productCurrentStatus: "The current status of the product",
    demandInfo: "Demand Side Information",
    serviceInfo: "Service Side Information",
    getContactInfo: "Get contact information",
    relatedInfo: "Related Information",
    expectedStrategy: "Expected overseas strategy",
    difficulties: "Difficulties and bottlenecks",
    hopeSupport: "The services and support we hope to receive",
    serviceDesc: "Service Description",
    hi: "Hi",
    demandDetail: "Demand Detail",
    eventDetail: "Event Detail",
    ended: "ENDED",
    detailIntro: "Detailed Introduction",
    popularRec: "Popular recommendations",
    newsDetail: "News Detail",
    logout: "Logout",
    eventManagement: "Event Management",
    topic: "Theme/Person of Message",
    resetPwd: "Reset your password",
    resetPwdTip:
      "To reset your password, enter the email address you use to log in.",
    newPwd: "New login password",
    bannerSubTitle:
      "This is a placeholder, and specific text will be added later is a placeholder, and specific text will be added later",
    viewNow: "View now",
    totalDemand: "Total demand",
    totalService: "Total of services",
    totalActivities: "Total of activities",
    totalSettleEnterprise: "Total of settled enterprises",
    lastRequirementBrief: "Latest Requirement Briefing",
    viewMoreRequirement: "View more requirement",
    popularService: "Popular Services",
    viewMoreServices: "View more services",
    latestActivities: "Latest Activities",
    viewMoreActivities: "View more activities",
    viewMore: "View more",
    latestRequirement: "Latest Requirement Briefing",
    popularCourse: "Popular Courses",
    newsAndInfo: "News and Information",
    projectCollect: "2024 Overseas Project Collection",
    projectBackground: "Project Background",
    overseaProject: "Oversea Collect Project Docs",
    uploadFillingDoc: "Upload Filling Docs",
    myDemand: "My Demand",
    myService: "My Service",
    createDemand: "Create Demand",
    createService: "Create Service",
    addDemand: "Create Demand",
    addService: "Create Service",
    productName: "Products/Services to be exported overseas",
    productCategory: "Product Type and Category",
    productStatus: "Current status of the product",
    contactPerson: "Contact Person",
    contactPhone: "Contact Phone",
    orgType: "Organization Type",
    usci: "Uniform Social Credit Code",
    contactAddress: "Address",
    overseaProdInfo: "Overseas Product Information",
    prodOverseaStatus: "Overseas Product Status",
    alreadyOverseaProd: "Overseas Product Existing",
    overseaProdCount: "Number of products exported",
    productType: "Types and categories of overseas products",
    currentOverseaStrategy: "Current overseas expansion strategies",
    currentOverseaCountry: "Countries/regions that have been exported to",
    currentStep: "The stage in the exporting country",
    ableTargetRegion: "Overseas service area",
    planOverseaRegion: "Planning of offshore service areas",
    serviceSupplyInfo: "Service supply information",
    editContact: "Edit contact information",
    goAuthentication: "Go to Authentication",
    editEnterprise: "Edit Organization Name and Code",
    alreadyOverseas: "Already Overseas",
    notOverseas: "Not Overseas",
    layoutOverseas: "Not Overseas, But Ready For it",
    sales: "On Sales",
    approving: "Approving",
    yes: "Yes",
    no: "No",
    userDoc: "User Uploaded Docs",
    edit: "Edit",
    serviceDetail: "Service Detail",
    uploadProductImg: "Upload Product Image",
    delete: "Delete",
    deleteTip: "Delete Tip",
    deleteMsg:
      "Please confirm whether to delete? After deletion, the information will no longer be displayed on the platform.",
    cancel: "Cancel",
    aboutus1: ` As Payload grew, their customers wanted to find an easier way to
            deploy the CMS, which is built on a Node.js framework. That’s when
            Payload’s founders decided to explore different platforms that would
            give them and their users a simple way to deploy in one click, and
            eventually found DigitalOcean’s App Platform.`,
    aboutus2: `As Elliot shares “When we tried DigitalOcean’s App platform, we
            could use a Dockerfile and just push it through the API to deploy.
            We realized we could cut out half of the code that we’d written. We
            were also pleased with some of the other functionality App Platform
            offered, like the web hooks.”`,
    aboutus3: `Payload enjoys using the robust APIs offered through App Platform,
            finding that they can do most of their tasks programmatically. They
            also use the web hooks for alerting and updates. For a growing
            business, App Platform’s combination of simplicity and functionality
            has worked well for Payload, with Elliot saying “App Platform hit
            the sweet spot in terms of being able to customize things, but also
            not having to build out our own runner or deployers,” which has
            saved their team valuable time.As Elliot shares “When we tried
            DigitalOcean’s App platform, we could use a Dockerfile and just push
            it through the API to deploy. We realized we could cut out half of
            the code that we’d written. We were also pleased with some of the
            other functionality App Platform offered, like the web hooks.`,
    isread: "Read",
    unread: "Unread",
    opnionSuggestion: "Opinions and suggestions",
    complain: "Complain",
    messageUser: "Message/Message person",
    message: "Message",
  },
};
