<template>
  <div class="uploadContainer">
    <el-upload
      ref="uploadRef"
      v-loading="loading"
      accept=".pdf"
      class="avatar-uploader"
      :on-change="handleChange"
      :auto-upload="false"
      :show-file-list="false"
    >
      <div v-if="modelValue?.fileName" class="avatar">
        <img
          v-if="isImg"
          :src="modelValue.url"
          crossorigin="anonymous"
          alt=""
        />
        <img v-else src="@/assets/images/uploadFile.png" alt="" />
        <div class="fileName">{{ modelValue.fileName }}</div>
      </div>

      <el-icon v-else class="avatar-uploader-icon">
        <Plus />
        <div class="text">上传文件</div>
      </el-icon>
      <div v-if="modelValue?.fileName" class="hoverPop">
        <img
          src="@/assets/images/closeCircle.png"
          alt=""
          @click.stop.prevent="$emit('update:modelValue', null)"
        />
      </div>
    </el-upload>
    <el-progress
      v-if="progressType"
      class="progress"
      :percentage="uploadPercentage"
    />
    <div v-if="infoTxt" class="infos">
      <div>
        {{ infoTxt }}<br />
        <span
          v-if="templateDownloadUrl"
          class="templateDownload"
          @click="downloadFileSimple(templateDownloadUrl, templateDownloadName)"
          ><img src="@/assets/images/download.png" alt="" /> 模板下载</span
        >
        <span
          v-if="templateDownloadType"
          class="templateDownload"
          @click="$emit('downloadFile', templateDownloadType)"
          ><img src="@/assets/images/download.png" alt="" />
          {{ templateDownloadInfo }}</span
        >
      </div>
      <div class="errorTxt">{{ errMsg }}</div>
    </div>
  </div>
</template>
<script lang="ts">
import { UploadFile } from "element-plus";
import { defineComponent } from "vue";
import { downloadFileSimple, getToken, uploadBigFile } from "../../utils/util";

export default defineComponent({
  name: "UploadFile",
  props: {
    modelValue: {
      type: Object,
      default: () => ({}),
    },
    infoTxt: {
      type: String,
      default: "",
    },
    templateDownloadType: {
      type: [String, Object],
      default: "",
    },
    templateDownloadUrl: {
      type: String,
      default: "",
    },
    templateDownloadName: {
      type: String,
      default: "",
    },
    templateDownloadInfo: {
      type: String,
      default: "模板下载",
    },
  },
  emits: ["update:modelValue", "downloadFile", "uploading"],
  data() {
    return {
      uploadPercentage: 0,
      loading: false,
      progressType: false,
      errMsg: "",
      headers: {
        Authorization: getToken(),
      },
    };
  },
  computed: {
    isImg() {
      return ["pdf", "jpg", "jpeg", "png"].includes(
        (this.modelValue.url ?? "").split("?")[0].split(".").slice(-1)[0]
      );
    },
  },
  methods: {
    downloadFileSimple,
    handleChange(uploadFileObj: UploadFile) {
      this.loading = true;
      const file = uploadFileObj.raw;
      if (!file) return;
      this.uploading = true;
      this.uploadPercentage = 0;
      this.uploadName = "";
      this.uploadPath = "";
      this.uploadType = true;
      uploadBigFile(file, this.uploadCall);
    },
    uploadCall(message: string, data: string) {
      if (message === "uploading") {
        this.progressType = true;
        this.uploadPercentage = data;
      } else if (data?.fileName != null || data?.fileName != "") {
        this.loading = false;
        this.progressType = false;
        this.uploadPercentage = 100;
        this.errMsg = "";
        this.uploadName = data;
        this.$emit("update:modelValue", data);
      }
      if (data && data.status === 500) {
        this.uploadPercentage = 0;
        this.errMsg = "上传失败!";
        this.loading = false;
        this.progressType = false;
      }
    },
  },
});
</script>
<style scoped lang="scss">
.uploadContainer {
  display: flex;
  .avatar-uploader {
    cursor: pointer;
    .avatar {
      width: 100px;
      height: 100px;
      background: #f6f8fa;
      border-radius: 4px;
      text-align: center;
      color: #666666;
      line-height: 17px;
      font-size: 12px;
      padding: 18px 8px 0;
      box-sizing: border-box;
      overflow: visible;
      .fileName {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 100%;
      }
      img {
        width: 40px;
        height: 40px;
        margin-bottom: 8px;
      }
    }
    .hoverPop {
      display: none;
      opacity: 0;
      top: -1px;
      bottom: -1px;
      left: -1px;
      right: -1px;
      position: absolute;
      background: #000000;
      border-radius: 4px;
      transition: opacity 200ms ease-in;
    }
    &:hover {
      position: relative;
      .hoverPop {
        display: inherit;
        opacity: 0.5;
        img {
          position: absolute;
          top: 8px;
          right: 8px;
          width: 16px;
          height: 16px;
        }
      }
    }
  }
  .infos {
    margin-left: 10px;
    width: 300px;
    font-size: 12px;
    color: #999999;
    line-height: 17px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .errorTxt {
      color: #db3940;
      background: #fff;
      z-index: 88;
      margin-left: -2px;
    }
  }
  .templateDownload {
    display: inline-block;
    margin-top: 10px;
  }
}
</style>

<style>
.el-upload-dragger {
  padding: 0px !important;
  /* border: 1px solid #EAEAEE !important; */
}
.avatar-uploader .el-upload {
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  /* border: 1px dashed #dcdee6; */
}

.el-icon.avatar-uploader-icon {
  width: 100px;
  height: 100px;
  background: #f6f8fa;
  border-radius: 4px;
  text-align: center;
  font-size: 18px;
  color: #666666;
  line-height: 17px;
  flex-direction: column;
}
.el-icon.avatar-uploader-icon .text {
  font-size: 12px;
  margin-top: 8px;
  font-style: normal;
}
.progress {
  position: absolute !important;
  width: 30px !important;
  height: 30px !important;
  left: 20px;
  text-align: center;
  top: 34px;
  z-index: 9999;
}
</style>
