import i18n from "./../i18n/index";
const menusRoutes = [
  // {
  //   path: "/admin/home",
  //   name: "admin-home",
  //   meta: {
  //     title: "首页",
  //     code: "*",
  //     breadcrumb: [{ label: "管理端管理" }],
  //     icon: "home",
  //     permissions: [],
  //     defaultMenu: "admin-home",
  //   },
  // },
  {
    path: "/admin/news-manage",
    name: "news-manage",
    component: () => import("../views/admin/NewsManage.vue"),
    meta: {
      title: "新闻管理",
      code: "*",
      breadcrumb: [{ label: "新闻管理" }],
      icon: "newsManage",
      permissions: [],
      defaultMenu: "news-manage",
    },
  },
  {
    path: "/admin/course-manage",
    name: "course-manage",
    component: () => import("../views/admin/CourseManage.vue"),
    meta: {
      title: "课程管理",
      code: "*",
      breadcrumb: [{ label: "课程管理" }],
      icon: "courseManage",
      permissions: [],
      defaultMenu: "course-manage",
    },
  },
  {
    path: "/admin/event-manage",
    name: "event-manage",
    component: () => import("../views/admin/EventManage.vue"),
    meta: {
      title: "活动管理",
      code: "*",
      breadcrumb: [{ label: "试点企业管理" }],
      icon: "eventManage",
      permissions: [],
      defaultMenu: "event-manage",
    },
  },
  {
    path: "/admin/message-center",
    name: "message-center",
    component: () => import("../views/admin/MessageCenter.vue"),
    meta: {
      title: "留言中心",
      code: "*",
      breadcrumb: [{ label: "试点企业管理" }],
      icon: "message",
      permissions: [],
      defaultMenu: "message-center",
    },
  },
  {
    path: "/admin/user-management",
    name: "user-management",
    component: () => import("../views/admin/UserManagement.vue"),
    meta: {
      title: "用户管理",
      code: "*",
      breadcrumb: [{ label: "试点企业管理" }],
      icon: "user",
      permissions: [],
      defaultMenu: "user-management",
    },
    children: [
      {
        path: "/admin/user-management?type=demand",
        name: "user-management-demand",
        component: () => import("../views/admin/UserManagement.vue"),
        meta: {
          title: "需求方",
          code: "*",
          breadcrumb: [{ label: "试点企业管理" }],
          permissions: [],
          defaultMenu: "user-management-demand",
        },
      },
      {
        path: "/admin/user-management?type=supply",
        name: "user-management-supply",
        component: () => import("../views/admin/UserManagement.vue"),
        meta: {
          title: "供应方",
          code: "*",
          breadcrumb: [{ label: "试点企业管理" }],
          permissions: [],
          defaultMenu: "user-management-supply",
        },
      },
      {
        path: "/admin/user-management?type=international",
        name: "user-management-org",
        component: () => import("../views/admin/UserManagement.vue"),
        meta: {
          title: "国际/国外组织",
          code: "*",
          breadcrumb: [{ label: "试点企业管理" }],
          permissions: [],
          defaultMenu: "user-management-org",
        },
      },
      // {
      //   path: "/admin/user-management?type=admin",
      //   name: "user-management-admin",
      //   component: () => import("../views/admin/UserManagement.vue"),
      //   meta: {
      //     title: "后台用户",
      //     code: "*",
      //     breadcrumb: [{ label: "试点企业管理" }],
      //     permissions: [],
      //     defaultMenu: "user-management-admin",
      //   },
      // },
    ],
  },
];

export default menusRoutes;
