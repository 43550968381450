<template>
  <ul class="imitate_table" :class="{ no_data: !list.length }">
    <template v-if="list.length">
      <li v-for="(item, index) in list">
        <i class="no">{{ index + 1 }}</i>
        <i class="header_icon" :class="item.sex"></i>
        <div v-if="!show.includes('name_box')" class="name_sex_box">
          <span class="name">{{ item.name || "-" }}</span>
          <span v-if="item.sex !== null" class="sex" :class="item.sex">{{
            item.sexStr
          }}</span>
        </div>
        <div v-else class="name_box">
          <span class="name">{{ item.name || "-" }}</span>
        </div>
        <div v-if="show.includes('tag_box')" class="tag_box">
          <span
            class="tag"
            :class="item.diagnoseExportType === 'TEAM_LEADER' ? 'lv_1' : 'lv_2'"
            >{{
              item.diagnoseExportType === "TEAM_LEADER" ? "组长" : "组员"
            }}</span
          >
        </div>
        <span v-if="!hide.includes('phone')" class="phone">{{
          item.phone
        }}</span>
        <span v-if="!hide.includes('base')" class="base">{{
          item.certificateName
        }}</span>
        <span v-if="show.includes('department')" class="department">{{
          item.department
        }}</span>
        <span v-if="show.includes('unitPost')" class="unitPost">{{
          item.unitPost
        }}</span>
        <span v-if="show.includes('qualifications')" class="qualifications">{{
          item.qualifications
        }}</span>
        <div v-if="show.includes('options')" class="options">
          <i class="icon edit_icon" @click="$emit('handleTagValue', item)"></i>
          <i class="icon delete_icon" @click="$emit('deleteItem', item)"></i>
        </div>
        <div v-if="show.includes('options_del')" class="options">
          <i class="icon delete_icon" @click="$emit('deleteItem', item)"></i>
        </div>
        <div v-if="show.includes('checkbox')" class="checkbox_item">
          <el-checkbox
            v-model="item.checked"
            :disabled="item.isDisabled"
            label=""
            @change="$emit('addItem', item)"
          />
        </div>
      </li>
    </template>
    <template v-else>
      <li>
        <i class="icon"></i> <span>暂无相关信息</span>
        <el-button
          v-if="show.includes('no_data_btn')"
          class="no_data_btn"
          type="primary"
          @click="$emit('handleAddData')"
          >{{ noDataBtnName }}</el-button
        >
      </li>
    </template>
  </ul>
</template>
<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "TableUpgraded",
  props: {
    list: {
      type: Array,
      default: () => {
        return [];
      },
    },
    hide: {
      type: Array,
      default: () => {
        return [];
      },
    },
    show: {
      type: Array,
      default: () => {
        return [];
      },
    },
    noDataBtnName: {
      typE: String,
      default: () => {
        return "添加数据";
      },
    },
  },
  emits: ["addItem", "handleTagValue", "deleteItem", "handleAddData"],
});
</script>

<style scoped lang="scss">
.imitate_table {
  width: 100%;
  height: auto;
  border: 1px solid #eef0f7;
  background-color: #ffffff;
  animation: show 0.3s ease-in-out;

  li {
    width: 100%;
    border-bottom: 1px solid #eef0f7;
    border-top: 1px solid transparent;
    height: 76px;
    line-height: 74px;
    box-sizing: border-box;
    text-align: left;
    display: flex;
    flex-direction: row;
    align-items: center;

    .no {
      margin: 0 30px;
      font-style: normal;
      font-size: 16px;
      font-weight: bold;
      border-radius: 6px;
      text-align: center;
      vertical-align: middle;
      width: 28px;
      height: 28px;
      line-height: 28px;
      display: inline-block;
      background-color: #fef9ec;
      color: #e9ac51;
    }

    .header_icon {
      display: inline-block;
      width: 44px;
      height: 44px;
      background-repeat: no-repeat;
      background-size: cover;
      vertical-align: middle;
    }

    // .header_icon.MALE {
    //   background-image: url(@/assets/images/member_avatar_male.png);
    // }

    // .header_icon.FEMALE {
    //   background-image: url(@/assets/images/member_avatar_female.png);
    // }

    .header_icon.neuter {
      background-image: url(@/assets/images/defaultImg.png);
    }

    .name_sex_box {
      margin-left: 20px;
      display: inline-block;
      text-align: left;
      line-height: 1;
      vertical-align: middle;
      flex: 1;

      .name {
        display: inline-block;
        line-height: 20px;
        font-size: 14px;
        color: #333333;
        font-weight: bold;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        width: 200px;
      }

      .sex {
        display: inline-block;
        line-height: 20px;
        font-size: 14px;
        padding-left: 14px;
        box-sizing: border-box;
        color: #999999;
        width: 200px;
        margin-top: 2px;
        position: relative;
      }

      .sex.MALE:before {
        content: "";
        position: absolute;
        // background-image: url(@/assets/images/male.png);
        width: 10px;
        height: 10px;
        left: 0;
        top: 5px;
        background-repeat: no-repeat;
        background-size: cover;
      }

      .sex.FEMALE:before {
        content: "";
        position: absolute;
        // background-image: url(@/assets/images/female.png);
        width: 10px;
        height: 10px;
        left: 0;
        top: 5px;
        background-repeat: no-repeat;
        background-size: cover;
      }
    }

    .name_box {
      margin-left: 20px;
      display: inline-block;
      text-align: left;
      line-height: 1;
      vertical-align: middle;

      .name {
        display: inline-block;
        line-height: 20px;
        font-size: 14px;
        color: #333333;
        font-weight: bold;
      }
    }

    .tag_box {
      display: inline-block;
      text-align: center;
      line-height: 1;
      vertical-align: middle;
      flex: 1;

      .tag {
        display: inline-block;
        line-height: 20px;
        padding: 6px 12px;
        border-radius: 6px;
        font-size: 14px;
      }

      .tag.lv_1 {
        background-color: #fdf7e5;
        color: #d79200;
      }

      .tag.lv_2 {
        background-color: #f5fbfa;
        color: #2dad95;
      }
    }

    .phone,
    .base,
    .department,
    .unitPost,
    .qualifications {
      display: inline-block;
      line-height: 20px;
      font-size: 14px;
      color: #595959;
      flex: 1;
      text-align: center;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    .options {
      display: inline-block;
      text-align: center;
      line-height: 1;
      vertical-align: middle;
      padding: 0 30px;

      > i {
        display: inline-block;
        width: 17px;
        height: 17px;
        margin: 0 6px;
        cursor: pointer;
      }

      // .edit_icon {
      //   background-image: url(@/assets/icons/edit_icon.svg);
      // }

      // .edit_icon:hover {
      //   background-image: url(@/assets/icons/edit_on_icon.svg);
      // }

      // .delete_icon {
      //   background-image: url(@/assets/icons/delete_icon.svg);
      // }

      // .delete_icon:hover {
      //   background-image: url(@/assets/icons/delete_on_icon.svg);
      // }
    }

    .checkbox_item {
      display: inline-block;
      text-align: center;
      line-height: 1;
      vertical-align: middle;
      padding: 0 30px;

      ::v-deep(.el-checkbox .el-checkbox__label) {
        padding-left: 0;
      }

      ::v-deep(.el-checkbox .el-checkbox__input .el-checkbox__inner) {
        width: 18px;
        height: 18px;
        border-radius: 4px;
        border: 1px solid #dcdee6;
        box-sizing: border-box;
      }

      ::v-deep(.el-checkbox .el-checkbox__input .el-checkbox__inner:after) {
        content: "";
        width: 10px;
        height: 10px;
        top: 3px;
        left: 3px;
        display: inline-block;
        border: 0 !important;
        transform: rotate(0deg) scaleY(1);
        background-image: url(@/assets/icons/checkbox_gou_icon.png);
        background-size: cover;
        background-repeat: no-repeat;
      }
    }
  }

  li:last-child {
    border-bottom: 1px solid transparent;
  }

  li:nth-child(2n) {
    .no {
      background-color: #f6f8fc;
      color: #6182f7;
    }
  }

  li:hover {
    background-color: #fafbfd;
  }
}

.imitate_table.no_data {
  border: 0;

  li {
    border: 0;
    background-color: transparent;
    text-align: center;
    display: inline-block;
    position: relative;
    padding: 60px 0;
    height: auto;
    line-height: 1;
    animation: show 0.3s ease-in-out;

    > i.icon {
      width: 180px;
      height: 180px;
      display: inline-block;
      // background-image: url(@/assets/icons/emptyTable.png);
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center center;
    }

    > span {
      display: inline-block;
      font-size: 16px;
      line-height: 22px;
      color: #999999;
      width: 100%;
    }
  }

  .no_data_btn {
    border-radius: 4px;
    padding: 8px 25px;
    line-height: 1;
    height: auto;
    margin-top: 20px;
    text-align: center;
  }
}

@keyframes show {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
</style>
