<template>
  <div class="tabsCom">
    <div
      v-for="(item, index) in tabsData"
      :key="index"
      class="content_box"
      :class="(item.id ?? index) == modelValue ? 'currentIndex' : ''"
      :style="{ cursor: item.disabled ? 'not-allowed' : 'pointer' }"
      @click="!item.disabled && tabsItem(item.id ?? index)"
    >
      <span
        >{{ item.txtMes
        }}<i
          v-if="item.isClear"
          class="close_icon"
          @click="$emit('clearTabs', item.id)"
        ></i
      ></span>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent, PropType } from "vue";

type StepsConfigArr = {
  txtMes: string;
  disabled: boolean;
  id?: number;
};

export default defineComponent({
  name: "TabsComUpgraded",
  props: {
    tabsType: {
      type: String,
      default: "",
    },
    tabsData: {
      type: Array as PropType<StepsConfigArr[]>,
      default: () => [],
    },
    modelValue: {
      type: Number,
      default: 0,
    },
  },
  emits: ["click", "update:modelValue", "clearTabs"],
  data() {
    return {};
  },
  methods: {
    tabsItem(index: number) {
      this.$emit("update:modelValue", index);
      this.$emit("click", index);
    },
  },
});
</script>
<style scoped>
.tabsCom {
  width: calc(100% - 48px);
  overflow: hidden;
  position: relative;
  height: 37px;
  background-color: #ffffff;
  padding: 24px 24px 0 24px;
}

.tabsCom .content_box {
  display: inline-block;
  margin-right: 6px;
  text-align: center;
  cursor: pointer;
  position: relative;
  border-radius: 6px 6px 0 0;
  background: #eef2f5 !important;
  overflow: hidden;
}

.tabsCom .content_box.currentIndex::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: inline-block;
  background-color: #2468f1;
  z-index: 1;
  border-radius: 6px 6px 0 0;
}

.tabsCom .content_box.currentIndex::after {
  content: "";
  position: absolute;
  top: 2px;
  left: 0;
  right: 0;
  bottom: 0;
  display: inline-block;
  background-color: #ffffff;
  z-index: 2;
  border-radius: 6px 6px 0 0;
  border-left: 1px solid #eef0f7;
  border-right: 1px solid #eef0f7;
  border-bottom: 0;
}

.tabsCom .content_box > span {
  font-size: 14px;
  font-weight: 500;
  color: #4e4e4e !important;
  padding: 8px 16px;
  display: inline-block;
  line-height: 20px;
  position: relative;
  z-index: 3;
  border-radius: 6px 6px 0 0;
  border-top: 1px solid #eef0f7;
  border-left: 1px solid #eef0f7;
  border-right: 1px solid #eef0f7;
  border-bottom: 0;
}

.tabsCom .content_box > span > .close_icon {
  display: inline-block;
  width: 10px;
  height: 20px;
  margin-left: 6px;
  background-image: url(@/assets/icons/close_on_icon.png);
  vertical-align: top;
  background-size: 10px 10px;
  background-position: center center;
  background-repeat: no-repeat;
  cursor: pointer;
}

.tabsCom .content_box.currentIndex > span {
  color: #2468f1 !important;
  font-weight: bold;
  border-top: 1px solid transparent;
  border-left: 1px solid transparent;
  border-right: 1px solid transparent;
  border-bottom: 0;
}

.tabsCom .content_box:hover {
  color: #2468f1 !important;
  cursor: pointer;
}
</style>
