// import {RouteRecordRaw} from "vue-router";
import { ADMIN, ORGANIZATION } from "./permissionConstants";
import clientMenus from "./clientMenus";
import i18n from "./../i18n/index";
const t = i18n.global.t;

const routes: any[] = [
  ...clientMenus,
  {
    path: `news/:id`,
    name: "news-detail",
    component: () => import("@/views/user/NewsDetail.vue"),
    meta: {
      title: "新闻详情",
      code: "*",
      breadcrumb: [{ label: "需求管理" }, { label: "发布需求", path: "" }],
      permissions: [[ORGANIZATION]],
      defaultMenu: "enterprise_demand_management",
    },
  },
  {
    path: `event/:id`,
    name: "event-detail",
    component: () => import("@/views/user/EventDetail.vue"),
    meta: {
      title: "活动详情",
      code: "*",
      breadcrumb: [{ label: "需求管理" }, { label: "发布需求", path: "" }],
      permissions: [[ORGANIZATION]],
      defaultMenu: "enterprise_demand_management",
    },
  },
  {
    path: `demand/:id`,
    name: "demand-detail",
    component: () => import("@/views/user/DemandDetail.vue"),
    meta: {
      title: "需求详情",
      code: "*",
      breadcrumb: [
        { label: t("message.home"), path: "/" },
        { label: t("message.demandMarket"), path: "/demand" },
        { label: t("message.demandDetail") },
      ],
      permissions: [[ORGANIZATION]],
      defaultMenu: "enterprise_demand_management",
    },
  },
  {
    path: `service/:id`,
    name: "service-detail",
    component: () => import("@/views/user/ServiceDetail.vue"),
    meta: {
      title: "服务详情",
      code: "*",
      breadcrumb: [
        { label: t("message.home"), path: "/" },
        { label: t("message.demandMarket"), path: "/demand" },
        { label: t("message.demandDetail") },
      ],
      permissions: [[ORGANIZATION]],
      defaultMenu: "enterprise_service_management",
    },
  },
  {
    path: `/demand/create`,
    name: "demand-create",
    component: () => import("@/views/user/DemandCreate.vue"),
    meta: {
      title: "发布需求",
      code: "*",
      breadcrumb: [{ label: "需求管理" }, { label: "发布需求", path: "" }],
      permissions: [[ORGANIZATION]],
      defaultMenu: "enterprise_demand_management",
    },
  },
  {
    path: `/demand/edit/:id`,
    name: "demand-edit",
    component: () => import("@/views/user/DemandCreate.vue"),
    meta: {
      title: "编辑需求",
      code: "*",
      breadcrumb: [{ label: "需求管理" }, { label: "发布需求", path: "" }],
      permissions: [[ORGANIZATION]],
      defaultMenu: "enterprise_demand_management",
    },
  },
  {
    path: `/service/create`,
    name: "service-create",
    component: () => import("@/views/user/ServiceCreate.vue"),
    meta: {
      title: "发布服务",
      code: "*",
      breadcrumb: [{ label: "服务管理" }, { label: "发布服务", path: "" }],
      permissions: [[ORGANIZATION]],
      defaultMenu: "enterprise_demand_management",
    },
  },
  {
    path: `/service/edit/:id`,
    name: "service-edit",
    component: () => import("@/views/user/ServiceCreate.vue"),
    meta: {
      title: "编辑服务",
      code: "*",
      breadcrumb: [{ label: "服务管理" }, { label: "发布服务", path: "" }],
      permissions: [[ORGANIZATION]],
      defaultMenu: "enterprise_demand_management",
    },
  },
  {
    path: `/overseas-info/edit`,
    name: "overseas-info-edit",
    component: () => import("@/views/user/OverseaInfoEdit.vue"),
    meta: {
      title: "出海信息编辑",
      code: "*",
      breadcrumb: [{ label: "服务管理" }, { label: "发布服务", path: "" }],
      permissions: [[ORGANIZATION]],
      defaultMenu: "enterprise_demand_management",
    },
  },
  {
    path: `/supply-info/edit`,
    name: "supply-info-edit",
    component: () => import("@/views/user/SupplyInfoEdit.vue"),
    meta: {
      title: "服务提供信息编辑",
      code: "*",
      breadcrumb: [{ label: "服务管理" }, { label: "发布服务", path: "" }],
      permissions: [[ORGANIZATION]],
      defaultMenu: "enterprise_demand_management",
    },
  },
  {
    path: `personal-center`,
    name: "personalCenter",
    component: () => import("@/views/user/PersonalCenter.vue"),
    meta: {
      title: "用户中心",
      code: "*",
      permissions: [[ORGANIZATION]],
      defaultMenu: "enterprise_demand_management",
    },
  },
  {
    path: `about-us/feedback`,
    name: "feedback",
    component: () => import("@/views/user/Feedback.vue"),
    meta: {
      title: "意见反馈",
      code: "*",
      permissions: [[ORGANIZATION]],
      breadcrumb: [{ label: "需求管理" }, { label: "发布需求", path: "" }],
      defaultMenu: "enterprise_demand_management",
    },
  },
  {
    path: `about-us`,
    name: "about-us",
    component: () => import("@/views/user/AboutUs.vue"),
    meta: {
      title: "关于我们",
      code: "*",
      permissions: [[ORGANIZATION]],
      defaultMenu: "enterprise_demand_management",
    },
  },
];
export default routes;
