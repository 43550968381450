import BasePage from "./BasePage.vue";
import NormalTable from "./NormalTable.vue";
import TopSearchForm from "./TopSearchForm.vue";
import DialogBox from "./DialogBox.vue";
import DialogLastBox from "./DialogLastBox.vue";
import EmptyCom from "./EmptyCom.vue";
import SvgIcon from "./SvgIcon.vue";
import StepsCom from "./StepsCom.vue";
import MessageCom from "./MessageCom.vue";
import TabsCom from "./TabsCom.vue";
import DrawerCom from "./DrawerCom.vue";
import BaseECharts from "./BaseECharts.vue";
import LoadMoreSelect from "./LoadMoreSelect.vue";
import ButtonCom from "./ButtonCom.vue";
import OperationCom from "./OperationCom.vue";
import SelectTable from "./SelectTable.vue";
import UploadFile from "./UploadFile.vue";
import UploadBigFile from "./UploadBigFile.vue";
import UploadMultipleFile from "./UploadMultipleFile.vue";
import DetailCard from "./DetailCard.vue";
import DetailPopCard from "./DetailPopCard.vue";
import DetailLastCard from "./DetailLastCard.vue";
import StepsProgressUpgraded from "./StepsProgress.vue";
import TableUpgraded from "./Table.vue";
import Player from "./Player.vue";
import CircularRadioBox from "./CircularRadioBox.vue";
import CircularCheckBox from "./CircularCheckBox.vue";
import UploadLoading from "./UploadLoading.vue";
import ArticleEditor from "./ArticleEditor.vue";

import { App } from "vue";

import * as ElementPlusIconsVue from "@element-plus/icons-vue";

const components = [
  DrawerCom,
  BasePage,
  NormalTable,
  TopSearchForm,
  DialogBox,
  DialogLastBox,
  EmptyCom,
  SvgIcon,
  StepsCom,
  MessageCom,
  TabsCom,
  BaseECharts,
  LoadMoreSelect,
  ButtonCom,
  OperationCom,
  SelectTable,
  UploadFile,
  UploadBigFile,
  StepsProgressUpgraded,
  DetailCard,
  DetailPopCard,
  DetailLastCard,
  TableUpgraded,
  Player,
  CircularRadioBox,
  CircularCheckBox,
  UploadLoading,
  ArticleEditor,
  UploadMultipleFile,
];
export default function useGlobalComponents(app: App) {
  components.forEach((component) => {
    app.component(component.name, component);
  });
  for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component);
  }
}
