import { createRouter, RouteRecordRaw, createWebHistory } from "vue-router";
import menusRoutes from "./menus";
import clientRouter from "./clientRouter";
import adminRouter from "./adminRouter";
import adminMenus from "./adminMenus";
import store from "./../store/index";
import i18n from "./../i18n/index";
import { getToken } from "./../utils/util";
import { getUserInfo } from "../api/auth";

const routes: RouteRecordRaw[] = [
  {
    path: "/",
    name: "Index",
    redirect: "/home",
    component: () => import("@/views/Index.vue"),
    meta: {
      code: "*",
      permissions: [],
    },
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    children: [...clientRouter, ...menusRoutes],
  },
  {
    path: "/admin-index",
    name: "admin-index",
    redirect: "/admin/news-manage",
    component: () => import("@/views/AdminIndex.vue"),
    meta: {
      code: "*",
      permissions: [],
    },
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    children: [...adminRouter, ...adminMenus],
  },
  {
    path: "/forgetPwd",
    name: "forget-pwd",
    component: () => import("../views/public/ForgetPwd.vue"),
    meta: {
      requireLogin: false,
    },
  },
  {
    path: "/register",
    name: "register",
    component: () => import("../views/public/Register.vue"),
    meta: {
      requireLogin: false,
    },
  },
  {
    path: "/404",
    component: () => import("../views/NoFound.vue"),
    meta: {
      requireLogin: false,
    },
  },
  {
    path: "/500",
    component: () => import("../views/errorIndex.vue"),
    meta: {
      requireLogin: false,
    },
  },
  {
    path: "/:pathMatch(.*)*",
    name: "NoFound",
    component: () => import("../views/NoFound.vue"),
    meta: {
      requireLogin: false,
    },
  },
];

const whiteList = [
  "/",
  "/home",
  "/register",
  "/demand",
  "/service",
  "/event",
  "/course",
  "/news",
  "/about-us",
  "/404",
  "/500",
  "/forgetPwd",
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});
router.beforeEach((to, from, next) => {
  const { state, commit } = store;
  if (!state.userInfo && getToken()) {
    getUserInfo().then((res: any) => {
      commit("setUserInfo", res.results);
      if (res.results.is_superuser) {
        i18n.global.locale.value = "zh-CN";
        commit("setLangCode", "zh-CN");
        if (!to.path.includes("admin") && whiteList.indexOf(to.path) > -1) {
          next();
        } else if (!to.path.includes("admin")) {
          next(`/admin-index`);
        } else {
          next();
        }
      } else {
        if (to.path.includes("admin")) {
          next(`/home`);
        } else {
          next();
        }
      }
    });
  } else {
    if (getToken()) {
      next();
    } else if (whiteList.indexOf(to.path) > -1) {
      next();
    } else {
      commit("setLoginVisible", true);
    }
    if (!getToken() && whiteList.indexOf(to.path) < 0) {
      next(`/home`);
    }
  }
});

export { routes };
export default router;
