import * as ECharts from "echarts/core";
import {
  BarChart,
  GaugeChart,
  PieChart,
  HeatmapChart,
  LineChart,
  PictorialBarChart,
  MapChart,
  RadarChart,
} from "echarts/charts";

// 引入提示框，标题，直角坐标系，数据集，内置数据转换器组件，组件后缀都为 Component
import {
  TitleComponent,
  TooltipComponent,
  GridComponent,
  DatasetComponent,
  TransformComponent,
  GraphicComponent,
  VisualMapComponent,
  LegendComponent,
  ToolboxComponent,
  GeoComponent,
} from "echarts/components";

// 标签自动布局，全局过渡动画等特性
import { LabelLayout, UniversalTransition } from "echarts/features";

// 引入 Canvas 渲染器，注意引入 CanvasRenderer 或者 SVGRenderer 是必须的一步
import { CanvasRenderer } from "echarts/renderers";

import type * as echarts from "echarts/core";
import type { BarSeriesOption, PieSeriesOption } from "echarts/charts";
import type {
  TitleComponentOption,
  TooltipComponentOption,
  GridComponentOption,
  LegendComponentOption,
  ToolboxComponentOption,
  GeoComponentOption,
} from "echarts/components";

// 注册必须的组件
ECharts.use([
  TitleComponent,
  TooltipComponent,
  GridComponent,
  DatasetComponent,
  TransformComponent,
  GraphicComponent,
  BarChart,
  LabelLayout,
  UniversalTransition,
  CanvasRenderer,
  GaugeChart,
  PieChart,
  VisualMapComponent,
  HeatmapChart,
  LegendComponent,
  ToolboxComponent,
  LineChart,
  PictorialBarChart,
  GeoComponent,
  MapChart,
  RadarChart,
]);

export default ECharts;

/** 按需加载的Echarts图表配置项类型 */
export type ECOption = echarts.ComposeOption<
  | BarSeriesOption
  | PieSeriesOption
  | TitleComponentOption
  | TooltipComponentOption
  | GridComponentOption
  | LegendComponentOption
  | ToolboxComponentOption
  | GeoComponentOption
>;
