<!-- EmptyCom  -->
<!-- 
  <EmptyCom :emptyData="emptyData"></EmptyCom>
  传入空样式配置emptyData: {
        type: "emptyReport", ------（状态：emptyMessage无详细内容，emptyTable表格list无内容，emptyReport报告制作中，emptyWaitingReport等待报告，empty404为404页面，empty500为500页面）
        message: "暂无相关信息", ------（空内容下面的提示内容） 
        visible: false, ------（空内容显示隐藏控制属性,false为隐藏，true为显示）
      },
     -->

<template>
  <div
    v-if="emptyData.visible"
    class="emptyDataCon"
    :class="
      emptyData.type == 'empty404' || emptyData.type == 'empty500'
        ? 'width400'
        : emptyData.type === 'emptyList'
        ? 'width360'
        : emptyData.type == 'empty150'
        ? 'width150'
        : emptyData.type == 'empty120'
        ? 'width120'
        : 'width180'
    "
  >
    <!-- <img
      v-if="
        emptyData.type == 'emptyMessage' ||
        emptyData.type == 'empty150' ||
        emptyData.type == 'empty120'
      "
      src="@/assets/icons/emptyMessage.png"
      alt=""
    /> -->

    <img
      v-if="emptyData.type == 'emptyList'"
      src="@/assets/icons/emptyList.png"
      alt=""
    />

    <!-- <img
      v-if="emptyData.type == 'emptyTable'"
      src="@/assets/icons/emptyTable.png"
      alt=""
    />
    <img
      v-if="emptyData.type == 'emptyReport'"
      src="@/assets/icons/emptyReport.png"
      alt=""
    />
    <img
      v-if="emptyData.type == 'emptyWaitingReport'"
      src="@/assets/icons/emptyWaitingReport.png"
      alt=""
    />
    <img
      v-if="emptyData.type == 'empty404'"
      src="@/assets/icons/empty404.png"
      alt=""
    />
    <img
      v-if="emptyData.type == 'empty500'"
      src="@/assets/icons/empty500.png"
      alt=""
    /> -->
    <div class="emptyHint">{{ emptyData.message }}</div>
  </div>
</template>
<script lang="ts">
import { defineComponent, PropType } from "vue";

export default defineComponent({
  name: "EmptyCom",
  props: {
    emptyData: {
      type: Object as PropType<{
        visible: boolean;
        type: string;
        message: string;
        width: number;
        height: number;
      }>,
      default: () => ({
        page: 1,
        size: 10,
      }),
    },
  },
});
</script>
<style scoped>
.emptyDataCon {
  font-size: 14px;
  color: #cccccc;
  line-height: 20px;
  text-align: center;
  margin: 0 auto;
  padding-top: 80px;
  padding-bottom: 60px;
  /* padding-bottom: calc(100vh - 630px); */
}

.emptyDataCon > img {
  width: 100%;
}

.emptyHint {
  font-size: 16px;
  color: #222;
  text-align: center;
  margin-top: 12px;
}

.width400 {
  width: 400px;
}

.width360 {
  width: 364px;
}

.width180 {
  width: 180px;
}
.width150 {
  width: 150px;
}
</style>
