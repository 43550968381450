<template>
  <div class="el-loading-mask" @click.stop.prevent>
    <div class="el-loading-spinner">
      <svg class="circular" viewBox="0 0 50 50">
        <circle class="path" cx="25" cy="25" r="20" fill="none"></circle>
      </svg>
      <div class="uploadPercentage">{{ uploadPercentage }}%</div>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";
export default defineComponent({
  name: "UploadLoadingComp",
  props: {
    uploadPercentage: {
      type: Number,
      default: "",
    },
  },
  data() {
    return {};
  },
  created() {},
  methods: {},
});
</script>
<style scoped lang="scss">
.el-loading-mask {
  top: 1px;
  bottom: 1px;
  left: 1px;
  right: 1px;
  cursor: not-allowed;
  --el-loading-spinner-size: 54px;

  .uploadPercentage {
    position: absolute;
    top: 11px;
    bottom: 0;
    left: 0;
    right: 0;
    font-size: 12px;
    color: #333333;
  }
}
</style>
