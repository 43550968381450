<template>
  <div class="uploadCon">
    <div
      v-for="(item, index) in modelValue"
      :key="item.path"
      class="uploadedFile"
      @click="showPreviewImg(item.url)"
    >
      <img :src="item.url" class="cover" alt="" />
      <div
        v-if="showCover"
        :class="['setting', item.coverPicture ? 'active' : '']"
        @click.stop="toggleCover(index)"
      >
        {{ item.coverPicture ? "封面" : "设为封面" }}
      </div>
      <img
        class="delImg"
        src="@/assets/images/closeCircle.png"
        alt=""
        @click.stop="delFile(index)"
      />
    </div>
    <el-upload
      id="upload0"
      class="avatar-uploader"
      :show-file-list="false"
      drag
      :on-success="($event) => handleUploadSuccess($event)"
      :on-error="(res) => handleUploadError(res)"
      :headers="headers"
      :before-upload="($events) => beforeUpload($events)"
      :on-progress="($events) => progressRecord($events, 0)"
      :action="action"
      v-bind="$attrs"
      accept=".jpg,.jpeg,.png,.gif"
      multiple
    >
      <el-icon class="avatar-uploader-icon" size="34">
        <Plus />
        <div class="text">{{ uploadTxt }}</div>
        <div v-if="errorArr" class="uploadErr">
          {{ errorArr }}
        </div>
      </el-icon>
      <UploadLoading
        v-if="uploadingStatus"
        :upload-percentage="uploadings"
      ></UploadLoading>
    </el-upload>
  </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import { downloadFileSimple, getToken, uploadBigFile } from "../../utils/util";
export default defineComponent({
  name: "UploadFileUpgraded",
  props: {
    type: {
      type: String,
      default: "",
    },
    modelValue: {
      type: Array,
      default: () => [],
    },
    infoTxt: {
      type: String,
      default: "",
    },
    templateDownloadType: {
      type: [String, Object],
      default: "",
    },
    templateDownloadUrl: {
      type: String,
      default: "",
    },
    templateDownloadName: {
      type: String,
      default: "",
    },
    isImg: {
      type: Boolean,
      default: false,
    },
    uploadTxt: {
      type: String,
      default: "上传图片",
    },
    action: {
      type: String,
      default: "",
    },
    showPdf: {
      type: Boolean,
      default: false,
    },
    showCover: {
      type: Boolean,
      default: false,
    },
    uploadingStatus: {
      type: Boolean,
      default: false,
    },
    uploadings: {
      type: Number,
      default: 0,
    },
  },
  emits: [
    "update:modelValue",
    "downloadFile",
    "uploading",
    "lookItem",
    "update:uploadingStatus",
    "update:uploadings",
  ],
  data() {
    return {
      errorArr: "",
      headers: {
        Authorization: "Bearer " + getToken(),
      },
    };
  },
  methods: {
    showPreviewImg(url: string) {
      this.$store.commit("setPreviewImageConfig", {
        url,
        visible: true,
      });
    },
    toggleCover(index: number | string) {
      const copyModelValue = [...this.modelValue];
      copyModelValue.forEach(
        (pictureFile) => (pictureFile.coverPicture = false)
      );
      copyModelValue[index].coverPicture = true;
      const file = this.modelValue[index];
      copyModelValue.splice(index, 1);
      copyModelValue.unshift(file);
      this.$emit("update:modelValue", copyModelValue);
    },
    downloadFileSimple,
    handleUploadSuccess(res: object) {
      const copyModelValue = [...this.modelValue];
      this.$emit("update:uploadingStatus", false);
      this.$emit("update:uploadings", 0);
      const data = res.results;
      this.errorArr = "";
      const fileObj = {
        filename: data.filename,
        path: data.path,
        url: data.url,
        coverPicture: !this.modelValue?.length,
        firstFrameUrl: data.firstFrameUrl,
        firstFramePath: data.firstFramePath,
      };
      copyModelValue.push(fileObj);
      this.$emit("update:modelValue", copyModelValue);
    },
    handleUploadError(res: Object) {
      this.errorArr = "上传失败!";
      this.$emit("update:uploadingStatus", false);
      this.$emit("update:uploadings", 0);
    },
    progressRecord(res, index: number) {
      this.$emit(
        "update:uploadings",
        Math.floor(res.percent) >= 100 ? 99 : Math.floor(res.percent)
      );
    },
    beforeUpload(rawFile: any) {
      this.errorArr = "";
      if (Array.isArray(rawFile)) {
        this.$store.commit("setErrConfig", {
          visible: true,
          message: "只支持单个文件",
        });
        return;
      }
      const { accept } = this.$attrs;
      let maxSize = 0;
      if (accept === ".jpg,.jpeg,.png,.gif") {
        maxSize = this.$attrs["max-size"] || 20 * 1024 * 1024;
      } else {
        maxSize = this.$attrs["max-size"] || 200 * 1024 * 1024;
      }

      if (
        !accept
          .split(",")
          .map((v) => v.trim())
          .includes(`.${rawFile.name.split(".").slice(-1)[0]}`)
      ) {
        this.errorArr = "格式不支持";
        return false;
      } else if (rawFile.size > maxSize) {
        this.errorArr = "大小超出限制";
        return false;
      }
      this.errorArr = "";
      this.$emit("update:uploadingStatus", true);

      if (this.type === "bigFile") {
        uploadBigFile(rawFile, (message: string, data: string) => {
          this.uploadCall(message, data);
        });
      } else {
        this.$emit("uploading");
      }
      return true;
    },
    delFile(index: number) {
      const copyModelValue = [...this.modelValue];
      // 移除文件
      if (this.showCover && this.modelValue[index].coverPicture) {
        copyModelValue.splice(index, 1);
        copyModelValue.coverPicture = true;
      } else {
        copyModelValue.splice(index, 1);
      }
      this.$emit("update:modelValue", copyModelValue);
    },
    lookItem() {
      // 查看pdf
      this.$emit("lookItem", "update:modelValue");
    },
    uploadCall(message: string, data: string) {
      if (message === "uploading") {
        this.progressType = true;
        this.uploadPercentage = data;
      } else if (data?.fileName != null || data?.fileName != "") {
        this.loading = false;
        this.progressType = false;
        this.uploadPercentage = 100;
        this.errMsg = "";
        this.uploadName = data;
        this.$emit("update:modelValue", data);
      }
      if (data && data.status === 500) {
        this.uploadPercentage = 0;
        this.errMsg = "上传失败";
        this.loading = false;
        this.progressType = false;
      }
    },
  },
});
</script>
<style lang="scss" scoped>
@mixin clampRowEllipsis($n) {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: $n;
  overflow: hidden;
}
.uploadItem ::v-deep(.el-form-item__error) {
  display: none;
}

.uploadCon {
  display: flex;
  gap: 12px;
  flex-wrap: wrap;
}

.uploadErr {
  font-size: 12px;
  color: #db3940;
  line-height: 17px;
  font-style: normal;
}

.uploadedFile,
::v-deep(.el-icon.avatar-uploader-icon),
.avatar {
  width: 144px;
  height: 144px;
  border-radius: 8px;
  overflow: hidden;
  flex: none;
}

.uploadedFile {
  position: relative;
  cursor: pointer;

  .avatar {
    padding: 30px 12px 0;
    box-sizing: border-box;
    text-align: center;
    font-size: 0px;
    line-height: 0px;
    border: 1px dotted #dcdfe6;
    background: #f6f8fa;

    .fileName {
      font-size: 14px;
      color: #666666;
      line-height: 20px;
      padding: 0;
      word-break: break-all;
      @include clampRowEllipsis(2);
    }
  }

  .avatar .file {
    width: 50px;
    height: 50px;
    margin-bottom: 14px;
  }

  &:hover {
    &::after {
      position: absolute;
      content: " ";
      background: rgba(0, 0, 0, 0.5);
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
    }

    .delImg {
      display: inline-block;
    }
  }

  .delImg,
  .ticked {
    width: 16px;
    height: 16px;
    top: 8px;
    right: 8px;
    position: absolute;
    display: none;
  }

  .ticked {
    display: inherit;
  }

  .delImg {
    z-index: 1;
  }

  .cover {
    width: 100%;
    height: 100%;
  }

  .setting {
    line-height: 18px;
    background: rgba(0, 0, 0);
    opacity: 0.5;
    color: #ffffff;
    width: 100%;
    position: absolute;
    bottom: 0px;
    text-align: center;
    z-index: 2;

    &:hover,
    &.active {
      background: #2468f1;
      opacity: 1;
    }
  }

  .play {
    position: absolute;
    width: 32px;
    height: 32px;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
  }
}
</style>
