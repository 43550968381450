<!-- MyTable组件 -->
<!-- <NormalTable :columnData="tableColumns" :table-data="tableData" row-selection @selection-change="SelectionChange1">
  <template #province1>  ------（插槽字段显示）
    <el-table-column label="发货地址" align="left">
      <template #default="scoped">
        <span> {{ scoped.row.province }} / {{ scoped.row.city }} </span>
      </template>
    </el-table-column>
  </template>

  <template #typeSlot>  ------（插槽状态）
        <el-table-column label="状态" width="260px" align="left">
          <template #default="scoped">
            <span v-if="scoped.row.treatmentStatus" class="bgFBA904"> ------（状态样式：bg+设计稿按钮颜色值#FBA904）  
                <i></i>
                已对接
              </span>
              <span v-if="!scoped.row.treatmentStatus" class="bg00CF12">
                <i></i>
                匹配中
              </span>
          </template>
        </el-table-column>
  </template>

  <template #tableSlot>  ------（插槽操作）
        <el-table-column label="操作" width="260px" align="left">
          <template #default="scoped">
            <span class="tableOperate"  @click.stop="deleteClick1(scoped.row)">
              操作1
            </span>
            <span class="tableOperate"  @click.stop="deleteClick1(scoped.row)">
              操作2
            </span>
          </template>
        </el-table-column>
      </template>
</NormalTable>

参数配置： row-selection：选择多行
      tableColumns: [
        { label: "序号", prop: "index", width: 80,fixedType:"right"}, ------（序号）fixedType:"right"为固定列参数配置
        { label: "序号", prop: "index", width: 80 },  ------（序号）
        { label: "设备名称", prop: "equipmentName" },  ------（正常字段展示）
        { label: "品牌", prop: "brand" },
        { slot: "province1" },  ------（插槽字段显示）
        { slot: "tableSlot" },  ------（插槽操作）
        
      ],

  操作配置：
    <template #tableSlot>
        <el-table-column label="操作" width="260px" align="left">
          <template #default="scoped">
            <span class="tableOperate"  @click.stop="deleteClick1(scoped.row)">
              操作1
            </span>
            <span class="tableOperate"  @click.stop="deleteClick1(scoped.row)">
              操作2
            </span>
          </template>
        </el-table-column>
      </template>
-->
<template>
  <div :class="['myTable', scroll ? 'scrollCon' : '']">
    <div class="mesModule">
      <el-table
        v-bind="$attrs"
        ref="myTableRef"
        :data="tableData"
        :height="tableHeight"
        class="tableClass"
        style="width: 100%"
        stripe
        scrollbar-always-on
      >
        <template #empty>
          <empty-com class="emptyStyle" :empty-data="emptyData"></empty-com>
        </template>
        <!-- 多选 -->
        <el-table-column
          v-if="rowSelection"
          type="selection"
          style="width: 55px"
          fixed="left"
          :selectable="
                    (row: any) => {
                      return !row.cannotBeSelected;
                    }"
        ></el-table-column>
        <el-table-column
          v-if="rowSingleSelection"
          type="index"
          width="34px"
          align="left"
          fixed="left"
        >
          <template #default="scoped">
            <i
              class="select_region"
              :class="singleSelectItem === scoped.row.id ? 'on' : ''"
              @click="changeSingleItem(scoped.row.id)"
            ></i>
          </template>
        </el-table-column>
        <template v-for="(item, index) in columnData || []" :key="index">
          <el-table-column
            v-if="item.label == '序号'"
            type="index"
            :width="item.width"
            label="序号"
            align="left"
          ></el-table-column>
          <el-table-column
            v-else-if="!item.slot"
            :fixed="item.fixed"
            :label="item.label"
            :prop="item.prop"
            :width="item.width"
            :min-width="item.minWidth"
            :sortable="item.sortable"
            :show-overflow-tooltip="true"
            :max-height="item.height"
            :align="item.left || 'left'"
          >
            <template #default="scoped">
              <span
                v-if="scoped.row[item.prop] === 0"
                class="positionRelative textOverflow"
              >
                0
              </span>
              <span v-else class="positionRelative textOverflow">
                {{ scoped.row[item.prop || ""] || item.defaultValue || "- -" }}
              </span>
            </template>
          </el-table-column>
          <slot v-else :name="item.slot"></slot>
        </template>
      </el-table>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent, PropType } from "vue";
import EmptyCom from "./EmptyCom.vue";

export interface ConfigData {
  name: string;
  value: string[];
  label: string;
  width: number;
  slot?: boolean;
  prop?: string;
  minWidth?: number;
  height?: number;
  sortable?: boolean;
  left?: string;
  fixed: boolean;
  defaultValue?: string;
}

export default defineComponent({
  name: "NormalTable",
  components: { EmptyCom },
  props: {
    // 数据
    tableData: {
      type: Array,
      default: () => {
        return [];
      },
    },
    // 高度
    tableHeight: {
      type: [Number, String],
    },
    // 开启选择
    rowSelection: {
      type: Boolean,
      default: () => {
        return false;
      },
    },
    // 开启单选
    rowSingleSelection: {
      type: Boolean,
      default: () => {
        return false;
      },
    },
    // 列
    columnData: {
      type: Array as PropType<ConfigData[]>,
      default: () => {
        return [];
      },
    },
    scroll: {
      type: Boolean,
      default: false,
    },
  },
  expose: ["myTableRef"],
  data() {
    return {
      myTableRef: null,
      singleSelectItem: "",
      emptyData: {
        type: "emptyList",
        message: "暂无相关信息",
        visible: true,
        width: 1,
        height: 1,
      },
    };
  },
  methods: {
    changeSingleItem(id) {
      this.singleSelectItem = id;
      this.$emit("changeSingle", this.singleSelectItem);
    },
  },
});
</script>
<style scoped>
.emptyStyle {
  /* margin-top: 25%; */
}

.myTable {
  width: 100%;
  /* overflow: hidden !important; */
  position: relative;
  flex: 1;
}

.mesModule {
  padding-bottom: 0;
  min-height: calc(100vh - 300px);
  /* height: 100%; */
}

.flexp {
  display: flex;
  align-items: center;
}

.bgColor {
  display: block;
  width: 6px;
  height: 6px;
  background: #276efa;
  border-radius: 3px;
  float: left;
  margin-right: 6px;
}

.tableClass ::v-deep(tr th) {
  background-color: #f0f2f8 !important;
  font-size: 14px;
  height: 43px;
  color: #333333;
  line-height: 44px;
  font-weight: bold;
}

::-webkit-scrollbar {
  /*滚动条整体样式*/
  width: 6px;
  /*高宽分别对应横竖滚动条的尺寸*/
  height: 6px;
}

::-webkit-scrollbar-thumb {
  /*滚动条里面小方块*/
  border-radius: 6px;
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.1);
  background: rgba(0, 0, 0, 0.1);
}

::-webkit-scrollbar-track {
  /*滚动条里面轨道*/
  background: #f6f6f6;
  border-radius: 6px;
}

/* ::v-deep(.el-scrollbar__bar.is-horizontal) {
  height: 12px;
  opacity: 1;
}
::v-deep(.el-scrollbar__bar.is-horizontal:hover) {
  height: 12px;
  background: #f6f6f6;
} */

.tableClass ::v-deep(tr td) {
  height: 44px;
  color: #595959;
  font-size: 13px;
  box-sizing: border-box;
}

.myTable.scrollCon ::v-deep(.el-scrollbar__view) {
  height: 100% !important;
}

.myTable.scrollCon ::v-deep(.emptyDataCon) {
  margin-top: 0;
  padding-top: 0;
}

.emptyContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.myTable .tablePage {
  position: sticky;
  bottom: 0px !important;
  left: 0;
  right: 0;
  height: 76px;
  background-color: #fff;
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.05);
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 11;
}

.borderShow ::v-deep(.el-pagination) {
  margin: 0 auto;
  max-width: 800px;
}

.borderShow ::v-deep(.el-pager li) {
  background-color: #fff !important;
  padding: 0 10px;
  height: 30px;
  border-radius: 4px;
  border: 1px solid #e3e3e3;
}

.borderShow
  ::v-deep
  .el-pagination.is-background
  .el-pager
  li:not(.is-disabled).is-active {
  background: #2468f1 !important;
  border-radius: 4px;
}

.leftTips {
  padding-left: 20px;
  font-size: 12px;
  color: #4e4e4e;
  display: flex;
  align-items: center;
}

.leftTips p {
  display: flex;
  align-items: center;
}

.tips {
  margin-left: 6px;
  color: #276efa;
  font-size: 20px;
}

.pageRight {
  display: flex;
  margin-right: 20px;
}

.userHeigh {
  width: 100%;
}

.el-button--primaryBackground {
  background: #276efa;
  color: #ffffff;
  border: none;
}

.el-button--primaryBackground:focus,
.el-button--primaryBackground:hover {
  background: #276efa;
  color: #ffffff;
  border: none;
}

.el-button--primaryBackground.is-active,
.el-button--primaryBackground:active {
  background: #276efa;
  color: #ffffff;
  border: none;
}

.el-button--whiteBackground {
  border: 1px solid rgba(39, 110, 250, 0.3);
  color: #276efa;
  background-color: #fff !important;
}

.el-button--whiteBackground:focus,
.el-button--whiteBackground:hover {
  border: 1px solid rgba(39, 110, 250, 0.3);
  color: #276efa;
  background-color: #fff !important;
}

.el-button--whiteBackground.is-active,
.el-button--whiteBackground:active {
  border: 1px solid rgba(39, 110, 250, 0.3);
  color: #276efa;
  background-color: #fff !important;
}

.bth {
  width: 100px !important;
  height: 40px !important;
  margin-top: -4px;
}

.bthdef {
  width: 100px !important;
  height: 40px !important;
  margin-top: -4px;
}

::v-deep(.el-button) {
  margin-left: 0;
}

.radioCustom {
  width: 16px;
  height: 16px;
  background: #ffffff;
  border-radius: 9px;
  border: 1px solid #dcdee6;
  display: inline-block;
  vertical-align: middle;

  &.checked {
    background: #016fff;
    border-color: #016fff;
    position: relative;

    &::after {
      position: absolute;
      content: " ";
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      background: url("@/assets/images/tick.png") center / 10px 10px no-repeat;
    }
  }
}

.textOverflow {
  display: inline-block;
  width: -webkit-fill-available;
  width: -moz-available;
  width: fill-available;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  vertical-align: bottom;
}

.select_region {
  width: 14px;
  height: 14px;
  box-sizing: border-box;
  border: 1px solid #b1b6c8;
  background-color: #ffffff;
  display: inline-block;
  vertical-align: top;
  margin: 8px 10px 8px 6px;
  border-radius: 100%;
  cursor: pointer;
}

.select_region.on {
  border: 1px solid #2468f1;
  background-color: #2468f1;
  background-image: url(@/assets/icons/active_icon.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}

::v-deep(.el-popper) {
  max-width: 420px !important;
}

::v-deep(
    .el-table--striped
      .el-table__body
      tr.el-table__row--striped
      td.el-table__cell
  ) {
  background: #f8f8fb;
}
</style>
