<template>
  <el-dialog
    :key="config.key || new Date().getTime()"
    v-model="visible"
    :title="config.name"
    class="dialog_box_upgraded"
    :class="config?.className"
    :width="config.width"
    :lock-scroll="false"
    :close-icon="'dialog_close_icon'"
    @close="$emit('close')"
  >
    <div
      class="content"
      :style="`height:${config.height || 'auto'}px`"
      @click.stop
    >
      <template v-if="config.type === 'form'">
        <slot name="dialogBoxContent"></slot>
      </template>
      <template v-if="config.type === 'text'">
        <slot name="dialogBoxContent"></slot>
      </template>
      <template v-if="config.type === 'message'">
        <div class="message messageType">
          <img
            src="@/assets/images/warning_dialog.png"
            alt=""
            class="iconfont hintIco"
          />
          <p
            v-for="(mes, index) in config.message?.split('<br/>')"
            :key="index"
          >
            {{ mes }}
          </p>
          <p v-if="config?.hintMes" style="padding-left: 32px">
            {{ config.hintMes }}
          </p>
        </div>
      </template>
    </div>
    <template v-if="config.isShowFooter" #footer>
      <slot name="dialogBoxFooter"></slot>
    </template>
  </el-dialog>
</template>
<script lang="ts">
import { defineComponent, PropType } from "vue";
import { Right } from "@element-plus/icons-vue";

export interface ListMesData {
  mes: string;
}

export interface ConfigData {
  name: string;
  width: number;
  listName: string;
  listMes: Array<ListMesData>;
}

export default defineComponent({
  name: "DialogBoxUpgraded",
  components: { Right },
  props: {
    config: {
      type: Object as PropType<{
        visible: string;
        name: string;
        type: string;
        isShowFooter: boolean;
        message: string;
        img: string;
        hintMes: string;
        time: string;
        width: number;
        height: number;
        customClass: string;
        data: Array<ConfigData>;
        page: number;
        size: number;
        messageList: Array<ConfigData>;
        key?: string;
      }>,
      default: () => ({
        page: 1,
        size: 10,
      }),
    },
  },
  emits: ["close"],
  data() {
    return {
      visible: false,
    };
  },
  watch: {
    "config.visible": {
      handler(newVal: boolean) {
        this.visible = newVal;
      },
      immediate: true,
    },
  },
});
</script>

<style scoped lang="scss"></style>

<style lang="scss">
.el-dialog.dialog_box_upgraded {
  padding: 0 0 0 0 !important;
  //background-image: url(@/assets/images/dialog_bg.png);background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;

  .el-dialog__header {
    background-color: transparent;
    padding: 20px 20px !important;
    box-sizing: border-box;
    position: relative;

    .el-dialog__title {
      color: #222222;
      line-height: 22px;
      display: inline-block;
    }

    .el-dialog__headerbtn {
      width: 20px;
      height: 20px;
      top: 20px !important;
      right: 22px;
      font-size: 12px;
      margin-right: -5px;
      line-height: 0;

      .el-icon {
        color: #666666;
        font-weight: bold;

        svg {
          margin-top: 5px;
        }
      }

      .el-dialog__close {
        background-image: url(@/assets/icons/dialog_close_icon.png);
        background-size: cover;
        display: inline-block;
        vertical-align: top;
      }
    }
  }

  .el-dialog__footer {
    padding: 12px 20px;
    box-sizing: border-box;

    .footer_center_box {
      text-align: center;
      padding: 0 0 18px 0;
      box-sizing: border-box;
    }

    .smallBtn {
      float: none;
      display: inline-block;
    }
  }

  .el-dialog__body {
    padding: 6px 0 26px 0;

    .toolbar_box {
      padding: 0 20px;
      text-align: left;
      box-sizing: border-box;
      position: relative;

      .search_keyword {
        width: 300px;
      }

      .el-checkbox {
        height: 20px;
        position: absolute;
        top: 5px;
        right: 20px;

        .el-checkbox__input {
          .el-checkbox__inner {
            width: 18px;
            height: 18px;
            border-radius: 4px;
            border: 1px solid #dcdee6;
            box-sizing: border-box;
          }

          .el-checkbox__inner:after {
            content: "";
            width: 10px;
            height: 10px;
            top: 3px;
            left: 3px;
            display: inline-block;
            border: 0 !important;
            transform: rotate(0deg) scaleY(1);
            background-image: url(@/assets/icons/checkbox_gou_icon.png);
            background-size: cover;
            background-repeat: no-repeat;
          }
        }

        .el-checkbox__label {
          line-height: 20px;
          color: #333333;
          font-size: 14px;
        }
      }
    }

    .content {
      width: 100%;

      .labels_box {
        width: 100%;

        .label {
          line-height: 20px;
          padding: 10px 20px;
          color: #333333;
          font-size: 16px;
          border-radius: 8px;
          display: inline-block;
          border: 1px solid #e5e7ef;
          box-sizing: border-box;
          margin: 0 5px 10px 5px;
          cursor: pointer;
          transition: border 0.3s, color 0.3s;
          position: relative;
          background-color: #ffffff;
        }

        .label.on {
          border: 1px solid #2468f1;
          color: #2468f1;
        }

        .label.on:before {
          content: "";
          position: absolute;
          right: -4px;
          top: -2px;
          width: 12px;
          height: 12px;
          background-image: url(@/assets/icons/active_icon.png);
          background-repeat: no-repeat;
          background-size: cover;
        }

        .label:hover {
          border: 1px solid #2468f1;
          color: #2468f1;
        }
      }

      .content_center_box {
        width: 100%;
        padding-top: 30px;
        text-align: center;

        .switch_item {
          width: 138px;
          height: 154px;
          display: inline-block;
          vertical-align: top;
          border: 2px solid #e4e4e4;
          border-radius: 12px;
          background: #ffffff;
          transition: background 0.3s, border 0.3s;
          margin: 0 20px;
          position: relative;
          box-sizing: border-box;
          cursor: pointer;

          > span.name {
            width: 100%;
            margin-top: 10px;
            line-height: 22px;
            display: inline-block;
            font-size: 16px;
            color: #999999;
            transition: color 0.3s;
          }

          > i.icon {
            position: absolute;
            width: 138px;
            height: 154px;
            display: inline-block;
            bottom: 0;
            left: 0;
          }
        }

        .switch_item.on {
          border: 2px solid #2468f1;
          background: #f5f8fe;

          > span.name {
            color: #2468f1;
          }
        }
      }
    }

    .content.type_2 {
      margin: 16px 10px 0 10px;
      padding: 0 10px;
      width: calc(100% - 20px);
      max-height: calc((76px * 5) + 1px);
      overflow-y: auto;
      overflow-x: hidden;
      box-sizing: border-box;
    }

    // .qr_code_box {
    //   width: 272px;
    //   height: 272px;
    //   display: inline-block;
    //   background-image: url(@/assets/images/qr_code_box.png);
    //   background-size: 100% 100%;
    //   margin-top: 54px;

    //   .qr_code {
    //     width: 240px;
    //     height: 240px;
    //     display: inline-block;
    //     background-image: url(@/assets/images/qr_code.png);
    //     background-size: 100% 100%;
    //     margin: 16px 16px;
    //   }
    // }

    .qr_code_tips {
      width: 100%;
      line-height: 20px;
      font-size: 14px;
      display: inline-block;
      margin-top: 14px;
      margin-bottom: 34px;
      color: #666666;
    }
  }
}

// 渐变背景
.el-dialog.type_1 {
  .el-dialog__header {
    text-align: left;
  }
}

//  对角底图
.el-dialog.type_2 {
  background-image: none;
}

// .el-dialog.type_2:before {
//   content: "";
//   display: inline-block;
//   background-image: url(@/assets/images/dialog_left_top_bg.png);
//   background-repeat: no-repeat;
//   background-size: cover;
//   background-position: center center;
//   width: 96px;
//   height: 92px;
//   position: absolute;
//   left: 0;
//   top: 0;
// }

// .el-dialog.type_2:after {
//   content: "";
//   display: inline-block;
//   background-image: url(@/assets/images/dialog_right_bottom_bg.png);
//   background-repeat: no-repeat;
//   background-size: cover;
//   background-position: center center;
//   width: 160px;
//   height: 160px;
//   position: absolute;
//   right: 0;
//   bottom: 0;
// }

.el-dialog.type_no_bg {
  background-image: none;
}

.dialogBox .content ::v-deep(.message) {
  width: 100%;
  height: 100%;
  padding: 0px 20px 0 20px;
  box-sizing: border-box;
}

.dialogBox .content ::v-deep(.message > i.iconfont) {
  display: inline-block;
  width: 32px;
  height: 24px;
  line-height: 1;
  font-size: 24px;
  vertical-align: top;
  text-align: left;
  color: #ec9e00;
}

.dialogBox .content ::v-deep(.message p) {
  margin-top: 2px;
  width: calc(100% - 32px);
  display: inline-block;
  vertical-align: top;
  height: auto;
  line-height: 20px;
  font-size: 14px;
  color: #4e4e4e;
  white-space: pre-wrap;
  text-align: left;
}

.dialogBox .content._message_form ::v-deep(.message),
.dialogBox .content._message_import ::v-deep(.message) {
  height: auto;
}
.messageType {
  padding-left: 30px !important;
  color: #595959;
  line-height: 20px;
  font-size: 14px;
  position: relative;
  text-align: left;
}
.hintIco {
  font-size: 24px;
  float: left;
  margin-right: 8px;
  color: #ec9e00;
  position: absolute;
  left: 0px;
  width: 24px;
  height: 24px;
  vertical-align: middle;
  top: 0px;
}
</style>
