<!-- EmptyData  -->
<!-- 
  <ButtonCom :btn-data="btnData"></ButtonCom>
  按钮组件配置 btnData: [
        {
          text: "开通账号", ------（按钮文案）
          configObj: {
            type: "primary",
          },
          localIcon: "add", ------（按钮前面的icon：add---是加号）
          btnType: "bigBtn",------（按钮大小状态：bigBtn--padding:5px 14px;smallBtn--padding:8px 12px;）
          btnClass: "bg016FFF",------（按钮背景颜色:bg016FFF---蓝色底，bgFFFFFF---白色底，"bgNoMes"---蓝色底不可点击）
          clickBtn: () => { ------（按钮点击方法）
            this.toAddAcct();
          },
        },
        {
          text: "确认",
          configObj: {
            type: "primary",
          },
          localIcon: "",
          btnType: "smallBtn",
          btnClass: "bg016FFF",
          clickBtn: () => {
            this.toAddAcct();
          },
        },
        {
          text: "取消",
          configObj: {
            type: "primary",
          },
          btnType: "smallBtn",
          btnClass: "bgFFFFFF",
          localIcon: "",
          clickBtn: () => {
            this.toAddAcct();
          },
        },
      ],
     -->

<template>
  <span
    v-for="(item, index) in btnData"
    :key="index"
    :class="[item.btnClass, item.btnType]"
    v-bind="item.configObj"
    :disabled="item.disabled"
    @click="!item.disabled && !item.isUpload && item.clickBtn()"
  >
    <template v-if="item.isUpload">
      <el-upload v-bind="item.uploadConfig" :show-file-list="false">
        <i v-if="item.localIcon" :class="item.localIcon" class="icon-size"></i>
        <label>{{ item.text }}</label>
      </el-upload>
    </template>
    <template v-else>
      <el-tooltip
        v-if="item.localIcon && item.iconHoverText"
        effect="customized"
        :content="item.iconHoverText"
        placement="top"
      >
        <i :class="item.localIcon" class="icon-size"></i>
      </el-tooltip>
      <i
        v-if="item.localIcon && !item.iconHoverText"
        :class="item.localIcon"
        class="icon-size"
      ></i>
      <el-icon v-if="item.elementIcon" color="#ffffff"
        ><component :is="item.elementIcon"></component
      ></el-icon>
      <label>{{ item.text }}</label>
    </template>
  </span>
</template>
<script lang="ts">
import { defineComponent, PropType } from "vue";
import type { UploadProps } from "element-plus";

export type BtnArray = {
  text: string;
  configObj: object;
  localIcon?: object;
  iconHoverText?: string;
  btnClass?: string;
  btnType?: string;
  disabled?: {
    type: boolean;
    default: false;
  };
  uploadConfig?: Partial<UploadProps>;
  isUpload?: {
    type: boolean;
    default: false;
  };
  elementIcon: string;
  clickBtn: () => void;
};
export default defineComponent({
  name: "ButtonCom",
  props: {
    btnData: {
      type: Array as PropType<BtnArray[]>,
      default: () => [],
    },
  },
});
</script>
<style scoped>
.bigBtn {
  padding: 8px 14px;
  height: auto;
  overflow: hidden;
  line-height: 22px;
  font-size: 16px;
  text-align: center;
  float: right;
  display: inline;
  border-radius: 4px;
  margin-left: 8px;
  cursor: pointer;
}

.pd42 {
  padding: 7px 42px;
}

.formBtn {
  padding: 5px 14px;
  height: auto;
  overflow: hidden;
  line-height: 24px;
  font-size: 14px;
  text-align: center;
  display: inline;
  border-radius: 4px;
  margin-left: 8px;
  cursor: pointer;
}

.smallBtn {
  padding: 7px 12px;
  height: auto;
  overflow: hidden;
  line-height: 20px;
  font-size: 14px;
  text-align: center;
  float: right;
  display: inline;
  border-radius: 4px;
  margin-left: 8px;
  cursor: pointer;
}

.smallBtn label {
  display: block;
  min-width: 56px;
  cursor: inherit;
}

.bg016FFF {
  border: 1px solid #2468f1;
  color: #fff;
  background-color: #2468f1;
  cursor: pointer;
}

.bg016FFF:hover,
.bgLight:hover {
  border: 1px solid #327fff;
  background-color: #327fff;
  cursor: pointer;
  color: #fff;
}

.bg080B0E {
  border: 1px solid #080b0e;
  color: #fff;
  background-color: #080b0e;
  cursor: pointer;
}

.bg080B0E:hover {
  background: #242d38;
}

.bgFFFFFF {
  border: 1px solid #e3e3e3;
  color: #4e4e4e;
  background-color: #fff;
  cursor: pointer;
}

.bgFFFFFF:hover {
  border: 1px solid #2468f1;
  color: #2468f1;
  background-color: #fff;
  cursor: pointer;
}

.bgLight {
  background: rgba(50, 127, 255, 0.05);
  border-radius: 4px;
  border: 1px solid #2468f1;
  cursor: pointer;
  color: #2468f1;
}

.icon-size {
  height: 14px;
  width: 14px;
  margin-right: 6px;
  background-size: 100% 100%;
  float: right;
  margin-top: 3px;
}

span[disabled="true"] {
  opacity: 0.5;
  cursor: not-allowed !important;
}

.registPrimary {
  width: 140px;
  box-sizing: border-box;
}

::v-deep(.el-upload:focus) {
  color: #fff;
}

.bgF2F3F9 {
  background: #f2f3f9;
  color: #4e4e4e;
  border: 1px solid #e3e3e3;
}

.bgF2F3F9:hover {
  background-color: #fff;
  border-radius: 4px;
  border: 1px solid rgba(36, 104, 241, 0.5);
  font-size: 14px;
  font-weight: 400;
  color: #2468f1;
}
</style>
