<!--StepsCom  -->
<!-- 
   <StepsCom :steps-config-arr="StepsConfigArr"></StepsCom>
  传入步骤格式配置 StepsConfigArr:[
        StepsConfigArr: [
        {
          number: 1, ------（步骤）
          typeMes: "进行中",  ------（步骤状态描述）
          hintMes: "这是一段长的文字描述", ------（副标题描述）
          activeType: 1, ------（当前在哪一步，控制高亮颜色）
        },
        {
          number: 2,
          typeMes: "未开始",
          hintMes:
            "这是一段很长很长很长的文字描述这是一段很长很长很长的文字描述",
          activeType: 1,
        },
      ],
     -->

<template>
  <div class="stepsUl">
    <div v-for="(item, index) in stepsConfigArr" :key="index" class="stepsLi">
      <i></i>
      <span :class="item.activeType == index ? 'txt016FFF' : ''">{{
        item.number
      }}</span>
      <i></i>
    </div>
  </div>
  <div class="stepsMesUl">
    <div
      v-for="(item, index) in stepsConfigArr"
      :key="index"
      class="stepsMesList"
    >
      <span :class="item.activeType == index ? 'color016FFF' : ''">{{
        item.typeMes
      }}</span>
      <span :class="item.activeType == index ? 'color016FFF' : ''">{{
        item.hintMes
      }}</span>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent, PropType } from "vue";
type StepsConfigArr = {
  number: number;
  typeMes: string;
  hintMes: string;
  activeType: number;
};

export default defineComponent({
  name: "StepsCom",
  props: {
    stepsConfigArr: {
      type: Array as PropType<StepsConfigArr[]>,
      default: () => [],
    },
  },
});
</script>
<style scoped>
.color016FFF {
  color: #2468f1 !important;
}

.txt016FFF {
  border: 2px solid #2468f1 !important;
  color: #2468f1 !important;
}

.txt39AD01 {
  border: 2px solid #39ad01 !important;
  color: #39ad01 !important;
}

.class39AD01 {
  background-color: #39ad01 !important;
}

.class016FFF {
  background-color: #2468f1 !important;
}

.stepsMesUl {
  width: auto;
  height: auto;
  overflow: hidden;
  margin: 0px auto;
}

.stepsMesList {
  width: 212px;
  height: auto;
  overflow: hidden;
  float: left;
}

.stepsMesList span:nth-child(1) {
  display: block;
  width: 100%;
  text-align: center;
  margin: 10px auto 8px auto;
  font-size: 16px;
  font-weight: 500;
  color: #c0c4cc;
}

.stepsMesList span:nth-child(2) {
  display: block;
  width: 100%;
  text-align: center;
  margin: 0px auto;
  font-size: 12px;
  font-weight: 400;
  color: #c0c4cc;
  height: auto;
  overflow: hidden;
  line-height: 17px;
}

.stepsUl {
  width: auto;
  height: auto;
  overflow: hidden;
  margin: 0 auto;
  /* overflow: hidden;
  position: absolute;
  left: 50%;
  top:50%;
  transform: translate(0%,-50%) */
}

.stepsLi {
  width: 212px;
  height: auto;
  overflow: hidden;
  float: left;
}

.stepsUl .stepsLi:nth-child(1) i:nth-child(1) {
  background-color: #fff !important;
}

.stepsUl .stepsLi:last-child i:last-child {
  background-color: #fff !important;
}

.stepsLi span {
  display: block;
  width: 28px;
  height: 28px;
  background: #ffffff;
  border: 2px solid #c0c4cc;
  float: left;
  border-radius: 16px;
  text-align: center;
  line-height: 28px;
  color: #c0c4cc;
  font-weight: 600;
}

.stepsLi i {
  width: 90px;
  height: 2px;
  background-color: #c0c4cc;
  float: left;
  margin-top: 13px;
}
</style>
