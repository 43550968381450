<!-- 弹框组件
  1、删除提示：
    <DialogBox :config="config_message1">
      <template #dialogBoxFooter>
        <div class="elFooter">
          <ButtonCom :btn-data="btnBoxData"></ButtonCom>
        </div>
      </template>
    </DialogBox>

    config_message1配置： config_message1: {
        name: "删除提示",  ------（title文案）
        width: "425", ------（设计稿宽度）
        height: "130", ------（设计稿高度）
        type: "message", ------（弹框类型：message为提示类型，form为form表单）
        message: "是否要删除该条信息？删除后信息将在平台中不再显示", ------（type为message时的提示语）
        hintMes:'禁用后该账号将不能登录平台，可再次执行‘启用’进行恢复使用。', ------（hintMes为message时的二级描述提示语）
        visible: false,------（控制弹框显示隐藏）
        isShowFooter: true,------（有无底部按钮）
      },

    2、form表单：
      <DialogBox :config="dialog_config_add">
      <template #dialogBoxContent>
        <el-form ref="form" :model="acctInfo" label-position="left" :rules="rules" class="errorTRForm">
          <el-form-item label="账号" required prop="userName">
            <el-input v-model="acctInfo.userName" :disabled="editBol" :autocomplete="'off'" placeholder="字母、数字，不超过10位"
              maxlength="10" />
          </el-form-item>
          <el-form-item label="密码" prop="password" required>
            <el-input v-model="acctInfo.password" type="password" :disabled="editBol" :autocomplete="'off'"
              placeholder="字母+数字，8~16位" maxlength="16" minlength="8" />
          </el-form-item>
          <el-form-item label="姓名" prop="name" required>
            <el-input v-model="acctInfo.name" type="text" autocomplete="off" placeholder="请输入" maxlength="16" />
          </el-form-item>
          <el-form-item label="角色" prop="roleId" required>
            <el-select v-model="acctInfo.roleId">
              <el-option v-for="item in roles" :key="item.id" :label="item.name" :value="item.id" />
            </el-select>
          </el-form-item>
        </el-form>
      </template>
      <template #dialogBoxFooter>
        <el-button class="cancel" @click="saveCancel">取消</el-button>
        <el-button class="confirm" @click="saveConfirm">确认</el-button>
      </template>
    </DialogBox>

    dialog_config_add配置：dialog_config_add: {
        name: "开通账号",
        width: "488",
        height: "327",
        type: "form",
        visible: false,
        isShowFooter: true,
      },
-->
<template>
  <el-dialog
    :key="config.key || new Date().getTime()"
    v-model="visible"
    :title="config.name"
    class="dialogStyle"
    :width="config.width"
    :lock-scroll="false"
    center
    @close="$emit('close')"
  >
    <template
      v-if="config.isShowHeader"
      #header="{ close, titleId, titleClass }"
    >
      <slot name="dialogBoxHeader"></slot>
    </template>
    <div class="content" :class="'_' + config.type" @click.stop>
      <template v-if="config.type === 'form'">
        <slot name="dialogBoxContent"></slot>
      </template>
      <template v-if="config.type === 'message'">
        <div class="message messageType">
          <img
            src="@/assets/images/warning_dialog.png"
            alt=""
            class="iconfont hintIco"
          />
          <p
            v-for="(mes, index) in config.message?.split('<br/>')"
            :key="index"
          >
            {{ mes }}
          </p>
          <p v-if="config?.hintMes" style="padding-left: 32px">
            {{ config.hintMes }}
          </p>
        </div>
      </template>
      <template v-if="config.type === 'messageList'">
        <div class="message lastMesList">
          <div v-for="(mes, index) in config.messageList" :key="index">
            <div class="mesName">{{ mes.listName }}</div>
            <div
              v-for="(mesList, index1) in mes.listMes"
              :key="index1"
              class="mesList"
            >
              {{ mesList.mes }}
            </div>
          </div>
        </div>
      </template>
    </div>
    <template v-if="config.isShowFooter" #footer>
      <slot name="dialogBoxFooter"></slot>
    </template>
  </el-dialog>
</template>
<script lang="ts">
import { defineComponent, PropType } from "vue";

export interface ListMesData {
  mes: string;
}

export interface ConfigData {
  name: string;
  width: number;
  listName: string;
  listMes: Array<ListMesData>;
}

export default defineComponent({
  name: "DialogBox",
  props: {
    config: {
      type: Object as PropType<{
        visible: string;
        name: string;
        type: string;
        isShowFooter: boolean;
        isShowHeader: boolean;
        message: string;
        img: string;
        hintMes: string;
        time: string;
        width: number;
        height: number;
        customClass: string;
        data: Array<ConfigData>;
        page: number;
        size: number;
        messageList: Array<ConfigData>;
        key?: string;
      }>,
      default: () => ({
        page: 1,
        size: 10,
      }),
    },
  },
  emits: ["close"],
  data() {
    return {
      visible: false,
    };
  },
  watch: {
    "config.visible": {
      handler(newVal: boolean) {
        this.visible = newVal;
      },
      immediate: true,
    },
  },
});
</script>
<style scoped>
.hintIco {
  font-size: 24px;
  float: left;
  margin-right: 8px;
  color: #ec9e00;
  position: absolute;
  left: 0px;
  width: 24px;
  height: 24px;
  vertical-align: middle;
  top: 0px;
}

.dialogBox .content {
  box-sizing: border-box;
  width: 100%;
}

.dialogBox .content ::v-deep(.el-form.dialogBoxForm) {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding: 6px 14px 0 14px;
}

::v-deep(.el-dialog__headerbtn) {
  background: url("@/assets/icons/closeBtn.png") !important;
}

.dialogBox .content ::v-deep(.el-form.dialogBoxForm .el-form-item) {
  width: calc(50% - 12px);
  margin: 14px 6px 0 6px;
  display: inline-block;
  vertical-align: top;
  font-size: 0;
}

.dialogBox .content ::v-deep(.el-form.dialogBoxForm .el-form-item.line) {
  width: calc(100% - 12px);
  margin: 14px 6px 0 6px;
}

.dialogBox .content ::v-deep(.el-form.dialogBoxForm .el-form-item__label) {
  display: inline-block;
  width: auto;
  text-align: left;
  line-height: 17px;
  margin-bottom: 6px;
  color: #595959;
  font-size: 12px;
  font-weight: 400;
  padding: 0 0;
  position: relative;
}

.dialogBox
  .content
  ::v-deep(.el-form.dialogBoxForm .el-form-item__label::before) {
  position: absolute;
  right: -12px;
  top: 0;
  color: #f56c6c;
  margin: 0 0;
  width: 12px;
  line-height: 1.8;
  text-align: center;
}

.dialogBox .content ::v-deep(.el-form.dialogBoxForm .el-form-item__content) {
  display: inline-block;
  width: 100%;
}

.dialogBox .content ::v-deep(.el-form.dialogBoxForm .el-input) {
  width: 100%;
  height: 36px;
  color: #333333;
  border: 0;
}

.dialogBox
  .content
  ::v-deep(.el-form.dialogBoxForm .el-input .el-input__inner) {
  width: 100%;
  height: calc(100% - 2px);
  line-height: calc(100% - 2px);
  padding-left: 12px !important;
  font-size: 14px;
  border: 1px solid #dcdee6;
  -webkit-box-shadow: 0 0 0 0 #dcdee6;
  -moz-box-shadow: 0 0 0 0 #dcdee6;
  box-shadow: 0 0 0 0 #dcdee6;
}

.dialogBox
  .content
  ::v-deep(
    .el-form.dialogBoxForm .el-form-item.is-error .el-input .el-input__inner
  ) {
  border: 1px solid #f56c6c;
  -webkit-box-shadow: 0 0 0 0 #f56c6c;
  -moz-box-shadow: 0 0 0 0 #f56c6c;
  box-shadow: 0 0 0 0 #f56c6c;
}

.dialogBox
  .content
  ::v-deep(.el-form.dialogBoxForm .el-input.is-disabled .el-input__inner) {
  color: #999999;
}

.dialogBox
  .content
  ::v-deep(.el-form.dialogBoxForm .el-form-item__content .el-form-item__error) {
  position: absolute;
  right: 0;
  top: -23px;
  text-align: right;
  line-height: 17px;
  padding: 0 0;
  font-size: 12px;
  color: #f56c6c;
}

.dialogBox .content ::v-deep(.el-form.dialogBoxForm .el-select),
.dialogBox .content ::v-deep(.el-form.dialogBoxForm .el-cascader) {
  width: 100%;
  height: 36px;
}

.dialogBox .content ::v-deep(.el-form.dialogBoxForm .el-input.el-date-editor) {
  width: calc(50% - 6px);
}

.dialogBox
  .content
  ::v-deep(.el-form.dialogBoxForm .el-input.el-date-editor .el-input__inner) {
  padding-left: 30px !important;
}

.dialogBox
  .content
  ::v-deep(.el-form.dialogBoxForm .el-input.el-date-editor .el-input__prefix) {
  left: 8px;
  top: 11px;
  width: 14px;
  height: 14px;
}

.dialogBox
  .content
  ::v-deep(.el-form.dialogBoxForm .el-form-item__content .to) {
  width: 12px;
  display: inline-block;
  vertical-align: top;
  text-align: center;
  font-size: 14px;
  color: #666666;
  line-height: 36px;
}

.dialogBox
  .content
  ::v-deep(.el-form.dialogBoxForm .el-textarea .el-textarea__inner) {
  resize: none;
  height: 80px;
  padding: 12px 12px;
  box-sizing: border-box;
  line-height: 20px;
  color: #333333;
}

.dialogBox
  .content
  ::v-deep(.el-form.dialogBoxForm .el-textarea.wordLimit .el-textarea__inner) {
  height: 98px;
  padding: 12px 12px 44px 12px;
}

.dialogBox
  .content
  ::v-deep(.el-form.dialogBoxForm .el-textarea.wordLimit .el-input__count) {
  line-height: 44px;
  right: 12px;
  bottom: 1px;
  color: #cccccc;
  font-size: 14px;
}

.dialogBox .content ::v-deep(.message) {
  width: 100%;
  height: 100%;
  padding: 0px 20px 0 20px;
  box-sizing: border-box;
}

.dialogBox .content ::v-deep(.message > i.iconfont) {
  display: inline-block;
  width: 32px;
  height: 24px;
  line-height: 1;
  font-size: 24px;
  vertical-align: top;
  text-align: left;
  color: #ec9e00;
}

.dialogBox .content ::v-deep(.message p) {
  margin-top: 2px;
  width: calc(100% - 32px);
  display: inline-block;
  vertical-align: top;
  height: auto;
  line-height: 20px;
  font-size: 14px;
  color: #4e4e4e;
  white-space: pre-wrap;
}

.dialogBox .content._message_form ::v-deep(.message),
.dialogBox .content._message_import ::v-deep(.message) {
  height: auto;
}

.dialogBox .content._message_form ::v-deep(.el-form.dialogBoxForm),
.dialogBox .content._message_import ::v-deep(.el-form.dialogBoxForm) {
  height: auto;
}

.dialogBox .content._message_import .import_info {
  padding: 17px 20px 0 20px;
  width: 100%;
  box-sizing: border-box;
}

.dialogBox .content._message_import .import_info > li {
  line-height: 20px;
  margin-top: 10px;
  display: flex;
  flex: 1;
  flex-direction: row;
}

.dialogBox .content._message_import .import_info > li .label {
  font-size: 14px;
  color: #242020;
  font-weight: 400;
  white-space: nowrap;
  padding-right: 4px;
  box-sizing: border-box;
}

.dialogBox .content._message_import .import_info > li .info {
  font-size: 14px;
  color: #f56c6c;
  font-weight: 400;
  white-space: pre-wrap;
  word-break: keep-all;
}

.dialogBox .content ::v-deep(.el-form.dialogBoxForm .basicInfo) {
  padding: 6px 14px 14px 14px;
  box-sizing: border-box;
  width: 100%;
  height: auto;
  margin: 14px 0 0 0;
  background-color: #f9f9f9;
}

.dialogBox .content ::v-deep(.el-form.dialogBoxForm .basicInfo > li) {
  line-height: 20px;
  margin-top: 8px;
  display: flex;
  flex: 1;
  flex-direction: row;
}

.dialogBox .content ::v-deep(.el-form.dialogBoxForm .basicInfo > li .label) {
  font-size: 14px;
  color: #999999;
  font-weight: 400;
  white-space: nowrap;
  padding-right: 8px;
  box-sizing: border-box;
}

.dialogBox .content ::v-deep(.el-form.dialogBoxForm .basicInfo > li .info) {
  font-size: 14px;
  color: #666666;
  font-weight: 400;
  white-space: pre-wrap;
  word-break: keep-all;
}

.dialogBox .content ::v-deep(.el-form.dialogBoxForm .checkedInfo) {
  line-height: 20px;
}

.dialogBox
  .content
  ::v-deep(.el-form.dialogBoxForm .checkedInfo .iconfont.checked) {
  cursor: pointer;
  display: inline-block;
  width: 16px;
  height: 16px;
  margin-right: 8px;
  margin-top: 2px;
  vertical-align: top;
  border: 1px solid #dcdee6;
  -webkit-box-shadow: 0 0 0 0 #dcdee6;
  -moz-box-shadow: 0 0 0 0 #dcdee6;
  box-shadow: 0 0 0 0 #dcdee6;
  border-radius: 100%;
}

.dialogBox
  .content
  ::v-deep(.el-form.dialogBoxForm .checkedInfo .iconfont.icon-selected) {
  color: #276efa;
  font-size: 16px;
  -webkit-box-shadow: 0 0 0 0;
  -moz-box-shadow: 0 0 0 0;
  box-shadow: 0 0 0 0;
}

.dialogBox
  .content
  ::v-deep(
    .el-form.dialogBoxForm .checkedInfo .iconfont.icon-selected::before
  ) {
  position: absolute;
  top: 0;
  left: 0;
}

.dialogBox .content ::v-deep(.el-form.dialogBoxForm .checkedInfo .info) {
  display: inline-block;
  font-size: 14px;
  color: #666666;
  font-weight: 400;
  vertical-align: top;
}

.messageType {
  padding-left: 30px !important;
  color: #595959;
  line-height: 20px;
  font-size: 14px;
  position: relative;
}

.mesName {
  width: 100%;
  height: 20px;
  font-size: 14px;
  font-weight: 500;
  color: #333333;
  line-height: 20px;
  margin-bottom: 8px;
}

.lastMesList .mesList:last-child {
  width: 100%;
  height: auto;
  overflow: hidden;
  font-size: 14px;
  font-weight: 400;
  color: #4e4e4e;
  line-height: 22px;
  margin-bottom: 20px;
}

.score_dialog .content ::v-deep(.el-form.noLabel) {
  width: calc(100% - 20px);
}

.score_dialog .content ::v-deep(.el-form.noLabel .el-form-item) {
  margin-bottom: 50px;
}

.score_dialog .content ::v-deep(.el-form.noLabel .el-form-item:last-child) {
  margin-bottom: 0;
}

.score_dialog .content ::v-deep(.el-form.noLabel .el-form-item__content) {
  display: inline-block;
  width: 100%;
  height: 43px;
  line-height: 1;
}

.score_dialog
  .content
  ::v-deep(.el-form.noLabel .el-form-item__content .demonstration) {
  display: inline-block;
  vertical-align: top;
  width: 110px;
  line-height: 26px;
  color: #333333;
  font-weight: bold;
  font-size: 14px;
  padding-right: 20px;
  padding-left: 10px;
  box-sizing: border-box;
  text-align: left;
}

.score_dialog
  .content
  ::v-deep(.el-form.noLabel .el-form-item__content .el-slider) {
  display: inline-block;
  vertical-align: top;
  width: calc(100% - 110px);
  padding-top: 10px;
}

.score_dialog
  .content
  ::v-deep(.el-form.noLabel .el-form-item__content .el-slider__button) {
  border: 3px solid #327fff;
}

.score_dialog
  .content
  ::v-deep(.el-form.noLabel .el-form-item__content .el-slider__bar) {
  background-image: linear-gradient(
    270deg,
    rgba(50, 127, 255, 1),
    rgba(97, 182, 255, 1)
  );
}

.score_dialog
  .content
  ::v-deep(.el-form.noLabel .el-form-item__content .el-slider__marks-text) {
  font-size: 12px;
  line-height: 18px;
  color: #999999;
}
</style>
