<template>
  <video
    :id="videoId || 'videoPlayer'"
    ref="video"
    class="video-js vjs-default-skin video_main"
    muted="muted"
    controls
    playsinline
    autoplay
    :style="videoStyle"
    @loadeddata="cutPicture"
    @click.stop.prevent
  >
    <source type="application/x-mpegURL" :src="src" />
  </video>
  <canvas
    id="myCanvas"
    width="343"
    height="200"
    style="position: fixed; left: -9999px; top: -9999px"
  />
</template>
<script lang="ts">
import { defineComponent } from "vue";
import videojs from "video.js";
import "video.js/dist/video-js.css";
export default defineComponent({
  name: "PlayerComp",
  props: {
    height: {
      type: String,
      default: "auto",
    },
    src: {
      type: String,
      default: "",
    },
    videoStyle: {
      type: Object,
      default: () => ({
        width: "1200px",
        height: "675px",
      }),
    },
    videoId: {
      type: String,
      default: "videoPlayer",
    },
  },
  emits: ["imgUrlCreate"],
  data() {
    return {
      options: {
        loop: true,
        autoplay: false, // 设置自动播放
        muted: true, // 设置了它为true，才可实现自动播放,同时视频也被静音（Chrome66及以上版本，禁止音视频的自动播放）
        preload: "auto", // 预加载
        controls: true, // 显示播放的控件
      },
      player: null! as any,
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.loadVideo();
    });
  },
  // 销毁
  beforeUnmount() {
    if (this.db) {
      this.db.dispose(); // dispose()会直接删除Dom元素
    }
  },
  methods: {
    cutPicture() {
      if (this.isLive) {
        setTimeout(() => {
          // 视频截图
          const v = document.querySelector("video");
          const canvas = document.getElementById("myCanvas");
          const ctx = canvas.getContext("2d");
          ctx.drawImage(v, 0, 0, 343, 200);
          const oGrayImg = canvas.toDataURL("image/jpeg");
          this.$emit("imgUrlCreate", oGrayImg);
        }, 1000);
      }
    },
    loadVideo() {
      if (this.videoId === "videoPlayer") {
        if (document.getElementById("videoPlayer")) {
          this.db = videojs(
            document.getElementById("videoPlayer") as HTMLElement,
            this.options
          );
        }
      } else {
        if (document.getElementById(this.videoId)) {
          this.db = videojs(
            document.getElementById(this.videoId) as HTMLElement,
            this.options
          );
        }
      }
    },
  },
});
</script>
<style scoped lang="scss">
.video_main {
  width: 100%;
}

.videoPlayer-dimensions {
  height: 100%;
}

.video-js .vjs-tech {
  width: 100% !important;
  height: 100% !important;
}

.videoPlayer-dimensions {
  z-index: 2;
}
</style>
