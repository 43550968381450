<!-- DrawerCom  -->
<!-- 
  <DrawerCom :config="config_detail">
    <template #dialogBoxContent>
      </template>
  </DrawerCom>
  传入空样式配置 config_detail: {
        title: "企业用户信息详情", ------（title内容）
        width: "1002px", ------（设计稿的宽度）
        height: "100%", ------（高度）
        visible: true, ------（空内容显示隐藏控制属性,false为隐藏，true为显示）
        id: 0,
      },
     -->

<template>
  <el-drawer
    v-model="visible"
    :title="config.title"
    :size="config.width"
    @close="$emit('close')"
  >
    <slot name="dialogBoxContent"></slot>
  </el-drawer>
</template>
<script lang="ts">
import { defineComponent, PropType } from "vue";
export default defineComponent({
  name: "DrawerCom",
  props: {
    config: {
      type: Object as PropType<{
        visible: boolean;
        width: number;
        title: string;
      }>,
      default: () => ({
        page: 1,
        size: 10,
      }),
    },
  },
  emits: ["close"],
  data() {
    return {
      visible: false,
    };
  },
  watch: {
    "config.visible": {
      handler(newVal: boolean) {
        this.visible = newVal;
      },
      immediate: true,
    },
  },
});
</script>
<style scoped></style>
