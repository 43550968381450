<!-- DetailCard -->
<template>
  <div class="detailCard">
    <div v-if="title" class="title">
      <img src="@/assets/images/demo.png" alt="demo" />
      <span v-html="title"></span>
    </div>
    <slot></slot>
  </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";
export default defineComponent({
  name: "DetailCardComp",
  props: {
    title: {
      type: String,
      default: "",
    },
  },
  methods: {},
});
</script>
<style scoped lang="scss">
.detailCard {
  min-width: 1000px;
  max-width: 1440px;
  margin: 0 auto;
  &:last-child {
    border-bottom: none;
  }
  .title {
    font-weight: bold;
    color: #333333;
    line-height: 22px;
    font-size: 16px;
    margin-right: 80px;
    min-width: 164px;

    img {
      width: 14px;
      height: 14px;
      vertical-align: -1px;
      margin-right: 4px;
    }
  }
}
</style>
<style lang="scss">
.contentList {
  > .detailCard:first-child {
    padding-top: 10px;
  }
}
</style>
