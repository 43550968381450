<template>
  <div class="steps">
    <template v-for="(item, index) in steps" :key="item.title">
      <div
        :class="['step', getClassName(index)]"
        @click="$emit('update:current', index)"
      >
        <span>{{ index + 1 }}</span
        >{{ item.title }}
        <div :class="['subTitle', item.class]">{{ item.subTitle }}</div>
      </div>
      <i v-if="index < steps.length - 1" class="line"></i>
    </template>
  </div>
</template>
<script lang="ts">
import { defineComponent, PropType } from "vue";

export default defineComponent({
  name: "StepsProgressUpgraded",
  props: {
    steps: {
      type: Array as PropType<{ title: string }[]>,
      default: () => [],
    },
    // 当前进行到的步骤激活项
    active: {
      type: Number,
      default: -999,
    },
    // 当前点击查看的tab
    current: {
      type: Number,
      default: 0,
    },
    // 不能标记已完成状态的index
    notFinishArray: {
      type: Array as PropType<{ title: string }[]>,
      default: () => [],
    },
  },
  emits: ["update:active", "update:current"],
  data() {
    return {};
  },
  created() {},
  methods: {
    getClassName(index: number) {
      if (this.current === index) {
        if (this.active > index && !this.notFinishArray.includes(index)) {
          return "finished current";
        } else {
          return "current";
        }
      } else {
        if (this.active > index && !this.notFinishArray.includes(index)) {
          return "finished";
        }
        if (this.active === index) {
          return "active";
        }
      }
    },
  },
});
</script>
<style scoped lang="scss">
.steps {
  display: flex;
  justify-content: center;
  color: #000000;
  line-height: 20px;
  font-size: 14px;
  align-items: center;
  position: relative;
  width: var(--steps-progress-width);
  margin: 0 auto;
  gap: 6px;

  .line {
    display: inline-block;
    height: 2px;
    width: 40px;
    background-color: #ced1db;
  }

  .step {
    cursor: pointer;
    position: relative;
    padding: 0 4px;
    transition: color 0.3s, background-color 0.3s;

    span {
      display: inline-block;
      border-radius: 50%;
      width: 24px;
      height: 24px;
      border: 2px solid #ced1db;
      font-weight: bold;
      color: #808080;
      font-size: 14px;
      line-height: 20px;
      text-align: center;
      margin-right: 4px;
      box-sizing: border-box;
      transition: color 0.3s, background-color 0.3s;
    }

    .subTitle {
      padding-left: 28px;
      line-height: 17px;
      font-size: 12px;
      height: 0;
      margin-top: 2px;

      &.error {
        color: #f75057;
      }

      &.success {
        color: #2468f1;
      }

      &.pending {
        color: #999;
      }
    }

    &.active {
      font-weight: bold;
      color: #000000;

      span {
        background: rgba(36, 104, 241, 0.72);
        border: 2px solid #2468f1;
        font-weight: bold;
        color: #ffffff;
      }
    }

    &:hover {
      color: #2468f1;
      font-weight: bold;
    }

    &.current {
      color: #2468f1;
      font-weight: bold;

      span {
        border: 2px solid #b3cafa;
        color: #2468f1;
      }
    }

    &.finished {
      span {
        border: 2px solid #b3cafa;
        color: transparent;
        position: relative;
        background: transparent;

        &::before {
          position: absolute;
          content: " ";
          width: 100%;
          height: 100%;
          left: 0;
          right: 0;
          background: url("@/assets/images/tickBlue.png") center / 10px 10px
            no-repeat;
        }
      }
    }
  }
}
</style>
