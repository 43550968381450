import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import i18n from "./i18n";
import useGlobalComponents from "./components/global";
import useDirectives from "./directives/index";
import "element-plus/dist/index.css";
import "@/assets/css/global.scss";
import Sentry from "./../sentry.js";
import "virtual:svg-icons-register";

import "@/utils/echart";

const app = createApp(App);

// TODO:暂时注释
// new Sentry().install(app, router);
useGlobalComponents(app);
useDirectives(app);
app.use(router);
app.use(store);
app.use(i18n);
app.mount("#app");
