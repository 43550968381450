import { http } from "./http";

// web登入
export function login(params) {
  return http.post("/auth/login", params);
}

// 注册
export function register(params) {
  return http.post("/auth/register", params);
}

export function smsLogin(params) {
  return http.post("/auth/login/logout", { params });
}

// 验证码
export function getCode(params) {
  return http.post("/auth/get-register-verification-code", params);
}

// 重置验证码
export function getResetCode(params) {
  return http.post("/auth/reset-password/email", params);
}

// 图形验证码
export function getCaptcha(params) {
  return http.get("/sso/login/captcha", { params, responseType: "blob" });
}

// 忘记密码
export function forgetPassword(params) {
  return http.post("/sso/login/forget/password", params);
}

// 一轮注册账号认证
export function nextConfirm(params) {
  return http.post("/sso/login/register/confirm", params);
}

// web登出
export function logout() {
  return http.post("/auth/login/logout");
}

// 获取字典数据
export function getCommon(params = {}) {
  return http.get("/dic/common", { params });
}

// 获取当前登陆用户信息
export function getUserInfo() {
  return http.get("/account/info");
}

// 获取当前登陆用户详情
export function getUserInfoDetail() {
  return http.get("/auth/user/details");
}

// 编辑当前登陆用户信息
export function editUserInfo(params: object) {
  return http.post("/auth/user/info", params);
}

// 获取认证信息
export function checkOrgType() {
  return http.get("/org/authentication/check-organization-type");
}

// 用户名注册验证
export function getVerifyUserName(params) {
  return http.post("/auth/check-username", params);
}

// 邮箱注册验证
export function getVerifyEmail(params) {
  return http.post("/auth/check-email", params);
}

export function getSliderCaptcha() {
  return http.get("/captcha/public");
}

export function checkSliderCaptcha(params: object) {
  return http.get("/captcha/public/check", { params });
}

export function getPublicNeed(params: object) {
  return http.get("/captcha/public/need", { params });
}

// 重置密码
export function putResetPwd(params: object) {
  return http.put("/auth/reset-password", params);
}
