<!-- 
  echarts 基础组件，使用请参考@/views/admin/index/components/CircleChart.vue和HotChart.vue
 -->
<template>
  <div ref="elDom" class="chart">
    <div ref="chartDom" class="chart__el"></div>
    <!-- <NoData v-show="empty" class="chart__empty"></NoData> -->
  </div>
</template>
<script lang="ts">
import { computed, defineComponent, onMounted, shallowRef, watch } from "vue";
import { useElementSize } from "@vueuse/core";
import ECharts from "../../utils/echart";
import type { ECOption } from "../../utils/echart";
// https://github.com/apache/echarts/issues/13943 vue3.0下echarts resize()报错所以改用setup方法中的shallowRef引用
export default defineComponent({
  name: "BaseECharts",
  props: {
    empty: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["resize"],
  expose: ["getChart", "setOption"],
  setup(props, context) {
    const chartDom = shallowRef<HTMLElement>();
    const chart = shallowRef<ECharts.ECharts>();
    onMounted(() => {
      chart.value = ECharts.init(chartDom.value!);
    });

    const elDom = shallowRef<HTMLElement>();
    const wh = computed(() => {
      const { width, height } = useElementSize(elDom);
      return { width, height };
    });
    watch(
      wh,
      (newVal) => {
        context.emit("resize", newVal);
        chart.value?.resize();
      },
      { deep: true }
    );

    const getChart = function () {
      return chart.value;
    };

    const setOption = function (option: ECOption) {
      chart.value?.setOption(option, true);
    };
    return {
      elDom,
      chartDom,
      getChart,
      setOption,
    };
  },
});
</script>

<style scoped lang="scss">
.chart__el,
.chart {
  height: 100%;
  width: 100%;
}
</style>
