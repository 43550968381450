<!-- DetailCard -->
<template>
  <div class="detailCards">
    <div v-if="title" class="title">
      <span v-html="title"></span>
    </div>
    <slot></slot>
  </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "DetailCardComp",
  props: {
    title: {
      type: String,
      default: "",
    },
  },
  methods: {},
});
</script>
<style scoped lang="scss">
@media (min-width: 1581px) {
  .detailCards {
    margin: 0 auto;
  }
}

@media (max-width: 1580px) {
  .detailCards {
    margin: 0 auto;
  }
}

.detailCards {
  padding: 40px 10px;
  border-bottom: 1px solid #eeeeee;

  &:last-child {
    border-bottom: none;
  }

  .title {
    position: relative;
    font-weight: bold;
    color: #333333;
    line-height: 22px;
    font-size: 16px;
    min-width: 160px;
    padding-left: 20px;
    box-sizing: border-box;
    float: left;

    img {
      width: 14px;
      height: 14px;
      vertical-align: -1px;
      margin-right: 4px;
    }
  }

  .title:before {
    position: absolute;
    content: "";
    background-image: url("@/assets/icons/userLi.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    width: 14px;
    height: 14px;
    display: inline-block;
    top: 4px;
    left: 0;
  }
}
</style>
<style lang="scss">
.contentList {
  > .detailCards:first-child {
    padding-top: 10px;
  }
}
</style>
