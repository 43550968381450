import axios from "axios";
import { BASE_URL } from "./ENV";
import { getToken, getSubmitToken, setToken } from "../utils/util";
// import { ElLoading } from "element-plus";
import store from "../store/index";
import i18n from "@/i18n/index";
import qs from "qs";

const baseURL = BASE_URL as string;
axios.defaults.withCredentials = true;
export const http = axios.create({
  baseURL,
  // 针对get请求拼接数组的形式为arr=1,2,3...
  paramsSerializer: function (params) {
    return qs.stringify(params, { arrayFormat: "comma" });
  },
});

function isFileUploadApi(config) {
  return Object.prototype.toString.call(config.data) === "[object FormData]";
}

function isFileDownloadApi(config) {
  return config.responseType === "blob";
}

// 发布订阅
class MyEventEmitter {
  constructor() {
    this.event = {};
  }

  on(type, cbres, cbrej) {
    if (!this.event[type]) {
      this.event[type] = [[cbres, cbrej]];
    } else {
      this.event[type].push([cbres, cbrej]);
    }
  }

  emit(type, res, ansType) {
    if (!this.event[type]) return;
    else {
      this.event[type].forEach((cbArr) => {
        if (ansType === "resolve") {
          cbArr[0](res);
        } else {
          cbArr[1](res);
        }
      });
    }
  }
}

// 根据请求生成对应的key
function generateReqKey(config, hash) {
  const { method, url, params, data } = config;
  return [method, url, JSON.stringify(params), JSON.stringify(data), hash].join(
    "&"
  );
}

// 存储已发送但未响应的请求
const pendingRequest = new Set();

// 发布订阅容器
const ev = new MyEventEmitter();

// 接口响应成功
function handleSuccessResponse_limit(response) {
  const reqKey = response.config.pendKey;
  if (pendingRequest.has(reqKey)) {
    let x = null;
    try {
      x = JSON.parse(JSON.stringify(response));
    } catch (e) {
      x = response;
    }
    pendingRequest.delete(reqKey);

    ev.emit(reqKey, x, "resolve");
    delete ev.event[reqKey];
  }
}

// 接口走失败响应
function handleErrorResponse_limit(error) {
  if (error.type && error.type === "limiteResSuccess") {
    return Promise.resolve(error.val.data);
  } else if (error.type && error.type === "limiteResError") {
    if (error.val.config.url !== "/sso/login/login") {
      // store.commit("setErrConfig", {
      //   visible: true,
      //   message:
      //     "很抱歉，我们的服务器似乎出现了一些故障，已向技术人员发送通知，请您稍后再试。\n Sorry, there seems to be some problem with our server. We have sent a notification to the technicians. Please try again later.",
      // });
    }
    return Promise.reject(error.val);
  } else {
    const reqKey = error.config.pendKey;
    if (pendingRequest.has(reqKey)) {
      let x = null;
      try {
        x = JSON.parse(JSON.stringify(error));
      } catch (e) {
        x = error;
      }
      pendingRequest.delete(reqKey);
      ev.emit(reqKey, x, "reject");
      // store.commit("setErrConfig", {
      //   visible: true,
      //   message:
      //     "很抱歉，我们的服务器似乎出现了一些故障，已向技术人员发送通知，请您稍后再试。\nSorry, there seems to be some problem with our server. We have sent a notification to the technicians. Please try again later.",
      // });
      delete ev.event[reqKey];
    }
  }
  return Promise.reject(error);
}

export interface FormData {
  keyword: "";
  status: "";
  areaCode: "";
}

export interface ApiResponse<T> {
  code: string;
  name: string;
  data: T;
  message: string;
}

export interface PageResponse {
  [x: string]: {
    code?: string;
    name?: string;
    createDate?: string;
    createUserName?: string;
    disease?: string;
    id?: string;
    modality?: string;
    status?: string;
    statusName?: string;
    targetModel?: string;
    targetName?: string;
  }[];
  tableData: {
    code?: string;
    name?: string;
    createDate?: string;
    createUserName?: string;
    disease?: string;
    id?: string;
    modality?: string;
    status?: string;
    statusName?: string;
    targetModel?: string;
    targetName?: string;
  }[];
}
// let loadingInstance;
http.interceptors.request.use(
  async (config) => {
    const headers = Object.assign({}, config.headers, {
      Authorization: "Bearer " + getToken(),
      "SUBMIT-AGAIN-TOKEN": getSubmitToken(),
      "Accept-Language": i18n.global.locale.value,
    });
    config.headers = headers;
    // loadingInstance = ElLoading.service({
    //   lock: true,
    //   text: "加载中",
    //   background: "rgba(0, 0, 0, 0.7)",
    //   customClass: "customeLoading",
    //   // target: "",
    // });
    // setTimeout(() => {
    //   loadingInstance.close();
    // }, 2000);
    if (isFileDownloadApi(config) || isFileUploadApi(config)) {
      return { ...config };
    }
    const hash = location.hash;
    // 生成请求Key
    const reqKey = generateReqKey(config, hash);
    if (pendingRequest.has(reqKey)) {
      // 如果是相同请求,在这里将请求挂起，通过发布订阅来为该请求返回结果
      // 这里需注意，拿到结果后，无论成功与否，都需要return Promise.reject()来中断这次请求，否则请求会正常发送至服务器
      let res = null;
      try {
        // 接口成功响应
        res = await new Promise((resolve, reject) => {
          ev.on(reqKey, resolve, reject);
        });
        return Promise.reject({
          type: "limiteResSuccess",
          val: res,
        });
      } catch (limitFunErr) {
        // 接口报错
        return Promise.reject({
          type: "limiteResError",
          val: limitFunErr,
        });
      }
    } else {
      // 将请求的key保存在config
      config.pendKey = reqKey;
      pendingRequest.add(reqKey);
    }
    return { ...config };
  },
  function (error) {
    return Promise.reject(error);
  }
);

http.interceptors.response.use(
  (response) => {
    if (response.data instanceof Blob) {
      const filesNameCode =
        response.headers["content-disposition"]?.split("=")[1];
      // 解密文件名称
      const fileName = decodeURI(filesNameCode);
      const returnRes = [response.data, fileName];
      return returnRes;
    }
    if (response.data instanceof Blob) return response.data;
    switch (response.data.status) {
      case 200:
        // 将拿到的结果发布给其他相同的接口
        handleSuccessResponse_limit(response);
        return response.data;
      case 405:
      case 401:
        setToken("");
        localStorage.setItem("dataAccountName", "");
        localStorage.setItem("accountId", "");
        location.replace(`/`);
        break;
      case 500:
        store.commit("setErrConfig", {
          visible: true,
          message:
            response.data.message ||
            "很抱歉，我们的服务器似乎出现了一些故障，已向技术人员发送通知，请您稍后再试。\nSorry, there seems to be some problem with our server. We have sent a notification to the technicians. Please try again later.",
        });
        store.commit("setLoadingStatus", false);
        return handleErrorResponse_limit(response);
      default:
        store.commit("setErrConfig", {
          visible: true,
          message:
            response.data.message ||
            "很抱歉，我们的服务器似乎出现了一些故障，已向技术人员发送通知，请您稍后再试。\n Sorry, there seems to be some problem with our server. We have sent a notification to the technicians. Please try again later.",
        });
        store.commit("setLoadingStatus", false);
        return handleErrorResponse_limit(response);
    }
  },
  async (err) => {
    // if (err.config.url !== "/sso/login/login") {
    //   store.commit("setWaringConfig", {
    //     visible: true,
    //     message:
    //       "很抱歉，我们的服务器似乎出现了一些故障，已向技术人员发送通知，请您稍后再试",
    //   });
    // }
    if (err.response) {
      switch (err.response.status) {
        case 403:
        case 405:
        case 401:
          setToken("");
          localStorage.setItem("dataAccountName", "");
          localStorage.setItem("accountId", "");
          location.replace(`/`);
          break;
        case 500:
          store.commit("setErrConfig", {
            visible: true,
            message:
              err.response.data.message ||
              "很抱歉，我们的服务器似乎出现了一些故障，已向技术人员发送通知，请您稍后再试。\nSorry, there seems to be some problem with our server. We have sent a notification to the technicians. Please try again later.",
          });
          store.commit("setLoadingStatus", false);
          return handleErrorResponse_limit(err);
        default:
          store.commit("setErrConfig", {
            visible: true,
            message:
              err.response.data.message ||
              "很抱歉，我们的服务器似乎出现了一些故障，已向技术人员发送通知，请您稍后再试。\n Sorry, there seems to be some problem with our server. We have sent a notification to the technicians. Please try again later.",
          });
          store.commit("setLoadingStatus", false);
          return handleErrorResponse_limit(err);
      }
    } else {
      return handleErrorResponse_limit(err);
    }
    // return Promise.reject(err);
  }
);

export function getUrl(url: string, param?: Record<string, string>) {
  if (param) {
    let paramStr = "";
    for (const i in param) {
      if (param[i] && String(param[i])) {
        paramStr += `&${i}=${param[i]}`;
      }
    }
    url += "?" + paramStr.slice(1);
  }
  return url;
}
